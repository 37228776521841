import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocalization } from 'gatsby-theme-i18n';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import styled from 'styled-components';
import { ContentfulComponentFullWebinarGate } from '@/../graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import ContentfulImage from '@/components/ContentfulImage';
import { RegistrationForm } from '@/components/contentful/components';
import Typography from '@/components/layout/Typography';
import {
  EventAvailable,
  AccessTime,
  CheckCircleOutline,
} from '@material-ui/icons';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

const Container = styled.div``;

/* Rich Text stuff */
const RichTextContainer = styled.div`
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;

const IframeContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Blockquote = styled.blockquote`
  font-size: ${(props) => props.theme.fontSize.h4};
  line-height: 1.3;
  margin-bottom: ${(props) => props.theme.spacing.xs};
  p {
    margin-bottom: 0;
  }
  em {
    font-size: 0.6em;
    color: ${(props) => props.theme.color.grey};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="text-primaryMain font-light text-3xl mb-4">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-primaryMain text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="text-primaryMain font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return (
        <GatsbyImage
          alt={node?.data?.target?.title || 'image'}
          image={gatsbyImageData}
          className="my-sm"
        />
      );
    },
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.indexOf('youtube.com/embed') !== -1) {
        return (
          <IframeContainer>
            <iframe
              id="ytplayer"
              src={node.data.uri}
              width="640"
              height="360"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
            />
          </IframeContainer>
        );
      } else return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a
          href={node?.data?.target?.file?.url}
          rel="noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};
/* end Rich Text stuff */

interface WebinarGateProps {
  data?: ContentfulComponentFullWebinarGate;
  className?: string;
}
const WebinarGate: React.FC<WebinarGateProps> = (props) => {
  const { data } = props;

  return (
    <Container>
      <div className="container grid grid-cols-12 gap-y-sm md:gap-sm mt-sm">
        <div className="col-span-12 md:col-span-6 lg:col-span-8">
          {data?.formContent?.raw && (
            <RichTextContainer>
              {renderRichText(
                data.formContent as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                options,
              )}
            </RichTextContainer>
          )}
          <div className="lg:flex justify-between gap-sm">
            {data?.bulletPoints && data?.bulletPoints.length > 0 && (
              <div className="w-full lg:w-1/2">
                {data?.bulletPointsTitle && (
                  <Typography
                    as="h3"
                    className={`text-primaryMain font-bold mb-xs`}
                  >
                    {data?.bulletPointsTitle}
                  </Typography>
                )}
                <ul className="">
                  {data.bulletPoints?.map((bullet) => {
                    return (
                      <li
                        key={`bullet-${bullet.replace(' ', '-')}`}
                        className="flex items-center mt-xs first:mt-0"
                      >
                        <span>
                          <CheckCircleOutline className="text-primaryMain" />
                        </span>
                        <Typography variant="body" className={` ml-xs`}>
                          {bullet}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="w-full lg:w-1/2 my-sm lg:my-0">
              {data?.speakersTitle && (
                <Typography
                  as="h3"
                  className={`text-primaryMain font-bold mb-xs`}
                >
                  {data?.speakersTitle}
                </Typography>
              )}
              <div className="flex flex-wrap gap-sm lg:flex-nowrap justify-between lg:gap-xs">
                {data.speakers?.map((speaker, i) => {
                  return (
                    <div
                      key={`speaker-${i}`}
                      className="mb-xs w-full sm:w-1/2 md:w-full lg:w-auto text-center"
                    >
                      {speaker?.profilePicture && (
                        <ContentfulImage
                          image={speaker?.profilePicture}
                          alt={speaker?.profilePicture.description || ''}
                          className="inline-block"
                        />
                      )}
                      <div className="mt-xs">
                        <span className="block font-bold">
                          {speaker.fullName}
                        </span>
                        <span className="block text-sm">
                          {speaker.jobTitle}
                        </span>
                        <span className="block text-sm">
                          {speaker.companyName}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="">
            {data?.featureImage && (
              <ContentfulImage
                image={data?.featureImage}
                alt={data?.featureImage.description || ''}
              />
            )}
          </div>
        </div>
        <div className="order-first md:order-none col-span-12 md:col-span-6 lg:col-span-4">
          {data?.title && (
            <Typography
              as="h2"
              variant="h4"
              className="block text-primaryMain mb-xs"
            >
              {data?.title}
            </Typography>
          )}
          {data?.webinarDate && (
            <span className="block">
              <EventAvailable className="text-greenDark mr-2 relative bottom-[1px]" />
              {data?.webinarDate}
            </span>
          )}
          {data?.webinarDuration && (
            <span className="block mt-2">
              <AccessTime className="text-greenDark mr-2 relative bottom-[1px]" />
              {data?.webinarDuration}
            </span>
          )}
          <RegistrationForm key="webinar-gate" data={data} />
        </div>
      </div>
    </Container>
  );
};
export default WebinarGate;
