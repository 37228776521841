import React, { useEffect } from 'react';
import { useLocalization, LocalizedLink } from 'gatsby-theme-i18n';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

import { ContentfulComponentSiteFooter } from '@/../graphql-types';
import CookieConsent from 'react-cookie-consent';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import LanguageSelector from '@/components/LanguageSelector';
import SocialMediaList from '@/components/SocialMediaList';
import LinkedInWidget from '@/components/LinkedInWidget';
import ContentfulImage from '@/components/ContentfulImage';
import Typography from '@/components/layout/Typography';
import styled from 'styled-components';
import { getFullLink } from '@/utils/helpers';
import { theme } from '@/theme/mainTheme';

interface FooterProps {
  location: Location;
  className?: string;
  data?: ContentfulComponentSiteFooter;
}

const BannerBody = styled.div`
  a {
    opacity: 0.8;
    text-decoration: underline;
  }
  a:hover,
  a:focus {
    opacity: 1;
    color: #fff;
  }
`;

const Footer: React.FC<FooterProps> = (props) => {
  const { location, data } = props;
  const { locale } = useLocalization();

  //Adding CTM if Cookie exists and page ready
  useEffect(() => {
    const scriptExists = document.querySelector(
      'script[src="//442747.tctm.xyz/t.js"]',
    );
    const cookieExists = document.cookie.includes(
      'gatsby-gdpr-google-tagmanager=',
    );

    if (!scriptExists && cookieExists) {
      const script = document.createElement('script');
      script.src = '//442747.tctm.xyz/t.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  let footerType = 'Default';
  if (data?.footerType) {
    footerType = data?.footerType;
  }

  let cookieBannerButtonText = 'Yes';
  if (data?.cookieBannerButtonText?.copy) {
    cookieBannerButtonText = renderRichText(
      data?.cookieBannerButtonText
        ?.copy as RenderRichTextData<ContentfulRichTextGatsbyReference>,
    );
  }

  const footerMenu = (menu) => {
    return (
      <>
        <Typography as="h3" variant="subtitle" className="uppercase font-bold">
          <strong>{menu.menuTitle}</strong>
        </Typography>
        <ul>
          {menu.menuItems?.map((menuItem) => (
            <li key={`menu-item-${menu.id}-${menuItem.id}`} className="mt-3">
              {menuItem?.url && (
                <a
                  href={menuItem?.url}
                  className="hover:no-underline text-gray-200 hover:text-white"
                >
                  <Typography as="span" variant="subtitle">
                    {menuItem.text}
                  </Typography>
                </a>
              )}
              {menuItem?.page && (
                <LocalizedLink
                  language={locale}
                  to={getFullLink(menuItem?.page, locale)}
                  className="hover:no-underline text-gray-200 hover:text-white"
                >
                  <Typography as="span" variant="subtitle">
                    {menuItem.text}
                  </Typography>
                </LocalizedLink>
              )}
            </li>
          ))}
        </ul>
      </>
    );
  };
  if (footerType === 'Simple') {
    return (
      <>
        <div className="site-footer container py-8 mt-sm border-t border-grey1 flex flex-col lg:flex-row items-center justify-between">
          <div className="order-3 lg:order-1">
            <Typography className="text-grey text-sm">
              {data?.legalText?.map((item) =>
                /* uses default styling for rich text */
                renderRichText(
                  item.copy as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                ),
              )}
            </Typography>
          </div>
          <div className="mb-xs lg:mb-0 order-2">
            <SocialMediaList color="dark" />
          </div>
          <div className="order-1 mb-xs lg:mb-0 lg:order-3">
            {data?.menus?.map((menu) => (
              <ul key={`menu-${menu.id}`} className="">
                {menu.menuItems?.map((menuItem) => (
                  <li
                    key={`menu-item-${menu.id}-${menuItem.id}`}
                    className="inline mx-4 lg:mx-0 lg:mr-6"
                  >
                    {menuItem?.page && (
                      <LocalizedLink
                        language={locale}
                        to={getFullLink(menuItem?.page, locale)}
                        className="text-grey opacity-75 hover:opacity-100 focus:opacity-100"
                      >
                        <Typography as="span" variant="subtitle">
                          {menuItem.text}
                        </Typography>
                      </LocalizedLink>
                    )}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <CookieConsent
          debug={false}
          location="top"
          overlay={true}
          buttonText={cookieBannerButtonText}
          cookieName="gatsby-gdpr-google-tagmanager"
          style={{ background: theme?.color?.black }}
          buttonStyle={{
            background: theme?.color?.primaryMain,
            fontWeight: '400',
            color: '#fff',
            fontSize: '16px',
            borderRadius: '25px',
            padding: '0.5em 1.5em 0.6em',
          }}
          onAccept={() => {
            initializeAndTrack(location);
            //Loading CTM now
            const script = document.createElement('script');
            script.src = '//442747.tctm.xyz/t.js';
            script.async = true;
            document.body.appendChild(script);
          }}
          expires={150}
        >
          {data?.cookieBannerText?.copy && (
            <BannerBody>
              {renderRichText(
                data?.cookieBannerText
                  ?.copy as RenderRichTextData<ContentfulRichTextGatsbyReference>,
              )}
            </BannerBody>
          )}{' '}
        </CookieConsent>
      </>
    );
  } else {
    return (
      <>
        <div className="site-footer bg-black2 text-white pt-sm pb-md md:pt-md md:pb-sm">
          <div className="container">
            <div className="flex flex-col md:flex-row border-b pb-xs">
              <div className="flex-initial">
                <LocalizedLink language={locale} to="/">
                  <ContentfulImage
                    image={data?.logo?.logo}
                    alt={data?.logo?.logo?.description}
                  />
                </LocalizedLink>
              </div>
              {data?.languageToggle && (
                <div className="flex-initial mt-sm md:mt-0 md:ml-auto">
                  <LanguageSelector
                    location={location}
                    currentLocale={locale}
                    languageToggle={data?.languageToggle}
                  />
                </div>
              )}
            </div>
            <div className="mt-sm md:mt-md">
              <ul className="flex flex-col flex-wrap md:flex-row">
                {data?.menus?.map((menu) => (
                  <li
                    key={`menu-${menu.id}`}
                    className="md:w-1/3 lg:w-auto mt-md first:mt-0 md:mb-md lg:mb-0 md:mt-0 lg:ml-md first:ml-0"
                  >
                    {footerMenu(menu)}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col border-t lg:border-t-0 mt-sm lg:mt-md pt-sm lg:pt-0">
              <div className="">
                <SocialMediaList color="light" />
              </div>
              <div className="mt-md lg:mt-sm">
                <Typography variant="caption" className="text-gray-400">
                  {data?.legalText?.map(
                    (item) =>
                      /* uses default styling for rich text */
                      item.copy &&
                      renderRichText(
                        item.copy as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                      ),
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <CookieConsent
          debug={false}
          location="top"
          buttonText={cookieBannerButtonText}
          overlay={true}
          cookieName="gatsby-gdpr-google-tagmanager"
          style={{ background: theme?.color?.black }}
          buttonStyle={{
            background: theme?.color?.primaryMain,
            fontWeight: '400',
            color: '#fff',
            fontSize: '16px',
            borderRadius: '25px',
            padding: '0.5em 1.5em 0.6em',
          }}
          onAccept={() => {
            initializeAndTrack(location);
            //Loading CTM now
            const script = document.createElement('script');
            script.src = '//442747.tctm.xyz/t.js';
            script.async = true;
            document.body.appendChild(script);
          }}
          expires={150}
        >
          {data?.cookieBannerText?.copy && (
            <BannerBody>
              {renderRichText(
                data?.cookieBannerText
                  ?.copy as RenderRichTextData<ContentfulRichTextGatsbyReference>,
              )}
            </BannerBody>
          )}{' '}
        </CookieConsent>
      </>
    );
  }
};
export default Footer;
