import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocalization } from 'gatsby-theme-i18n';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import styled from 'styled-components';
import {
  ContentfulComponentRegistrationForm,
  ContentfulTopicCountryRegionsJsonNode,
  ContentfulAsset,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getPlainTextFromMicrocopyField } from '@/utils/helpers';
import { GatsbyImage } from 'gatsby-plugin-image';
import ContentfulImage from '@/components/ContentfulImage';
import { ArrowForward } from '@material-ui/icons';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

const Usercheck = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

/* Rich Text stuff */
const RichTextContainer = styled.div`
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;

const IframeContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Blockquote = styled.blockquote`
  font-size: ${(props) => props.theme.fontSize.h4};
  line-height: 1.3;
  margin-bottom: ${(props) => props.theme.spacing.xs};
  p {
    margin-bottom: 0;
  }
  em {
    font-size: 0.6em;
    color: ${(props) => props.theme.color.grey};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="text-primaryMain font-light text-3xl mb-4">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-primaryMain text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="text-primaryMain font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return (
        <GatsbyImage
          alt={node?.data?.target?.title || 'image'}
          image={gatsbyImageData}
          className="my-sm"
        />
      );
    },
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.indexOf('youtube.com/embed') !== -1) {
        return (
          <IframeContainer>
            <iframe
              id="ytplayer"
              src={node.data.uri}
              width="640"
              height="360"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
            />
          </IframeContainer>
        );
      } else return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a
          href={node?.data?.target?.file?.url}
          rel="noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};
/* end Rich Text stuff */

const CONTACT_FORM_URL = process.env.GATSBY_CONTACT_FORM_API_URL;

enum FORM_STATE {
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

interface ContainerProps {
  variant?: string;
}

const Container = styled.div<ContainerProps>``;

interface Template1_FullWidth {
  variant?: string;
  className?: string;
  data?: ContentfulComponentRegistrationForm;
  gdprMap: Array<string>;
}

const Template1_FullWidth: React.FC<Template1_FullWidth> = (props) => {
  const { data, gdprMap } = props;
  const { locale } = useLocalization();
  const [formState, setFormState] = React.useState<FORM_STATE>(
    FORM_STATE.IN_PROGRESS,
  );

  const [cookies] = useCookies([
    'utm_campaign',
    'utm_source',
    'utm_content',
    'utm_medium',
    'mio_ref',
    'gclid',
  ]);

  // set the defaults from cookies if available!
  const [gtmValues, setGtmValues] = React.useState({
    utm_campaign: cookies.utm_campaign,
    utm_source: cookies.utm_source,
    utm_content: cookies.utm_content,
    utm_medium: cookies.utm_medium,
    gclid: cookies.gclid,
  });

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
    const url = new URL(location?.href);
    const params = [];
    url?.search
      ?.substr(1)
      .split('&')
      .forEach((item) => {
        const temp = item.split('=');
        params.push(temp);
      });

    const newGtmValues = { ...gtmValues };
    params.forEach((param) => {
      newGtmValues[param[0]] = param[1];
    });
    setGtmValues(newGtmValues);
  }, []);

  const [requestedUser] = useQueryParam('userID', StringParam);

  // parse all field labels once so that React component doesn't
  // try rerendering too often attempting to check state
  const formFieldLabels = {
    firstName: getPlainTextFromMicrocopyField(data?.firstNameLabel),
    firstNameRequired: getPlainTextFromMicrocopyField(
      data?.firstNameRequiredMessage,
    ),
    lastName: getPlainTextFromMicrocopyField(data?.lastNameLabel),
    lastNameRequired: getPlainTextFromMicrocopyField(
      data?.lastNameRequiredMessage,
    ),
    email: getPlainTextFromMicrocopyField(data?.emailLabel),
    emailValidation: getPlainTextFromMicrocopyField(
      data?.emailValidationMessage,
    ),
    emailRequired: getPlainTextFromMicrocopyField(data?.emailRequiredMessage),
    phoneNumber: getPlainTextFromMicrocopyField(data?.phoneNumberLabel),
    phoneNumberValidation: getPlainTextFromMicrocopyField(
      data?.phoneNumberValidationMessage,
    ),
    company: getPlainTextFromMicrocopyField(data?.companyLabel),
    jobTitle: getPlainTextFromMicrocopyField(data?.jobTitleLabel),
    country: getPlainTextFromMicrocopyField(data?.countryLabel),
    countryRequired: getPlainTextFromMicrocopyField(
      data?.countryRequiredMessage,
    ),
    state: getPlainTextFromMicrocopyField(data?.stateLabel),
    stateValidation: getPlainTextFromMicrocopyField(
      data?.stateValidationMessage,
    ),
    comment: getPlainTextFromMicrocopyField(data?.commentLabel),
    commentExceedsLength: getPlainTextFromMicrocopyField(
      data?.commentLengthMessage,
    ),
    marketingOptIn: getPlainTextFromMicrocopyField(data?.marketingOptInLabel),
    marketingOptInGdpr: getPlainTextFromMicrocopyField(
      data?.marketingOptInGdprLabel,
    ),
    marketingOptInYes: getPlainTextFromMicrocopyField(
      data?.marketingOptInYesLabel,
    ),
    marketingOptInNo: getPlainTextFromMicrocopyField(
      data?.marketingOptInNoLabel,
    ),
    productInterest: getPlainTextFromMicrocopyField(data?.productInterestLabel),
    solutionInterest: getPlainTextFromMicrocopyField(
      data?.solutionInterestLabel,
    ),
    submitButton: getPlainTextFromMicrocopyField(data?.submitButtonLabel),
    successMessage: getPlainTextFromMicrocopyField(data?.successMessage),
    errorMessage: getPlainTextFromMicrocopyField(data?.errorMessage),
  };

  // yup validation schema for formik
  const validationSchema = yup.object({
    formId: yup.string().required(),
    firstName: yup
      .string()
      .min(1, '')
      .max(40, formFieldLabels.commentExceedsLength)
      .required(formFieldLabels.firstNameRequired),
    lastName: yup
      .string()
      .min(1, '')
      .max(80, formFieldLabels.commentExceedsLength)
      .required(formFieldLabels.lastNameRequired),
    email: yup
      .string()
      .email(formFieldLabels.emailValidation)
      .max(80, formFieldLabels.commentExceedsLength)
      .required(formFieldLabels.emailRequired),
    phoneNumber: yup.string(),
    company: yup.string(),
    jobTitle: yup.string(),
    country: yup.string().required(formFieldLabels.countryRequired),
    state: yup.string().when('country', {
      is: (country) => country === 'Canada' || country === 'United States',
      then: yup.string().required(formFieldLabels.stateValidation),
      otherwise: yup.string().nullable(),
    }),
    comment: yup.string().max(32500, formFieldLabels.commentExceedsLength),
    productInterest: yup.array().of(yup.string()),
    solutionInterest: yup.array().of(yup.string()),
    dates: yup.array().of(yup.date()),
    consent: yup.string(),
    utm_campaign: yup.string(),
    utm_source: yup.string(),
    utm_content: yup.string(),
    utm_medium: yup.string(),
  });

  // let's just clean out a few characters just in case
  function sanitize(str) {
    const specialChars = /[&<>[(:/$){}#;]/g;
    return str.replaceAll(specialChars, '_');
  }

  const [honeypot, setHoneypot] = useState(false);
  const handleHoneypot = (event, newValue) => {
    setHoneypot(newValue);
  };

  // formik object for form fields
  const formik = useFormik({
    initialValues: {
      formId: data.formId,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      company: '',
      jobTitle: '',
      country: '',
      state: '',
      comment: '',
      marketingOptIn: '',
      utm_campaign: '',
      utm_source: '',
      utm_content: '',
      utm_medium: '',
      utm_ref: '',
      gclid: '',
      requestedUser: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formBody = {
        // hidden
        FormID: values.formId,
        Language: locale,
        SourceURL: window.location.href,
        utm_campaign: gtmValues.utm_campaign,
        utm_source: gtmValues.utm_source ? gtmValues.utm_source : 'Miovision',
        utm_content: gtmValues.utm_content,
        utm_medium: gtmValues.utm_medium ? gtmValues.utm_medium : 'Direct',
        utm_ref: cookies.mio_ref,
        gclid: gtmValues.gclid,
        // contact forms
        FirstName: sanitize(values.firstName),
        LastName: sanitize(values.lastName),
        Email: sanitize(values.email),
        PhoneNumber: sanitize(values.phoneNumber),
        JobTitle: sanitize(values.jobTitle),
        Comment: sanitize(values.comment),
        Company: sanitize(values.company),
        Country: values.country,
        State: values.state,
        MarketingOptIn: values.marketingOptIn,
        requestedUser: requestedUser,
      };

      // eslint-disable-next-line no-console
      // console.log('registration', formBody);

      setFormState(FORM_STATE.IN_PROGRESS);
      formik.setSubmitting(true);

      // check h o n e y p o t
      if (!honeypot) {
        fetch(CONTACT_FORM_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formBody),
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              // eslint-disable-next-line no-console
              console.error('Could not submit form', response);
              setFormState(FORM_STATE.ERROR);
              formik.setSubmitting(false);
            } else {
              setFormState(FORM_STATE.SUCCESS);
              setTimeout(() => {
                window.location.href = '/thank-you';
              }, 500);
              formik.setSubmitting(false);
              formik.resetForm();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Could not submit form', error);
            setFormState(FORM_STATE.ERROR);
            formik.setSubmitting(false);
          });
      } else {
        setFormState(FORM_STATE.IN_PROGRESS);
        formik.setSubmitting(false);
      } // end h o n e y p o t
    },
  });

  const getRegions = (country) => {
    if (!country) {
      return [];
    }
    const countryData = data.countriesData.find(
      ({ content }) => content[1] === country,
    );

    return countryData.content[2].split('|').map((regionPair) => {
      const [regionName, regionShortCode = null] = regionPair.split('~');
      return { regionName, regionShortCode };
    });
  };

  let showState = false;
  if (
    formik.values.country === 'Canada' ||
    formik.values.country === 'United States'
  ) {
    showState = true;
  }

  // FormID's to omit Opt in checkbox
  const omitOptInMap = [
    'a6L1T000000U0z2UAC', // Axikon
  ];
  let showOptIn = true;
  if (omitOptInMap.includes(formik.values.formId)) {
    showOptIn = false;
  }

  let title, subtitle;

  if (data?.title) {
    title = data?.title;
  }
  if (data?.subheading) {
    subtitle = data?.subheading;
  }
  let hasIntro = false;
  if (title || subtitle) {
    hasIntro = true;
  }

  if (!hasMounted) {
    return null;
  }

  return (
    <Container>
      {hasIntro && (
        <div className="container mb-md">
          {title && (
            <Typography
              as="h2"
              variant="h2"
              className="mb-0 block text-center text-primaryMain"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography as="p" variant="body" className="text-center mt-xs">
              {subtitle}
            </Typography>
          )}
        </div>
      )}
      <div className="container my-sm">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-sm">
          <div className="lg:col-span-8 lg:col-start-3">
            <form onSubmit={formik.handleSubmit} className="h-full">
              {formState === FORM_STATE.IN_PROGRESS ? (
                <></>
              ) : formState === FORM_STATE.SUCCESS ? (
                <div
                  id="submitSuccess"
                  className="submitSuccess h-full w-full flex items-center text-center border-2 border-primaryMain rounded py-8 px-md mb-sm"
                >
                  {data?.successMessage?.copy && (
                    <RichTextContainer className="text-center w-full">
                      {renderRichText(
                        data?.successMessage
                          ?.copy as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                        options,
                      )}
                    </RichTextContainer>
                  )}
                </div>
              ) : (
                <div className="mb-sm">
                  <Chip
                    label={formFieldLabels.errorMessage}
                    color="secondary"
                    variant="outlined"
                  />
                </div>
              )}
              <div
                className={`inputsWrapper ${
                  formState === FORM_STATE.SUCCESS ? 'hidden' : ''
                } `}
              >
                <input
                  type="hidden"
                  name="utm_campaign"
                  id="utm_campaign"
                  value={gtmValues.utm_campaign}
                />
                <input
                  type="hidden"
                  name="utm_source"
                  id="utm_source"
                  value={
                    gtmValues.utm_source ? gtmValues.utm_source : 'Miovision'
                  }
                />
                <input
                  type="hidden"
                  name="utm_content"
                  id="utm_content"
                  value={gtmValues.utm_content}
                />
                <input
                  type="hidden"
                  name="utm_medium"
                  id="utm_medium"
                  value={gtmValues.utm_medium ? gtmValues.utm_medium : 'Direct'}
                />
                <input
                  type="hidden"
                  name="gclid"
                  id="gclid"
                  value={gtmValues.gclid}
                />
                <input
                  type="hidden"
                  name="formId"
                  id="formId"
                  value={formik.values.formId}
                />

                {/* username honeypot. Must be null */}

                <Usercheck>
                  <Checkbox
                    aria-label="username"
                    aria-hidden={true}
                    id="username"
                    name="username"
                    value={false}
                    onChange={handleHoneypot}
                    tabIndex="-1"
                  />
                </Usercheck>

                <div className="lg:grid lg:grid-cols-12 lg:gap-x-sm">
                  <div className="lg:col-span-12">
                    <div>
                      <TextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        label={formFieldLabels.firstName + '*'}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </div>
                    <div className="mt-edge">
                      <TextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        label={formFieldLabels.lastName + '*'}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                    </div>
                    <div className="mt-edge">
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label={formFieldLabels.email + '*'}
                        type="email"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                    <div className="mt-edge">
                      <TextField
                        select
                        id="country"
                        name="country"
                        label={formFieldLabels.country}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.country}
                        onChange={(e) => {
                          // unset state value
                          formik.setFieldValue('state', '');

                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        fullWidth
                      >
                        {data?.countriesData?.map(({ content }, index) => (
                          <MenuItem
                            key={`country-${index}-${content[1]}`}
                            value={content[1]}
                          >
                            {content[0]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    {showState && (
                      <div className="mt-edge">
                        <TextField
                          select
                          id="state"
                          name="state"
                          label={formFieldLabels.state}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          disabled={formik.isSubmitting}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.state)
                          }
                          helperText={
                            formik.touched.country && formik.errors.state
                          }
                        >
                          <MenuItem value={''}>
                            Please select a state/province...
                          </MenuItem>
                          {getRegions(formik.values.country).map(
                            ({ regionName, regionShortCode }) => (
                              <MenuItem
                                key={regionShortCode}
                                value={regionShortCode}
                              >
                                {regionName}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      </div>
                    )}
                  </div>
                  <div className="mt-edge lg:col-span-12">
                    <div className="">
                      <TextField
                        fullWidth
                        id="company"
                        name="company"
                        label={formFieldLabels.company}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.company}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.company &&
                          Boolean(formik.errors.company)
                        }
                        helperText={
                          formik.touched.company && formik.errors.company
                        }
                      />
                    </div>
                    <div className="mt-edge">
                      <TextField
                        fullWidth
                        id="jobTitle"
                        name="jobTitle"
                        label={formFieldLabels.jobTitle}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.jobTitle}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.jobTitle &&
                          Boolean(formik.errors.jobTitle)
                        }
                        helperText={
                          formik.touched.jobTitle && formik.errors.jobTitle
                        }
                      />
                    </div>
                    <div className="mt-edge">
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label={formFieldLabels.phoneNumber}
                        type="tel"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        }
                      />
                    </div>
                    <div className="mt-edge">
                      <TextField
                        fullWidth
                        id="comment"
                        name="comment"
                        label={formFieldLabels.comment}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        multiline
                        minRows={4}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched.comment &&
                          Boolean(formik.errors.comment)
                        }
                        helperText={
                          formik.touched.comment && formik.errors.comment
                        }
                      />
                    </div>
                    {showOptIn && (
                      <div className="mt-edge">
                        {formFieldLabels?.marketingOptIn && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                aria-label="marketingOptIn"
                                id="marketingOptIn"
                                name="marketingOptIn"
                                color="primary"
                                disabled={formik.isSubmitting}
                                value={formik.values.marketingOptIn}
                                onChange={formik.handleChange}
                              />
                            }
                            label={
                              gdprMap.indexOf(formik.values.country) !== -1
                                ? formFieldLabels.marketingOptInGdpr
                                : formFieldLabels.marketingOptIn
                            }
                          />
                        )}

                        {gdprMap.indexOf(formik.values.country) !== -1 ? (
                          <RichTextContainer className="text-xs pl-8 mt-4">
                            {data?.marketingOptInGdprMessage?.copy &&
                              renderRichText(
                                data?.marketingOptInGdprMessage
                                  ?.copy as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                options,
                              )}
                          </RichTextContainer>
                        ) : (
                          <RichTextContainer className="text-xs pl-8 mt-4">
                            {data?.marketingOptInMessage?.copy &&
                              renderRichText(
                                data?.marketingOptInMessage
                                  ?.copy as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                options,
                              )}
                          </RichTextContainer>
                        )}
                      </div>
                    )}
                    <div className="mt-edge">
                      <Button variant="Outline">
                        <button type="submit" disabled={formik.isSubmitting}>
                          <span>{formFieldLabels.submitButton} </span>
                          <ArrowForward className="ml-xxs" />
                        </button>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="lg:col-span-4 pt-sm lg:pt-0">
            {data?.featureImage && (
              <ContentfulImage
                image={data?.featureImage}
                alt={data?.featureImage.description || ''}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Template1_FullWidth;
