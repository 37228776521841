import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';

import { ContentfulComponentPlatformFeatureIntro } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';

const Container = styled.div``;

interface PlatformFeatureIntroProps {
  data?: ContentfulComponentPlatformFeatureIntro;
  className?: string;
}
const PlatformFeatureIntro: React.FC<PlatformFeatureIntroProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();
  return (
    <Container className="mt-lg">
      <div className="container">
        TODO --- PlatformFeatureIntro COMPONENT ---
        src/components/contentful/components/PlatformFeatureIntro.tsx
      </div>
    </Container>
  );
};
export default PlatformFeatureIntro;
