import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { ContentfulComponentCustomerLogos } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="font-light text-3xl mb-4">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [INLINES.HYPERLINK]: (node) => {
      return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a href={node?.data?.target?.file?.url} rel="noreferrer">
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const Container = styled.div<{
  backgroundImage?: string;
}>`
  ${(props) => {
    if (props.backgroundImage) {
      return `
        padding: ${props.theme.spacing.xs} 0 ${props.theme.spacing.lg};
        background-image: url(${props.backgroundImage});
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        min-height: 600px;
      `;
    }
  }}
  @media (max-width: ${(props) => props.theme.breakpoint.md}) {
    background: none;
  }
`;
const CustomerLogoList = styled.ul`
  margin: 0 calc(${(props) => props.theme.spacing.sm} / 2 * -1);
`;
const CustomerLogoListItem = styled.li``;

const Card = styled.div``;

interface CustomerLogosProp {
  data?: ContentfulComponentCustomerLogos;
  className?: string;
}
const CustomerLogos: React.FC<CustomerLogosProp> = (props) => {
  const { data } = props;

  let layout = 'Default';
  if (data?.layout) {
    layout = data?.layout;
  }

  let backgroundImage;
  if (data?.backgroundImage?.file?.url) {
    backgroundImage = data?.backgroundImage?.file?.url;
  }

  if (layout === 'Cards') {
    return (
      <Container
        className="mt-lg mb-xs mx-auto"
        backgroundImage={backgroundImage}
      >
        <div className="px-8">
          {data?.headline && (
            <Typography
              as="h2"
              variant="h2"
              className="text-center text-primaryMain"
            >
              {data?.headline}
            </Typography>
          )}
          {data?.shortDescription?.raw && (
            <div className="max-w-5xl mx-auto mt-sm text-center">
              {renderRichText(
                data?.shortDescription as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                options,
              )}
            </div>
          )}
          <div className="flex flex-wrap gap-sm mt-sm justify-center">
            {data?.card1Title && data?.card1Logos?.length > 0 && (
              <Card className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded bg-white shadow-4 flex flex-col p-xs text-center">
                <p className="font-medium mb-8">{data?.card1Title}</p>
                <div className="w-full flex items-stretch flex-wrap">
                  {data?.card1Logos?.map((logo) => {
                    return (
                      <div
                        key={logo.id}
                        className="flex flex-col justify-center items-center p-3 w-1/2 xxl:w-1/3"
                      >
                        <img
                          src={logo.value}
                          alt={logo.key}
                          className="max-h-[90px] max-w-[90px] mb-2"
                        />
                        {data.showNames && (
                          <span className="leading-tight text-xs">
                            {logo.key}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
            {data?.card2Title && data?.card2Logos?.length > 0 && (
              <Card className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded bg-white shadow-4 flex flex-col p-xs text-center">
                <p className="font-medium mb-8">{data?.card2Title}</p>
                <div className="w-full flex items-stretch flex-wrap">
                  {data?.card2Logos?.map((logo) => {
                    return (
                      <div
                        key={logo.id}
                        className="flex flex-col justify-center items-center p-3 w-1/2 xxl:w-1/3"
                      >
                        <img
                          src={logo.value}
                          alt={logo.key}
                          className="max-h-[90px] max-w-[90px] mb-2"
                        />
                        {data.showNames && (
                          <span className="leading-tight text-xs">
                            {logo.key}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
            {data?.card3Title && data?.card3Logos?.length > 0 && (
              <Card className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded bg-white shadow-4 flex flex-col p-xs text-center">
                <p className="font-medium mb-8">{data?.card3Title}</p>
                <div className="w-full flex items-stretch flex-wrap">
                  {data?.card3Logos?.map((logo) => {
                    return (
                      <div
                        key={logo.id}
                        className="flex flex-col justify-center items-center p-3 w-1/2 xxl:w-1/3"
                      >
                        <img
                          src={logo.value}
                          alt={logo.key}
                          className="max-h-[90px] max-w-[90px] mb-2"
                        />
                        {data.showNames && (
                          <span className="leading-tight text-xs">
                            {logo.key}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}

            {data?.card4Title && data?.card4Logos?.length > 0 && (
              <Card className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded bg-white shadow-4 flex flex-col p-xs text-center">
                <p className="font-medium mb-8">{data?.card4Title}</p>
                <div className="w-full flex items-stretch flex-wrap">
                  {data?.card4Logos?.map((logo) => {
                    return (
                      <div
                        key={logo.id}
                        className="flex flex-col justify-center items-center p-3 w-1/2 xxl:w-1/3"
                      >
                        <img
                          src={logo.value}
                          alt={logo.key}
                          className="max-h-[90px] max-w-[90px] mb-2"
                        />
                        {data.showNames && (
                          <span className="leading-tight text-xs">
                            {logo.key}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
            {data?.card5Title && data?.card5Logos?.length > 0 && (
              <Card className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded bg-white shadow-4 flex flex-col p-xs text-center">
                <p className="font-medium mb-8">{data?.card5Title}</p>
                <div className="w-full flex items-stretch flex-wrap">
                  {data?.card5Logos?.map((logo) => {
                    return (
                      <div
                        key={logo.id}
                        className="flex flex-col justify-center items-center p-3 w-1/2 xxl:w-1/3"
                      >
                        <img
                          src={logo.value}
                          alt={logo.key}
                          className="max-h-[90px] max-w-[90px] mb-2"
                        />
                        {data.showNames && (
                          <span className="leading-tight text-xs">
                            {logo.key}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
            {data?.card6Title && data?.card6Logos?.length > 0 && (
              <Card className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded bg-white shadow-4 flex flex-col p-xs text-center">
                <p className="font-medium mb-8">{data?.card6Title}</p>
                <div className="w-full flex items-stretch flex-wrap">
                  {data?.card6Logos?.map((logo) => {
                    return (
                      <div
                        key={logo.id}
                        className="flex flex-col justify-center items-center p-3 w-1/2 xxl:w-1/3"
                      >
                        <img
                          src={logo.value}
                          alt={logo.key}
                          className="max-h-[90px] max-w-[90px] mb-2"
                        />
                        {data.showNames && (
                          <span className="leading-tight text-xs">
                            {logo.key}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
          </div>
        </div>
      </Container>
    );
  } else {
    return (
      <Container className="mt-lg">
        <div className="container">
          {data?.headline && (
            <Typography
              as="h2"
              variant="h2"
              className="text-center text-primaryMain"
            >
              {data?.headline}
            </Typography>
          )}
          {data?.shortDescription?.raw && (
            <div className="max-w-5xl mx-auto mt-sm text-center">
              {renderRichText(
                data?.shortDescription as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                options,
              )}
            </div>
          )}
          {data?.customers && data?.customers?.length > 0 && (
            <div className="">
              <CustomerLogoList className="flex flex-wrap items-center justify-center mt-sm md:mt-md">
                {data?.customers?.map((customer) => {
                  const { logo, title, website } = customer;
                  return (
                    <CustomerLogoListItem
                      key={`customers-list-item-${customer.id}`}
                      className="flex justify-center text-center w-1/3 md:w-1/4 lg:w-[172px] px-8 mb-8 "
                    >
                      {website ? (
                        <a
                          href={website}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="inline-block leading-none"
                        >
                          <GatsbyImage
                            image={getImage(logo?.gatsbyImageData)}
                            alt={title || ``}
                          />
                          {data.showNames && <span>{title || ''}</span>}
                        </a>
                      ) : (
                        <div className="leading-none">
                          <GatsbyImage
                            image={getImage(logo?.gatsbyImageData)}
                            alt={title || ``}
                          />
                          {data.showNames && (
                            <span className="text-xs">{title || ''}</span>
                          )}
                        </div>
                      )}
                    </CustomerLogoListItem>
                  );
                })}
              </CustomerLogoList>
            </div>
          )}
        </div>
      </Container>
    );
  }
};
export default CustomerLogos;
