import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  ClickAwayListener,
  Drawer,
  Popper,
  Button as MaterialButton,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import {
  ContentfulComponentMenu,
  ContentfulComponentNavItem,
  ContentfulComponentSiteHeader,
  ContentfulTopicLink,
} from '@/../graphql-types';
import Button from '@/components/layout/Button';
import Typography from '@/components/layout/Typography';
import ContentfulImage from '@/components/ContentfulImage';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div``;
const TopNavigation = styled.nav`
  height: ${(props) => '40px'};
  background: #006dff;
  color: #fff;
`;
const NavigationDesktop = styled.nav`
  height: ${(props) => props.theme.header.desktopHeight};
`;
const DesktopHeaderSpacer = styled.div`
  height: calc(${(props) => props.theme.header.desktopHeight} + 40px);
  @media (max-width: 1024px) {
    height: ${(props) => props.theme.header.mobileHeight};
  }
`;
const NavigationMobile = styled.nav`
  height: ${(props) => props.theme.header.mobileHeight};
`;
const FloatingCTAMobile = styled.a`
  bottom: 30px;
  border: 2px solid rgb(0, 109, 255);
  left: 30px;
  position: fixed;
  padding: 10px 15px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(0, 109, 255);
  background: #fff;
  font-size: 1.2em;
  z-index: 2;
  text-decoration: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;
const PrimaryMenuItem = styled.li`
  height: ${(props) => props.theme.header.mobileHeight};

  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    height: ${(props) => props.theme.header.desktopHeight};
  }

  &.is-popper-open {
    color: ${(props) => props.theme.color.primaryMain};
  }
  & button,
  & > a {
    border-bottom: 5px solid #fff;
  }
  &.is-popper-open button,
  &:hover button,
  &:hover a {
    border-color: ${(props) => props.theme.color.primaryMain};
  }
`;

const TopNavMenuItem = styled.li``;

const MenuMarketLabel = styled.div`
  min-height: 16px;
`;
const MenuPrimaryLink = styled.div`
  min-height: 25px;
`;

const NewBadge = styled.span`
  color: ${(props) => props.theme.color.primaryMain};
  border: 1px solid ${(props) => props.theme.color.primaryMain};
  font-weight: 700;
  display: inline-block;
  line-height: 1;
  padding: 4px 6px;
  border-radius: 10px;
  margin-left: 0.5em;
  font-size: ${(props) => props.theme.fontSize.caption};
  background: ${(props) => props.theme.color.grey4};
`;

interface HeaderProps {
  data: ContentfulComponentSiteHeader;
  location: Location;
  className?: string;
}
const Header: React.FC<HeaderProps> = (props) => {
  const { data, location } = props;
  const className =
    props.className + ' fixed top-0 left-0 right-0 bg-white z-30';
  const { locale } = useLocalization();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [mobileMenuDrawers, setMobileMenuDrawers] = React.useState({});
  const [desktopPopperElements, setDesktopPopperElements] = React.useState([]);

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  let ctaText2, ctaLocalLink2, ctaExternalLink2, target2;
  if (data?.ctaButton2) {
    if (data?.ctaButton2.link?.page?.slug) {
      ctaLocalLink2 = getFullLink(data?.ctaButton2?.link?.page, locale);
    } else if (data?.ctaButton2?.link?.url) {
      ctaExternalLink2 = data?.ctaButton2?.link?.url;
    }

    if (data?.ctaButton2?.text) {
      ctaText2 = data?.ctaButton2?.text;
    } else if (data?.ctaButton2?.link?.text) {
      ctaText2 = data?.ctaButton2?.link?.text;
    }

    if (data?.ctaButton2?.link?.openInNewWindow) {
      target2 = '_blank';
    }
  }

  const btnVariant2 =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton2?.variant;

  let showMenu = true;
  if (!data?.primaryNavigation && !data?.userNavigation) {
    showMenu = false;
  }

  const togglePopper = (event, id) => {
    const newDesktopPopperElements = [];
    newDesktopPopperElements[id] = event.currentTarget;
    setDesktopPopperElements(newDesktopPopperElements);
  };

  const closePopper = (id) => {
    const newDesktopPopperElements = [];
    newDesktopPopperElements[id] = null;
    setDesktopPopperElements(newDesktopPopperElements);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleMobileMenuDrawer = (event, id) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    // copy mobile menu drawer states
    const drawers = { ...mobileMenuDrawers };
    // toggle drawer associated with id
    drawers[id] = !drawers[id];

    setMobileMenuDrawers(drawers);
  };

  const buildDesktopUserNavItem = (
    navItem: ContentfulComponentNavItem,
    index: number,
  ) => {
    let menuItemElement = (
      <React.Fragment key={`nav-item-${navItem.id}-${index}`}></React.Fragment>
    );
    const hasSubMenus = navItem.menus != null;
    if (hasSubMenus) {
      // menu item has sub-menus
      menuItemElement = (
        <React.Fragment key={`nav-item-${navItem.id}-${index}`}>
          <button
            type="button"
            aria-describedby={`menu-${navItem.text}`}
            onMouseEnter={(e) => togglePopper(e, navItem.id)}
            className="h-full flex items-center text-gray-500 hover:text-primaryMain"
          >
            <Typography variant="subtitle">{navItem.text}</Typography>

            {desktopPopperElements[navItem.id] ? (
              <KeyboardArrowUp className="ml-1" />
            ) : (
              <KeyboardArrowDown className="ml-1" />
            )}
          </button>
          <Popper
            id={`menu-${navItem.id}`}
            anchorEl={desktopPopperElements[navItem.id]}
            open={Boolean(desktopPopperElements[navItem.id])}
            onMouseLeave={() => closePopper(navItem.id)}
            style={{ zIndex: 9999 }}
          >
            <ClickAwayListener onClickAway={() => closePopper(navItem.id)}>
              <ul className="p-edge bg-white shadow-top">
                {navItem.menus?.map((menu, index) => {
                  return (
                    <li
                      key={`popper-menu-${menu.id}-${index}`}
                      className="border-b last:border-0 pb-xxs last:pb-0 mt-xxs first:mt-0"
                    >
                      <ul>
                        {menu.menuItems?.map(
                          (menuItem: ContentfulTopicLink, index) => {
                            if (menuItem?.url) {
                              return (
                                <li
                                  key={`popper-menu-item-${menuItem.id}-${index}`}
                                  className="mt-xxs first:mt-0"
                                >
                                  <a
                                    href={menuItem?.url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <Typography variant="subtitle">
                                      {menuItem.text}
                                    </Typography>
                                  </a>
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  key={`popper-menu-item-${menuItem.id}-${index}`}
                                  className="mt-xxs first:mt-0"
                                >
                                  {menuItem?.page && (
                                    <a
                                      href={getFullLink(menuItem.page, locale)}
                                    >
                                      <Typography variant="subtitle">
                                        {menuItem.text}
                                      </Typography>
                                    </a>
                                    // <LocalizedLink
                                    //   language={locale}
                                    //   to={getFullLink(menuItem.page, locale)}
                                    // >
                                    //   <Typography variant="subtitle">
                                    //     {menuItem.text}
                                    //   </Typography>
                                    // </LocalizedLink>
                                  )}
                                </li>
                              );
                            }
                          },
                        )}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </ClickAwayListener>
          </Popper>
        </React.Fragment>
      );
    } else {
      // menu item has no submenu
      // determine if internal or an external link
      if (navItem.link?.url) {
        // regular anchor tag
        menuItemElement = (
          <a
            key={`nav-item-${navItem.id}-${index}`}
            href={navItem.link?.url}
            target={navItem.link?.openInNewWindow ? '_blank' : ''}
            rel="noreferrer noopener"
            className="h-full flex items-center no-underline text-gray-500 hover:text-primaryMain"
          >
            <Typography variant="subtitle">{navItem.text}</Typography>
          </a>
        );
      } else {
        // localized link
        menuItemElement = (
          <>
            {navItem?.link?.page && (
              <a
                href={getFullLink(navItem.link?.page, locale)}
                className="flex items-center no-underline text-gray-500 hover:text-primaryMain"
              >
                <Typography variant="subtitle">{navItem.text}</Typography>
              </a>
              // <LocalizedLink
              //   language={locale}
              //   to={getFullLink(navItem.link?.page, locale)}
              //   className="flex items-center no-underline text-gray-500 hover:text-primaryMain"
              // >
              //   <Typography variant="subtitle">{navItem.text}</Typography>
              // </LocalizedLink>
            )}
          </>
        );
      }
    }
    return (
      <PrimaryMenuItem
        key={`primary-menu-item-${navItem.id}-${index}`}
        className={`flex items-center ml-edge first:ml-0 ${
          desktopPopperElements[navItem.id] ? 'is-popper-open' : ''
        }`}
      >
        {menuItemElement}
      </PrimaryMenuItem>
    );
  };

  const buildDesktopTopBarItem = (
    navItem: ContentfulComponentNavItem,
    index: number,
  ) => {
    let menuItemElement = (
      <React.Fragment key={`nav-item-${navItem.id}-${index}`}></React.Fragment>
    );
    const hasSubMenus = navItem.menus != null;
    if (hasSubMenus) {
      // menu item has sub-menus
      menuItemElement = (
        <React.Fragment key={`nav-item-${navItem.id}-${index}`}>
          <button
            type="button"
            aria-describedby={`menu-${navItem.text}`}
            onMouseEnter={(e) => togglePopper(e, navItem.id)}
            className="h-full flex items-center hover:opacity-80 hover:text-white"
          >
            <Typography variant="subtitle">{navItem.text}</Typography>

            {desktopPopperElements[navItem.id] ? (
              <KeyboardArrowUp className="ml-1" />
            ) : (
              <KeyboardArrowDown className="ml-1" />
            )}
          </button>
          <Popper
            id={`menu-${navItem.id}`}
            anchorEl={desktopPopperElements[navItem.id]}
            open={Boolean(desktopPopperElements[navItem.id])}
            style={{ zIndex: 9999 }}
            onMouseLeave={() => closePopper(navItem.id)}
          >
            <>
              <ul className="p-edge bg-white shadow-top">
                {navItem.menus?.map((menu, index) => {
                  return (
                    <li
                      key={`popper-menu-${menu.id}-${index}`}
                      className="border-b last:border-0 pb-xxs last:pb-0 mt-xxs first:mt-0"
                    >
                      <ul>
                        {menu.menuItems?.map(
                          (menuItem: ContentfulTopicLink, index) => {
                            if (menuItem?.url) {
                              return (
                                <li
                                  key={`popper-menu-item-${menuItem.id}-${index}`}
                                  className="mt-xxs first:mt-0"
                                >
                                  <a
                                    href={menuItem?.url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <Typography variant="subtitle">
                                      {menuItem.text}
                                    </Typography>
                                  </a>
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  key={`popper-menu-item-${menuItem.id}-${index}`}
                                  className="mt-xxs first:mt-0"
                                >
                                  {menuItem?.page && (
                                    <a
                                      href={getFullLink(menuItem.page, locale)}
                                    >
                                      <Typography variant="subtitle">
                                        {menuItem.text}
                                      </Typography>
                                    </a>
                                  )}
                                </li>
                              );
                            }
                          },
                        )}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </>
          </Popper>
        </React.Fragment>
      );
    } else {
      // menu item has no submenu
      // determine if internal or an external link
      if (navItem.link?.url) {
        // regular anchor tag
        menuItemElement = (
          <a
            key={`nav-item-${navItem.id}-${index}`}
            href={navItem.link?.url}
            target={navItem.link?.openInNewWindow ? '_blank' : ''}
            rel="noreferrer noopener"
            className="h-full flex items-center no-underline hover:opacity-80 hover:text-white"
          >
            <Typography variant="subtitle">{navItem.text}</Typography>
          </a>
        );
      } else {
        // localized link
        menuItemElement = (
          <>
            {navItem?.link?.page && (
              <a
                href={getFullLink(navItem.link?.page, locale)}
                className="flex items-center no-underline hover:opacity-80 hover:text-white"
              >
                <Typography variant="subtitle">{navItem.text}</Typography>
              </a>
            )}
          </>
        );
      }
    }
    return (
      <TopNavMenuItem
        key={`primary-menu-item-${navItem.id}-${index}`}
        className={`flex items-center ml-edge first:ml-0 ${
          desktopPopperElements[navItem.id] ? 'is-popper-open' : ''
        }`}
      >
        {menuItemElement}
      </TopNavMenuItem>
    );
  };

  const createMenu = (
    menu: ContentfulComponentMenu,
    isMultiMenu: boolean,
    index: number,
  ) => {
    let menuElement = (
      <React.Fragment key={`menu-${menu.id}-${index}`}></React.Fragment>
    );
    if (isMultiMenu) {
      // builds out multiple menu navigation
      menuElement = (
        <React.Fragment key={`menu-${menu.id}-${index}`}>
          <MenuMarketLabel>
            {menu.menuTitle && (
              <Typography
                as="h3"
                variant="captionCaps"
                className="text-gray-500"
              >
                {menu.menuTitle}
              </Typography>
            )}
          </MenuMarketLabel>

          <MenuPrimaryLink className="mt-edge">
            {menu.primaryLink && (
              <>
                {menu?.primaryLink?.page && (
                  <a
                    href={getFullLink(menu.primaryLink?.page, locale)}
                    className="flex items-center"
                  >
                    <Typography variant="body" className="font-medium">
                      {menu.primaryLink.text}
                    </Typography>
                    <ChevronRight className="ml-1" />
                  </a>
                  // <LocalizedLink
                  //   language={locale}
                  //   to={getFullLink(menu.primaryLink?.page, locale)}
                  //   className="flex items-center"
                  // >
                  //   <Typography variant="body" className="font-medium">
                  //     {menu.primaryLink.text}
                  //   </Typography>
                  //   <ChevronRight className="ml-1" />
                  // </LocalizedLink>
                )}
              </>
            )}
          </MenuPrimaryLink>

          <ul className="list-none mt-xs ml-xs">
            {menu.menuItems &&
              menu.menuItems.map((menuItem: ContentfulTopicLink, index) => {
                return (
                  <li
                    key={`menu-item-${menuItem.id}-${index}`}
                    className="mt-xs first:mt-0"
                  >
                    {menuItem?.page && (
                      <a
                        href={getFullLink(menuItem.page, locale)}
                        className="text-gray-700 hover:text-primaryMain w-full inline-block"
                      >
                        <Typography variant="subtitle">
                          {menuItem.text}
                        </Typography>
                        {menuItem?.isNew && (
                          <NewBadge className="">NEW</NewBadge>
                        )}
                      </a>
                      // <LocalizedLink
                      //   language={locale}
                      //   to={getFullLink(menuItem.page, locale)}
                      //   className="text-gray-700 hover:text-primaryMain w-full inline-block"
                      // >
                      //   <Typography variant="subtitle">
                      //     {menuItem.text}
                      //   </Typography>
                      // </LocalizedLink>
                    )}
                  </li>
                );
              })}
          </ul>
        </React.Fragment>
      );
    } else {
      // builds the simple menu list
      menuElement = (
        <ul key={`menu-${menu.id}-${index}`}>
          {menu.menuItems &&
            menu.menuItems.map((menuItem: ContentfulTopicLink, index) => (
              <li
                key={`menu-item-${menuItem.id}-${index}`}
                className="mt-xs first:mt-0"
              >
                {menuItem?.page && (
                  <a href={getFullLink(menuItem.page, locale)}>
                    <Typography variant="body" as={`span`}>
                      {menuItem.text}
                    </Typography>
                  </a>
                  // <LocalizedLink
                  //   language={locale}
                  //   to={getFullLink(menuItem.page, locale)}
                  // >
                  //   <Typography variant="body" as={`span`}>
                  //     {menuItem.text}
                  //   </Typography>
                  // </LocalizedLink>
                )}
              </li>
            ))}
        </ul>
      );
    }
    return (
      <div
        key={`menu-wrapper-${menu.id}-${index}`}
        className={`${
          isMultiMenu
            ? 'bg-white pl-sm pr-sm first:pl-0 last:pl-0 ml-md first:ml-0 last:ml-0 border-l first:border-l-0 last:border-l-0'
            : ''
        }`}
      >
        {menuElement}
      </div>
    );
  };

  const buildDesktopNavItem = (item, index: number) => {
    // Top-level menu items can either be a direct link or contain
    // nested menus
    const hasMenus = item?.menus && item?.menus.length > 0;
    const isMultiMenu = item?.menus != null && item?.menus.length > 1;

    let menuItemElement;
    if (hasMenus) {
      menuItemElement = (
        <React.Fragment key={`desktop-nav-item-${item.id}-${index}`}>
          <button
            type="button"
            aria-describedby={`menu-${item.text}`}
            onMouseEnter={(e) => togglePopper(e, index)}
            className="h-full flex items-center hover:text-primaryMain"
          >
            <Typography variant="subtitle">{item.text}</Typography>

            {desktopPopperElements[index] ? (
              <KeyboardArrowUp className="ml-1" />
            ) : (
              <KeyboardArrowDown className="ml-1" />
            )}
          </button>

          <Popper
            id={`menu-${item.id}`}
            disablePortal={true}
            anchorEl={desktopPopperElements[index]}
            open={Boolean(desktopPopperElements[index])}
            onMouseLeave={() => closePopper(index)}
            style={{
              zIndex: 9999,
              backgroundColor: '#fff',
              width: isMultiMenu ? '100%' : 'auto',
            }}
            popperOptions={{
              modifiers: {
                preventOverflow: {
                  padding: 0,
                },
              },
            }}
          >
            <>
              <div
                className={`bg-white shadow-top ${
                  isMultiMenu ? 'py-sm px-lg w-full justify-center' : 'p-edge'
                } flex`}
                tabIndex={1}
              >
                {item.menus?.map((menu, index) =>
                  createMenu(menu, isMultiMenu, index),
                )}
              </div>
            </>
          </Popper>
        </React.Fragment>
      );
    } else {
      menuItemElement = (
        <>
          {item?.link?.page && (
            <a
              href={getFullLink(item.link?.page, locale)}
              key={`desktop-nav-item-${item.id}-${index}`}
              className="no-underline h-full flex items-center"
            >
              <Typography variant="subtitle">{item.text}</Typography>
            </a>
            // <LocalizedLink
            //   key={`desktop-nav-item-${item.id}-${index}`}
            //   language={locale}
            //   to={getFullLink(item.link?.page, locale)}
            //   className="no-underline h-full flex items-center"
            // >
            //   <Typography variant="subtitle">{item.text}</Typography>
            // </LocalizedLink>
          )}
        </>
      );
    }
    return (
      <PrimaryMenuItem
        key={`primary-menu-item-${item.id}-${index}`}
        className={`flex items-center ml-edge first:ml-0 ${
          desktopPopperElements[index] ? 'is-popper-open' : ''
        }`}
      >
        {menuItemElement}
      </PrimaryMenuItem>
    );
  };

  const buildMobileMenuItem = (
    navItem: ContentfulComponentNavItem,
    index: number,
  ) => {
    let menuItemElement = (
      <React.Fragment
        key={`mobile-menu-item-${navItem.id}-${index}`}
      ></React.Fragment>
    );
    const hasSubMenus = navItem.menus != null;
    if (hasSubMenus) {
      // menu item has sub-menus
      menuItemElement = (
        <React.Fragment key={`mobile-menu-item-${navItem.id}-${index}`}>
          <div className="flex w-full items-center text-left">
            <Typography variant="body" className="w-full p-xs">
              {navItem.text}
            </Typography>
            <ChevronRight className="ml-auto" />
          </div>
          <Drawer
            anchor="right"
            open={mobileMenuDrawers[navItem.id]}
            onClose={(e) => toggleMobileMenuDrawer(e, navItem.id)}
          >
            <div
              style={{ minWidth: '280px' }}
              className="h-full"
              role="presentation"
              onKeyDown={(e) => toggleMobileMenuDrawer(e, navItem.id)}
            >
              <div className="flex flex-col h-full">
                <div className="p-edge flex-initial items-center flex">
                  <div>
                    <ChevronLeft
                      onClick={(e) => toggleMobileMenuDrawer(e, navItem.id)}
                      className="cursor-pointer text-gray-500"
                      fontSize="large"
                    />
                  </div>
                  <div className="ml-auto">
                    <CloseIcon
                      onClick={(e) => toggleMobileMenuDrawer(e, navItem.id)}
                      className="cursor-pointer text-gray-500"
                      fontSize="large"
                    />
                  </div>
                </div>
                <div className="p-edge flex-initial flex">
                  <ul className="w-full">
                    {navItem.menus?.map((menu, index) => {
                      // we only want the primary links on mobile sub menus
                      let subDrawerLinkElement = (
                        <React.Fragment
                          key={`sub-menu-item-${menu?.id}-${index}`}
                        ></React.Fragment>
                      );
                      if (menu.primaryLink) {
                        subDrawerLinkElement = (
                          <li
                            key={`sub-menu-item-${menu?.id}-${index}`}
                            className={`flex cursor-pointer items-center text-primaryMain border-t last:border-b transition-colors hover:bg-blue-100 ${
                              location?.pathname ===
                              `/${menu.primaryLink?.page?.slug}`
                                ? 'active'
                                : ''
                            }`}
                          >
                            {menu.primaryLink?.page && (
                              <a
                                href={getFullLink(
                                  menu.primaryLink?.page,
                                  locale,
                                )}
                                className="flex items-center p-xs w-full"
                              >
                                <Typography variant="body">
                                  {menu.primaryLink?.text}
                                </Typography>
                              </a>
                              // <LocalizedLink
                              //   language={locale}
                              //   to={getFullLink(menu.primaryLink?.page, locale)}
                              //   className="flex items-center p-xs w-full"
                              // >
                              //   <Typography variant="body">
                              //     {menu.primaryLink?.text}
                              //   </Typography>
                              // </LocalizedLink>
                            )}
                          </li>
                        );
                      } else {
                        subDrawerLinkElement = (
                          <React.Fragment
                            key={`sub-menu-item-${menu?.id}-${index}`}
                          >
                            {menu.menuItems?.map(
                              (menuItem: ContentfulTopicLink, index) => {
                                return (
                                  <li
                                    key={`sub-menu-menu-item-${menuItem?.id}-${index}`}
                                    className={`flex cursor-pointer items-center text-primaryMain border-t last:border-b transition-colors hover:bg-blue-100 ${
                                      location?.pathname ===
                                      `/${menu.primaryLink?.page?.slug}`
                                        ? 'active'
                                        : ''
                                    }`}
                                  >
                                    {menuItem?.page && (
                                      <a
                                        key={`sub-drawer-link-${menuItem?.text}`}
                                        href={getFullLink(
                                          menuItem?.page,
                                          locale,
                                        )}
                                        className="flex items-center p-xs w-full"
                                      >
                                        <Typography variant="body">
                                          {menuItem?.text}
                                        </Typography>
                                      </a>
                                      // <LocalizedLink
                                      //   key={`sub-drawer-link-${menuItem?.text}`}
                                      //   language={locale}
                                      //   to={getFullLink(menuItem?.page, locale)}
                                      //   className="flex items-center p-xs w-full"
                                      // >
                                      //   <Typography variant="body">
                                      //     {menuItem?.text}
                                      //   </Typography>
                                      // </LocalizedLink>
                                    )}
                                  </li>
                                );
                              },
                            )}
                          </React.Fragment>
                        );
                      }

                      return subDrawerLinkElement;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      );
    } else {
      // menu item has no submenu
      // determine if internal or external link
      const isExternal = navItem.link?.url != null;
      if (isExternal) {
        // regular anchor tag
        menuItemElement = (
          <a
            key={`mobile-menu-item-${navItem.id}-${index}`}
            href={navItem?.link?.url}
            target="_blank"
            rel="noreferrer noopener"
            className="flex items-center p-xs w-full"
          >
            <Typography variant="body">{navItem.text}</Typography>
          </a>
        );
      } else {
        // localized link
        menuItemElement = (
          <a
            key={`mobile-menu-item-${navItem.id}-${index}`}
            href={
              navItem?.link?.page
                ? getFullLink(navItem?.link?.page, locale)
                : '#'
            }
            className="flex items-center p-xs w-full"
          >
            <Typography variant="body">{navItem.text}</Typography>
          </a>
          // <LocalizedLink
          //   key={`mobile-menu-item-${navItem.id}-${index}`}
          //   language={locale}
          //   to={
          //     navItem?.link?.page
          //       ? getFullLink(navItem?.link?.page, locale)
          //       : '#'
          //   }
          //   className="flex items-center p-xs w-full"
          // >
          //   <Typography variant="body">{navItem.text}</Typography>
          // </LocalizedLink>
        );
      }
    }
    return (
      <li
        key={`menu-item-${navItem.id}-${index}`}
        className={`flex flex-col items-start cursor-pointer text-primaryMain border-t last:border-b transition-colors hover:bg-blue-100`}
        onClick={
          hasSubMenus ? (e) => toggleMobileMenuDrawer(e, navItem.id) : null
        }
      >
        {menuItemElement}
      </li>
    );
  };

  const buildMobileUserNavigationItem = (
    navItem: ContentfulComponentNavItem,
    index: number,
  ) => {
    let menuItemElement = (
      <React.Fragment
        key={`mobile-user-nav-item-${navItem.id}-${index}`}
      ></React.Fragment>
    );
    const hasSubMenus = navItem.menus != null;
    if (hasSubMenus) {
      // menu item has sub-menus
      menuItemElement = (
        <li
          key={`mobile-user-nav-item-${navItem.id}-${index}`}
          className={`flex-0 items-center justify-center first:border-r px-sm text-primaryMain`}
        >
          <button
            className="flex w-full items-center text-left"
            onClick={(e) => toggleMobileMenuDrawer(e, navItem.id)}
          >
            <Typography variant="body" as="span">
              {navItem.text}
            </Typography>
            <ChevronRight />
          </button>
          <Drawer
            anchor="right"
            open={mobileMenuDrawers[navItem.id]}
            onClose={(e) => toggleMobileMenuDrawer(e, navItem.id)}
          >
            <div
              style={{ minWidth: '280px' }}
              className="h-full"
              role="presentation"
              onKeyDown={(e) => toggleMobileMenuDrawer(e, navItem.id)}
            >
              <div className="flex flex-col h-full">
                <div className="p-edge flex-initial items-center flex">
                  <div>
                    <ChevronLeft
                      onClick={(e) => toggleMobileMenuDrawer(e, navItem.id)}
                      className="cursor-pointer text-gray-500"
                      fontSize="large"
                    />
                  </div>
                  <div className="ml-auto">
                    <CloseIcon
                      onClick={(e) => toggleMobileMenuDrawer(e, navItem.id)}
                      className="cursor-pointer text-gray-500"
                      fontSize="large"
                    />
                  </div>
                </div>
                <div className="p-edge flex-initial flex">
                  <div className="w-full">
                    {navItem.menus?.map((menu, index) => {
                      return (
                        <ul
                          key={`mobile-user-navigation-item-${menu.id}-${index}`}
                          className="first:border-t"
                        >
                          {menu.menuItems?.map(
                            (menuItem: ContentfulTopicLink, index) => {
                              if (menuItem?.url) {
                                return (
                                  <li
                                    key={`menu-item-${menuItem?.id}-${index}`}
                                    className={`flex cursor-pointer items-center text-primaryMain border-t first:border-0 last:border-b transition-colors hover:bg-blue-100`}
                                  >
                                    <a
                                      href={menuItem?.url}
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      className="flex items-center w-full p-xs"
                                    >
                                      <Typography variant="body">
                                        {menuItem?.text}
                                      </Typography>
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li
                                    key={`menu-item-${menuItem?.id}-${index}`}
                                    className={`flex cursor-pointer items-center text-primaryMain border-t first:border-0 last:border-b transition-colors hover:bg-blue-100 ${
                                      location?.pathname ===
                                      `/${menuItem?.page?.slug}`
                                        ? 'active'
                                        : ''
                                    }`}
                                  >
                                    {menuItem?.page && (
                                      <a
                                        href={getFullLink(
                                          menuItem?.page,
                                          locale,
                                        )}
                                        className="flex items-center w-full p-xs"
                                      >
                                        <Typography variant="body">
                                          {menuItem?.text}
                                        </Typography>
                                      </a>
                                      // <LocalizedLink
                                      //   language={locale}
                                      //   to={getFullLink(menuItem?.page, locale)}
                                      //   className="flex items-center w-full p-xs"
                                      // >
                                      //   <Typography variant="body">
                                      //     {menuItem?.text}
                                      //   </Typography>
                                      // </LocalizedLink>
                                    )}
                                  </li>
                                );
                              }
                            },
                          )}
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </li>
      );
    } else {
      // menu item has no submenu
      // determine if internal or external link
      const isExternal = navItem.link?.url != null;
      if (isExternal) {
        // regular anchor tag
        menuItemElement = (
          <li
            key={`mobile-user-nav-item-${navItem.id}-${index}`}
            className={`flex-0 items-center justify-center first:border-r ${
              location?.pathname === `/${navItem.link?.url}` ? 'active' : ''
            }`}
          >
            <a
              href={navItem.link?.url}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center w-full px-sm"
            >
              <Typography variant="body" as="span">
                {navItem.text}
              </Typography>
            </a>
          </li>
        );
      } else {
        // localized link
        menuItemElement = (
          <li
            key={`mobile-user-nav-item-${navItem.id}-${index}`}
            className={`flex-0 items-center justify-center first:border-r ${
              location?.pathname === `/${navItem.link?.page?.slug}`
                ? 'active'
                : ''
            }`}
          >
            <LocalizedLink
              language={locale}
              to={`#`}
              className="flex items-center w-full px-sm"
            >
              <Typography variant="body" as="span">
                {navItem.text}
              </Typography>
            </LocalizedLink>
          </li>
        );
      }
    }
    return menuItemElement;
  };

  return (
    <>
      <Container className={`shadow-md ${className || ''}`}>
        <TopNavigation className="hidden lg:flex flex-col">
          <div className="container text-right h-full">
            {data?.userNavigation && (
              <div className="h-full flex-initial items-center">
                <ul className="h-full flex items-center justify-end">
                  {data?.userNavigation?.map(buildDesktopTopBarItem)}
                  <li className="flex items-center ml-edge first:ml-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      fill="currentcolor"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                    <a
                      className="ml-2 hover:text-white hover:opacity-80 no-underline"
                      href="tel:1-877-646-8476"
                    >
                      1-877-646-8476
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </TopNavigation>
        <NavigationDesktop className="hidden lg:flex flex-col">
          <div className="flex-1">
            <div className="container h-full lg:px-xs xl:px-edge flex items-center">
              <div className="flex flex-initial items-center">
                <a href={getFullLink('/', locale)}>
                  <ContentfulImage
                    image={data?.logo?.logo}
                    alt={data?.logo?.logo?.description || ''}
                  />
                </a>

                {data?.logoSecondary?.logo && (
                  <a
                    href={data?.logoSecondary?.link?.url}
                    target={
                      data?.logoSecondary?.link?.openInNewWindow ? '_blank' : ''
                    }
                    rel="noreferrer"
                    className="ml-4 pl-4 border-l"
                  >
                    <ContentfulImage
                      width={150}
                      image={data?.logoSecondary?.logo}
                      alt={data?.logoSecondary?.logo?.description || ''}
                    />
                  </a>
                )}

                {/* <LocalizedLink language={locale} to="/">
                <ContentfulImage
                  image={data?.logo?.logo}
                  alt={data?.logo?.logo?.description || ''}
                />
              </LocalizedLink> */}
              </div>
              <div className="flex-initial ml-auto flex items-center">
                {data?.primaryNavigation && (
                  <div className="flex-initial items-center">
                    <ul className="h-full flex items-center">
                      {data?.primaryNavigation?.map(buildDesktopNavItem)}
                    </ul>
                  </div>
                )}

                <div className="flex items-center">
                  {ctaLocalLink2 && (
                    <div className="ml-xs pl-xs">
                      <Button variant="Nav">
                        {ctaLocalLink2 && (
                          <a id="nav_cta" href={ctaLocalLink2} target={target2}>
                            <span>{data?.ctaButton2?.text}</span>
                          </a>
                        )}
                      </Button>
                    </div>
                  )}
                  {ctaExternalLink2 && (
                    <div className="ml-xs pl-xs">
                      <Button variant="Nav" className="whitespace-nowrap">
                        <a
                          href={ctaExternalLink2}
                          id="nav_cta"
                          target={target2}
                        >
                          <span>{data?.ctaButton2?.text}</span>
                        </a>
                      </Button>
                    </div>
                  )}

                  {ctaLocalLink && (
                    <div className="ml-xs">
                      <Button variant="Full">
                        {ctaLocalLink && (
                          <a id="nav_cta" href={ctaLocalLink} target={target}>
                            <span>{data?.ctaButton?.text}</span>
                          </a>
                        )}
                      </Button>
                    </div>
                  )}
                  {ctaExternalLink && (
                    <div className="ml-xs">
                      <Button variant="Full" className="whitespace-nowrap">
                        <a href={ctaExternalLink} id="nav_cta" target={target}>
                          <span>{data?.ctaButton?.text}</span>
                        </a>
                      </Button>
                    </div>
                  )}

                  {/* {ctaLink && (
                <div className="ml-xs pl-xs">
                  {data?.ctaButton?.variant === 'Jump-Link' ? (
                    <Button variant="Nav" className="whitespace-nowrap">
                      <a href={ctaLink} className="cta_nav">
                        <span>{ctaText}</span>
                      </a>
                    </Button>
                  ) : (
                    <Button variant="Nav" className="whitespace-nowrap">
                      {ctaLink && (
                        <LocalizedLink
                          language={locale}
                          className="cta_nav"
                          to={ctaLink}
                          target={target}
                        >
                          <span>{ctaText}</span>
                        </LocalizedLink>
                      )}
                    </Button>
                  )}
                </div>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </NavigationDesktop>
        <NavigationMobile
          key={`mobile-menu-anchor`}
          className="container px-edge flex items-center lg:hidden"
        >
          <div className="flex items-center flex-initial">
            <a href={getFullLink('/', locale)}>
              <ContentfulImage
                image={data?.logo?.logo}
                alt={data?.logo?.logo?.description || ''}
              />
            </a>
            {data?.logoSecondary?.logo && (
              <a
                href={data?.logoSecondary?.link?.url}
                target={
                  data?.logoSecondary?.link?.openInNewWindow ? '_blank' : ''
                }
                rel="noreferrer"
                className="ml-2 pl-2 border-l"
              >
                <ContentfulImage
                  width={150}
                  image={data?.logoSecondary?.logo}
                  alt={data?.logoSecondary?.logo?.description || ''}
                />
              </a>
            )}
          </div>
          <div className="flex-initial ml-auto flex items-center">
            <div className="hidden sm:flex flex-initial ml-sm xl:ml-md items-center">
              <div className="flex-initial ml-sm xl:ml-md items-center">
                <ul className="h-full flex items-center">
                  {data?.userNavigation?.map(buildDesktopUserNavItem)}
                </ul>
              </div>
              <div className="flex items-center">
                {ctaLocalLink2 && (
                  <div className="pl-xs">
                    <Button variant="Nav">
                      {ctaLocalLink2 && (
                        <a id="nav_cta" href={ctaLocalLink2} target={target2}>
                          <span>{data?.ctaButton2?.text}</span>
                        </a>
                      )}
                    </Button>
                  </div>
                )}
                {ctaExternalLink2 && (
                  <div className="pl-xs">
                    <Button variant="Nav" className="whitespace-nowrap">
                      <a href={ctaExternalLink2} id="nav_cta" target={target2}>
                        <span>{data?.ctaButton2?.text}</span>
                      </a>
                    </Button>
                  </div>
                )}
                {/* ml-xs border-l pl-xs */}
                <div className="ml-xs">
                  <Button variant="Full" className="whitespace-nowrap">
                    {data?.ctaButton?.link?.page && (
                      <a
                        href={getFullLink(data?.ctaButton?.link?.page, locale)}
                        className="cta_nav"
                      >
                        <Typography variant="subtitle" className="leading-none">
                          {data?.ctaButton?.text}
                        </Typography>
                      </a>
                      // <LocalizedLink
                      //   language={locale}
                      //   to={getFullLink(data?.ctaButton?.link?.page, locale)}
                      //   className="cta_nav"
                      // >
                      //   <Typography variant="subtitle" className="leading-none">
                      //     {data?.ctaButton?.text}
                      //   </Typography>
                      // </LocalizedLink>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            {showMenu && (
              <div className="flex-initial ml-xs">
                <MaterialButton onClick={toggleDrawer}>
                  <MenuIcon fontSize="large" />
                </MaterialButton>
              </div>
            )}
          </div>
          <Drawer anchor="right" open={isMobileMenuOpen} onClose={toggleDrawer}>
            <div
              style={{ minWidth: '320px', maxWidth: '100vw' }}
              className="h-full"
              role="presentation"
              onKeyDown={toggleDrawer}
            >
              <div className="flex flex-col h-full">
                <div className="p-edge flex-initial items-center flex">
                  {data?.logo?.mobileLogo && (
                    <div>
                      {data?.logo?.link?.page?.slug && (
                        <a language={locale} to="/">
                          <ContentfulImage
                            image={data?.logo?.mobileLogo}
                            alt={data?.logo?.mobileLogo?.description || ``}
                          />
                        </a>
                        // <LocalizedLink language={locale} to="/">
                        //   <ContentfulImage
                        //     image={data?.logo?.mobileLogo}
                        //     alt={data?.logo?.mobileLogo?.description || ``}
                        //   />
                        // </LocalizedLink>
                      )}
                    </div>
                  )}
                  <div className="ml-auto">
                    <CloseIcon
                      onClick={toggleDrawer}
                      className="cursor-pointer text-gray-500"
                      fontSize="large"
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-col">
                  <div className="p-edge flex-initial flex">
                    <ul className="w-full">
                      {data?.primaryNavigation?.map(buildMobileMenuItem)}
                    </ul>
                  </div>
                  <div className="flex-initial items-center sm:hidden">
                    <div className="p-edge">
                      <Button
                        variant={data?.ctaButton?.variant}
                        className="w-full"
                      >
                        {data?.ctaButton?.link?.page && (
                          <a
                            href={getFullLink(
                              data?.ctaButton?.link?.page,
                              locale,
                            )}
                            className="no-underline leading-none"
                          >
                            <Typography variant="body">
                              {data?.ctaButton?.text}
                            </Typography>
                          </a>
                          // <LocalizedLink
                          //   language={locale}
                          //   to={getFullLink(data?.ctaButton?.link?.page, locale)}
                          //   className="no-underline leading-none"
                          // >
                          //   <Typography variant="body">
                          //     {data?.ctaButton?.text}
                          //   </Typography>
                          // </LocalizedLink>
                        )}
                      </Button>
                    </div>
                    <div className="p-edge">
                      <ul className="list-none flex justify-center">
                        {data?.userNavigation?.map(
                          buildMobileUserNavigationItem,
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </NavigationMobile>
        <FloatingCTAMobile href="tel:+1-877-646-8476">
          Call now
        </FloatingCTAMobile>
      </Container>
      <DesktopHeaderSpacer />
    </>
  );
};

export default Header;
