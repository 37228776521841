import React from 'react';

interface UndefinedProps {
  message?: string;
}
const Undefined: React.FC<UndefinedProps> = (props) => {
  const { message } = props;
  return (
    <div className="bg-gray-200 m-sm p-sm rounded-md border-2 border-gray-500 break-words">
      <div className="container">{message}</div>
    </div>
  );
};
export default Undefined;
