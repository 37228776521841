import React from 'react';
import styled from 'styled-components';
import { ContentfulComponentHeroBanner } from '@/../graphql-types';
// import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import ContentfulImage from '@/components/ContentfulImage';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { getFullLink } from '@/utils/helpers';

interface Version2HeroProps {
  data?: ContentfulComponentHeroBanner;
  className?: string;
}

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="mb-4 text-lg">{children}</p>
    ),
    [BLOCKS.HR]: (node, children) => <hr className="my-8" />,
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const Container = styled.div<{
  backgroundImageURL: string;
  backgroundImageMobileURL: string;
  allowOverflow?: boolean;
}>`
  max-width: ${(props) => props.theme.container.extra};
  margin: 0 auto;
  position: relative;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    ${(props) => {
      if (props.allowOverflow) {
        return `padding-bottom: 60px;`;
      }
    }}
  }
`;
const BackgroundWrapper = styled.div<{
  backgroundImageURL: string;
  backgroundImageMobileURL: string;
}>`
  width: 100%;
  background-image: url('${(props) => props.backgroundImageMobileURL}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 40px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 40px));
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-bottom: 40px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 60px));
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    background-image: url('${(props) => props.backgroundImageURL}');
  }
  // @media screen and (min-width: ${({ theme }) => theme.breakpoint.xl}) {
  // }
`;

const GradientContainer = styled.div<{
  gradient: string;
}>`
  max-width: ${(props) => props.theme.container.xl};
  width: calc(100% - 4em);
  margin: 0 auto;
  padding-bottom: 60px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 36px));
  ${(props) => {
    switch (props.gradient) {
      case 'Dark':
        return `
          background: ${props.theme.backgroundGradient.heroBlack};
          color: ${props.theme.color.white};
        `;
      case 'Light':
        return `
          background: ${props.theme.color.grey1};
        `;
      case 'Blue':
      default:
        return `
          background: ${props.theme.backgroundGradient.heroBlue};
          color: ${props.theme.color.white};
        `;
    }
  }}
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    min-height: 560px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 57px));
  }
`;

const HeroBody = styled.div`
  padding: 1.5em;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 2em 4em 2em 2em;
  }
`;
const HeroImage = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  .inner {
    max-width: ${(props) => props.theme.container.xl};
    width: calc(100% - 4em);
    margin: 0 auto;
    padding: 2em 2em 0 0;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
  }
`;

const Version2Hero: React.FC<Version2HeroProps> = (props) => {
  const { data, className } = props;
  const { locale } = useLocalization();

  // const bgImage = getImage(data?.backgroundImage?.gatsbyImageData);

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  let hasMobileImage = false;
  if (data?.mobileImage) {
    hasMobileImage = true;
  }

  return (
    <>
      <div id="is_hero_banner_insert"></div>
      <Container
        id="hero_wrapper"
        className={`hero ${className}`}
        allowOverflow={data?.allowOverflow}
      >
        <BackgroundWrapper
          id="hero_background"
          backgroundImageMobileURL={data?.backgroundImageMobile?.fixed?.src}
          backgroundImageURL={data?.backgroundImage?.fixed?.src}
        >
          <GradientContainer
            gradient={data?.gradientColor}
            className="flex justify-between items-center"
          >
            <HeroBody
              id="hero_body"
              className={`w-full text-center
              ${
                hasMobileImage
                  ? 'md:w-2/3 lg:w-1/2 md:text-left'
                  : 'lg:w-1/2 lg:text-left'
              }
            `}
            >
              {data?.eyebrow && (
                <span className="font-bold uppercase text-yellowMain">
                  {data?.eyebrow}
                </span>
              )}
              {data?.title && (
                <Typography id="hero_title" as="h1" variant="h1" className="">
                  {data?.subheading && (
                    <span className="text-yellowMain block md:inline">
                      {data?.subheading}&nbsp;
                    </span>
                  )}
                  {data?.title}
                </Typography>
              )}

              {data?.longDescription?.raw && (
                <div id="hero_description" className={`mt-8`}>
                  {renderRichText(
                    data?.longDescription as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    options,
                  )}
                </div>
              )}

              {ctaLocalLink && (
                <div className="mt-sm sm:mt-sm mb-xxs xxs:mb-xs sm:mb-0">
                  <Button variant={data?.ctaButton?.variant}>
                    {ctaLocalLink && (
                      <LocalizedLink
                        id="hero_cta"
                        language={locale}
                        to={ctaLocalLink}
                        target={target}
                      >
                        <span>{data?.ctaButton?.text}</span>
                      </LocalizedLink>
                    )}
                  </Button>
                </div>
              )}
              {ctaExternalLink && (
                <div className="mt-sm sm:mt-sm mb-xxs xxs:mb-xs sm:mb-0">
                  <Button variant={data?.ctaButton?.variant}>
                    <a href={ctaExternalLink} id="hero_cta" target={target}>
                      <span>{data?.ctaButton?.text}</span>
                    </a>
                  </Button>
                </div>
              )}
            </HeroBody>
            {data?.mobileImage && (
              <ContentfulImage
                image={data?.mobileImage}
                alt={data?.mobileImage.description || ''}
                id="hero_image_small"
                className="hidden md:block lg:hidden w-1/3"
              />
            )}
            {!data?.allowOverflow && (
              <ContentfulImage
                image={data?.featureImage}
                alt={data?.featureImage.description || ''}
                id="hero_image_small"
                className="hidden md:block w-1/2"
              />
            )}
          </GradientContainer>
        </BackgroundWrapper>
        {data?.allowOverflow && (
          <HeroImage className="hidden lg:block pointer-events-none">
            <div className="inner flex justify-end">
              {data?.featureImage && (
                <ContentfulImage
                  image={data?.featureImage}
                  alt={data?.featureImage.description || ''}
                  id="hero_image"
                  className="w-1/2"
                />
              )}
            </div>
          </HeroImage>
        )}
      </Container>
    </>
  );
};
export default Version2Hero;
