import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowDownward, ArrowForward } from '@material-ui/icons';

import { ContentfulComponentTextCta } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div``;

interface TextCtaProps {
  data?: ContentfulComponentTextCta;
  className?: string;
}
const TextCta: React.FC<TextCtaProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  return (
    <Container className="mt-lg">
      <div className="container">
        <div className="max-w-2xl mx-auto mb-md">
          {data?.title && (
            <Typography
              as="h2"
              variant="h2"
              className="text-center text-primaryMain mb-xs"
            >
              {data?.title}
            </Typography>
          )}
          {data?.subheading && (
            <Typography as="p" variant="body" className="text-center">
              {data?.subheading}
            </Typography>
          )}

          {ctaLocalLink && (
            <div className="w-full mt-sm text-center">
              <Button variant={data?.ctaButton?.variant}>
                {ctaLocalLink && (
                  <LocalizedLink
                    id="hero_cta"
                    language={locale}
                    to={ctaLocalLink}
                    target={target}
                  >
                    <span>{data?.ctaButton?.text}</span>
                    <span className="inline-block ml-xxs">
                      <ArrowForward />
                    </span>
                  </LocalizedLink>
                )}
              </Button>
            </div>
          )}
          {ctaExternalLink && (
            <div className="w-full mt-sm text-center">
              <Button variant={data?.ctaButton?.variant}>
                <a href={ctaExternalLink} id="hero_cta" target={target}>
                  <span>{data?.ctaButton?.text}</span>
                  <span className="inline-block ml-xxs">
                    <ArrowForward />
                  </span>
                </a>
              </Button>
            </div>
          )}

          {/*    {data?.ctaButton && (
            <div className="w-full mt-sm text-center">
              {data?.ctaButton?.variant === 'Jump-Link' ? (
                <Button variant={data?.ctaButton?.variant}>
                  <a href={link}>
                    <span>{data?.ctaButton?.text}</span>
                    <span className="inline-block ml-xxs">
                      <ArrowDownward />
                    </span>
                  </a>
                </Button>
              ) : (
                <Button variant={data?.ctaButton?.variant}>
                  {link && (
                    <LocalizedLink language={locale} to={link} target={target}>
                      <span>{data?.ctaButton?.text}</span>
                      <span className="inline-block ml-xxs">
                        <ArrowForward />
                      </span>
                    </LocalizedLink>
                  )}
                </Button>
              )}
            </div>
          )} */}
        </div>
      </div>
    </Container>
  );
};
export default TextCta;
