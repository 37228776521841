import React from 'react';
import styled from 'styled-components';
import { ContentfulComponentSpacer } from '@/../graphql-types';

const Container = styled.div``;

interface SpacerProps {
  data?: ContentfulComponentSpacer;
  className?: string;
}
const Spacer: React.FC<SpacerProps> = (props) => {
  const { data } = props;

  const spaceTop = {
    None: 'pt-0',
    Small: 'pt-4',
    Medium: 'pt-10',
    Large: 'pt-16',
  };
  const spaceBottom = {
    None: 'pb-0',
    Small: 'pb-4',
    Medium: 'pb-10',
    Large: 'pb-16',
  };

  let top = spaceTop['Medium'];
  if (data?.topSpace) {
    top = spaceTop[data?.topSpace];
  }
  let bottom = spaceBottom['Medium'];
  if (data?.bottomSpace) {
    bottom = spaceBottom[data?.bottomSpace];
  }

  return (
    <Container className={`spacer ${top} ${bottom}`}>
      {data?.showLine && (
        <div className="container">
          <hr />
        </div>
      )}
    </Container>
  );
};
export default Spacer;
