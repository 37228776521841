import React, { useEffect } from 'react';
import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Language,
} from '@material-ui/icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { LocalizedLink } from 'gatsby-theme-i18n';

import {
  ContentfulComponentSelectDropdown,
  ContentfulTopicLocale,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;
const IconContainer = styled.div`
  height: 30px;
  width: 30px;
`;

interface LanguageSelectorProps {
  currentLocale?: string;
  languageToggle?: ContentfulComponentSelectDropdown;
  location?: Location;
}
const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  const { currentLocale, languageToggle, location } = props;

  const [currentPath, setCurrentPath] = React.useState(``);
  const [selectedLocale, setSelectedLocale] =
    React.useState<ContentfulTopicLocale>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // get and set menuItem associated with currentLocale
    const currentLocaleMenu = languageToggle?.menus?.find((menu) =>
      menu.menuItems?.find(
        (menuItem: ContentfulTopicLocale) =>
          menuItem.shortCode === currentLocale,
      ),
    );
    const currentLocaleMenuItem = currentLocaleMenu?.menuItems?.find(
      (menuItem: ContentfulTopicLocale) => menuItem.shortCode === currentLocale,
    );
    setSelectedLocale(currentLocaleMenuItem);

    let path = location?.pathname.replace(`/${currentLocale}`, ``);
    if (path === '/') {
      // ensures home path doesn't build trailing slash
      path = ' ';
    }
    path = path.endsWith('/') ? path.substring(0, path.length - 1) : path;
    // determine and set the current path without language shortCode
    setCurrentPath(path);
  }, []);

  return (
    <Container
      className={`inline-block relative  ${
        anchorEl ? 'text-black bg-white' : 'text-current'
      }`}
    >
      <button
        type="button"
        aria-describedby={`popper-${languageToggle?.id}`}
        onClick={handleClick}
        className={`flex items-center pt-xxs`}
      >
        <span className="mr-2">
          <IconContainer>
            <Language />
          </IconContainer>
        </span>
        {selectedLocale?.language && (
          <Typography variant="subtitle" className="leading-none">
            {selectedLocale?.language}
          </Typography>
        )}
        <span className="ml-2">
          {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </span>
      </button>

      <Popper
        id={`popper-${languageToggle?.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        style={{ zIndex: 9999, width: '100%' }}
        placement="bottom"
        disablePortal
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className="bg-grey1 shadow-4 pb-edge px-xs text-black">
            {languageToggle?.menus?.map((menu) => {
              return (
                menu.menuItems && (
                  <ul key={`menu-${menu.id}`} className="flex flex-col">
                    {menu.menuItems.map((menuItem: ContentfulTopicLocale) => {
                      if (menuItem.shortCode === currentLocale) {
                        // do not render current locale in language menu
                        return;
                      }
                      return (
                        <li
                          key={`menu-item-${menuItem.id}`}
                          className="mt-edge"
                        >
                          {currentPath && (
                            <LocalizedLink
                              language={menuItem.shortCode}
                              to={currentPath}
                              className="flex justify-center no-underline text-current"
                            >
                              <Typography
                                variant="subtitle"
                                className="leading-none"
                              >
                                {menuItem.language}
                              </Typography>
                            </LocalizedLink>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )
              );
            })}
          </div>
        </ClickAwayListener>
      </Popper>
    </Container>
  );
};
export default LanguageSelector;
