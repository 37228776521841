import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';

import {
  ContentfulComponentPlatformCard,
  ContentfulTopicCaseStudy,
  ContentfulTopicProductFeature,
  ContentfulTopicPlatformFeature,
} from '@/../graphql-types';
import Typography from '../../layout/Typography';
import { getFullLinkByContent } from '@/utils/helpers';
import * as Constants from '@/types/Constants';

const Card = styled.div`
  min-height: 250px;
  max-width: git statu450px;
  position: relative;
  transition: box-shadow 0.1s ease-in;

  &.textDark {
    color: ${(props) => props.theme.color.black} !important;
  }

  &.bgcolorBlue {
    background-image: ${(props) =>
      props.theme.backgroundGradient.blue} !important;
  }
  &.bgcolorGreen {
    background-image: ${(props) =>
      props.theme.backgroundGradient.green} !important;
  }
  &.bgcolorYellow {
    background-image: ${(props) =>
      props.theme.backgroundGradient.yellow} !important;
  }
  &:hover,
  &:focus-visible {
    box-shadow: ${(props) => props.theme.boxShadow[48]} !important;
  }
`;

const CardIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 24px;
  z-index: 0;
  width: 42px;
`;

interface PlatformCardProps {
  data?: ContentfulComponentPlatformCard;
  className?: string;
}
const PlatformCard: React.FC<PlatformCardProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  let page, icon, title, subHeading, product, slug, productSlug;

  // type cards in order to find correct fields
  switch (data?.topic?.internal?.type) {
    case Constants.TOPIC_PRODUCT_FEATURE:
      // eslint-disable-next-line no-case-declarations
      const productFeature = data?.topic as ContentfulTopicProductFeature;

      // eslint-disable-next-line no-case-declarations
      productSlug = productFeature?.product?.page__product?.find(
        (productPage) => productPage.compose__page !== null,
      ).compose__page?.[0]?.slug;

      // handle possibility of null compose__page, set slug for links
      productFeature.page__product_feature?.forEach((element) => {
        if (element?.compose__page) {
          page = `/${productSlug}/${element.compose__page?.[0].slug}`;
        }
      });

      product = productFeature?.product?.title?.replace(
        '®',
        '<sup>&reg;</sup>',
      );
      icon = data?.backgroundImage?.gatsbyImageData;
      title = data?.topic?.title;
      subHeading = data?.topic?.subheading;
      break;
    case Constants.TOPIC_CASE_STUDY:
      // eslint-disable-next-line no-case-declarations
      const caseStudyFeature = data?.topic as ContentfulTopicCaseStudy;

      // handle possibility of null compose__page, set slug for links
      caseStudyFeature?.page__case_study?.forEach((element) => {
        if (element?.compose__page) {
          slug = getFullLinkByContent(
            element?.compose__page?.[0]?.slug,
            Constants.PAGE_CASE_STUDY,
            locale,
          );
        }
      });

      page = slug;
      // TODO: where does the strapline come from on case studies?
      product = 'Case Study';
      icon = data?.backgroundImage?.gatsbyImageData;
      title = data?.topic?.title;
      subHeading = '';
      break;
  }

  // allow overrides
  if (data?.label) {
    product = data?.label;
  }
  if (data?.title) {
    title = data?.title;
  }
  if (data?.link?.url) {
    page = data?.link?.url;
  }

  return (
    <Card
      className={`rounded shadow-30 overflow-hidden text-white bgcolor${
        data?.backgroundColor || 'Blue'
      } text${data?.textColour || 'Light'}`}
    >
      <CardIcon>
        <GatsbyImage image={getImage(icon)} alt="" />
      </CardIcon>
      {page && (
        <LocalizedLink
          language={locale}
          to={page}
          className="h-full block relative z-10 p-edge text-current no-underline"
        >
          <span
            className="uppercase font-light text-xs tracking-wide"
            dangerouslySetInnerHTML={{ __html: product }}
          />
          <Typography
            as="h3"
            variant="h3"
            className="leading-tight font-normal"
          >
            {title}
          </Typography>
          <div className="pr-sm mt-xs">
            <Typography variant="body">{subHeading}</Typography>
          </div>
        </LocalizedLink>
      )}
    </Card>
  );
};
export default PlatformCard;
