import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  variant?: string;
}

// ${props.theme.fontSize.h5}

const Container = styled.div<ContainerProps>`
  /* enforce proper styling when button includes links and icons */

  a,
  button {
    text-decoration: none !important;
    border: 2px solid ${(props) => props.theme.color.primaryMain};
    color: ${(props) => props.theme.color.primaryMain};
    padding: 0.4em 2em 0.5em;
    border-radius: 2em;
    display: inline-block;
    white-space: nowrap;
    transition: all 0.15s;
  }
  a:hover,
  a:focus,
  button:hover,
  button:focus {
    background: ${(props) => props.theme.color.primaryMain};
    color: #fff;
  }
  svg {
    fill: currentColor;
    position: relative;
    top: -1px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    a,
    button {
      white-space: normal;
    }
  }

  ${(props) => {
    switch (props.variant) {
      case 'Outline':
        return ``; // default
      case 'Outline-Light':
        return `
          a {
            border-color: #fff;
            color: #fff;
          }
          a:hover,
          a:focus {
            background: #fff;
            color: ${props.theme.color.primaryMain};
          }
        `;
      case 'Full':
        return `
        a {
          background:${props.theme.color.primaryMain};
          color: #fff;
        }
        a:hover,
        a:focus {
          background: #fff;
          color: ${props.theme.color.primaryMain};
          border-color: ${props.theme.color.primaryMain};
        }
      `;
      case 'Solid':
        return `
          a {
            border-color: ${props.theme.color.primaryMain};
            background: ${props.theme.color.primaryMain};
            color: #fff;
          }
          a:hover,
          a:focus {
            border-color: ${props.theme.color.primaryDark};
            background: ${props.theme.color.primaryDark};
            color: #fff;
          }
        `;
      case 'Solid-green':
        return `
          a {
            border: none ;
            background: ${props.theme.backgroundGradient.green};
            color: #fff;
          }
          a:hover,
          a:focus {
            background: ${props.theme.backgroundGradient.greenHover};
            color: #fff;
          }
        `;
      case 'Nav':
        return `
        a {
          padding:0.2em 1.5em 0.4em;
          position: relative;
          
        }
        `;
      case 'Jump-Link':
        return ``; // TODO if necessary
    }
  }};
`;

interface ButtonProps {
  variant?: string;
  className?: string;
}
const Button: React.FC<ButtonProps> = (props) => {
  const { children, variant, className } = props;

  return (
    <Container variant={variant} className={`buttonWrap ${className}`}>
      {children}
    </Container>
  );
};
export default Button;
