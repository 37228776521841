import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import Typography from '@/components/layout/Typography';
import {
  ContentfulComponentFeaturedBlogPosts,
  ContentfulPageBlog,
  FeaturedBlogPostsQuery,
} from '@/../graphql-types';
import { getFullLinkByContent } from '@/utils/helpers';
import { PAGE_BLOG } from '../../../types/Constants';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled.div`
  padding-left: 65px;
  padding-top: 16px;
  min-width: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

interface FeaturedBlogPostsProps {
  data?: ContentfulComponentFeaturedBlogPosts;
}

const FeaturedBlogPosts: React.FC<FeaturedBlogPostsProps> = (props) => {
  const { data } = props;
  const [displayedFeaturedPosts, setDisplayedFeaturedPosts] = React.useState<
    ContentfulPageBlog[]
  >([]);
  const { locale } = useLocalization();

  const { allContentfulTopicBlog } =
    useStaticQuery<FeaturedBlogPostsQuery>(graphql`
      query FeaturedBlogPosts {
        allContentfulTopicBlog(
          filter: {
            isFeatured: { eq: true }
            page__blog: {
              elemMatch: {
                compose__page: { elemMatch: { disablePage: { eq: false } } }
              }
            }
          }
          sort: { fields: publicationDate, order: DESC }
        ) {
          edges {
            node {
              id
              page__blog {
                id
                contentful_id
                __typename
                blogPost {
                  title
                }
                compose__page {
                  slug
                }
              }
            }
          }
        }
      }
    `);

  React.useEffect(() => {
    // set the max amount of featured posts, with promoted posts at the top
    const promotedBlogPosts = data?.promotedBlogPosts || [];
    const allFeaturedBlogPostPages =
      allContentfulTopicBlog?.edges
        ?.map(({ node }) => node.page__blog) // grab Page: Blog
        ?.flat() // flatten array
        ?.filter(
          (pageBlog, index, array) =>
            array.findIndex(
              (t) => t.contentful_id === pageBlog.contentful_id,
            ) === index,
        ) //remove duplicates since Contentful will return all node_locales
        ?.filter((pageBlog) => pageBlog.compose__page?.[0]?.slug) || // remove any blog posts that have no page yet
      []; // return empty array if undefined

    const maxFeaturedPosts = [
      ...promotedBlogPosts,
      ...allFeaturedBlogPostPages,
    ].slice(0, data?.maxPosts || 6);
    setDisplayedFeaturedPosts(maxFeaturedPosts as ContentfulPageBlog[]);
  }, [data]);

  return (
    <Container>
      <Typography
        as="h3"
        variant="body"
        className="mb-sm uppercase font-medium"
      >
        {data.title}
      </Typography>
      <ul className="list-none p-0 m-0">
        {displayedFeaturedPosts?.map((pageBlog) => {
          const id = pageBlog.id;
          const title = pageBlog.blogPost?.title;
          const slug = pageBlog.compose__page?.[0]?.slug;
          const linkTarget = getFullLinkByContent(slug, PAGE_BLOG, locale);

          return (
            <Typography
              key={id}
              as="li"
              variant="body"
              className="mb-sm pl-xs text-primaryMain"
            >
              {linkTarget && (
                <LocalizedLink to={linkTarget} language={locale}>
                  {title}
                </LocalizedLink>
              )}
            </Typography>
          );
        })}
      </ul>
    </Container>
  );
};
export default FeaturedBlogPosts;
