import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ArrowForward, ChevronRight } from '@material-ui/icons';
import {
  ContentfulComponentResourceCardSet,
  ContentfulTopicBlog,
  ContentfulTopicCaseStudy,
  ContentfulTopicCustomerStory,
  ContentfulTopicWebinar,
  ContentfulTopicVideo,
  ContentfulPageBlog,
  ContentfulPagePressRelease,
} from '@/../graphql-types';
import SocialMediaList from '@/components/SocialMediaList';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import {
  getFullLink,
  getFullLinkByContent,
  getPlainTextFromMicrocopyField,
} from '@/utils/helpers';
import * as Constants from '@/types/Constants';
import { PAGE_BLOG, PAGE_PRESS_RELEASE } from '../../../types/Constants';

const Container = styled.div``;
const ImageContainer = styled.div`
  height: 280px;
  transition: box-shadow 0.1s ease-in;
`;
const IconContainer = styled.span`
  opacity: 0;
  transition: opacity 0.1s ease-in;
`;
const ResourceCardContainer = styled.div`
  &:hover {
    ${ImageContainer} {
      box-shadow: ${(props) => props.theme.boxShadow[48]} !important;
    }
    ${IconContainer} {
      opacity: 1 !important;
    }
  }
`;

const HorizontalCardTag = styled.span`
  background: ${(props) => props.theme.color.primaryMain};
  font-size: ${(props) => props.theme.fontSize.subtitle};
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.3rem 2rem 0.4rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0 0 7px 7px;
  z-index: 10;
`;

const ResourceCardHorizontal = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: ${(props) => props.theme.boxShadow[4]};
  ${ImageContainer} {
    min-height: 375px;
    overflow: hidden;
  }
  &:nth-of-type(even) {
    ${HorizontalCardTag} {
      left: ${(props) => props.theme.spacing.sm};
    }
  }
  &:nth-of-type(odd) {
    flex-direction: row-reverse;
    ${HorizontalCardTag} {
      right: ${(props) => props.theme.spacing.sm};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
    ${ImageContainer} {
      min-height: 180px;
    }
    &:nth-of-type(odd) {
      ${HorizontalCardTag} {
        right: auto;
        left: ${(props) => props.theme.spacing.sm};
      }
    }
  }
`;

interface ResourceCardSetProps {
  data?: ContentfulComponentResourceCardSet;
}
const ResourceCardSet: React.FC<ResourceCardSetProps> = (props) => {
  const { data } = props;
  const { resources } = data;
  const { locale } = useLocalization();
  const [latestBlogPosts, setLatestBlogPosts] = React.useState<
    ContentfulPageBlog[]
  >([]);
  const [latestPressReleases, setLatestPressReleases] = React.useState<
    ContentfulPagePressRelease[]
  >([]);

  const { allContentfulPageBlog, allContentfulPagePressRelease } =
    useStaticQuery<LatestPostsQuery>(graphql`
      query LatestPosts {
        allContentfulPagePressRelease(
          filter: {
            node_locale: { eq: "en-US" }
            compose__page: { elemMatch: { disablePage: { eq: false } } }
          }
          sort: { fields: publishDate, order: DESC }
          limit: 3
        ) {
          edges {
            node {
              id
              internal {
                type
              }
              title
              excerpt {
                excerpt
              }
              compose__page {
                slug
              }
              publishDate(formatString: "MMM DD, YYYY")
            }
          }
        }
        allContentfulPageBlog(
          filter: {
            node_locale: { eq: "en-US" }
            compose__page: { elemMatch: { disablePage: { eq: false } } }
          }
          sort: { fields: blogPost___publicationDate, order: DESC }
          limit: 3
        ) {
          edges {
            node {
              id
              internal {
                type
              }
              blogPost {
                title
                shortDescription {
                  shortDescription
                }
                featureImage {
                  gatsbyImageData
                }
                publicationDate(formatString: "MMM DD, YYYY")
              }
              compose__page {
                slug
              }
            }
          }
        }
      }
    `);

  let layout = 'Vertical';
  if (data?.layout) {
    layout = data?.layout;
  }

  let bottomCopy;
  if (data?.bottomCopy) {
    bottomCopy = getPlainTextFromMicrocopyField(data?.bottomCopy);
  }

  let showBlogPosts = false;
  if (data?.showLatestBlogPosts) {
    showBlogPosts = data?.showLatestBlogPosts;
  }

  let showPressReleases = false;
  if (data?.showLatestPressReleases) {
    showPressReleases = data?.showLatestPressReleases;
  }

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);

    const allLatestBlogPosts = allContentfulPageBlog?.edges
      ?.map(({ node }) => node)
      ?.flat();
    setLatestBlogPosts(allLatestBlogPosts as unknown as ContentfulPageBlog[]);

    const allLatestPressReleases = allContentfulPagePressRelease?.edges
      ?.map(({ node }) => node)
      ?.flat();
    setLatestPressReleases(
      allLatestPressReleases as unknown as ContentfulPagePressRelease[],
    );
  }, [data]);

  let title, subtitle;

  if (data?.headline) {
    title = data?.headline;
  }
  if (data?.subheadline) {
    subtitle = data?.subheadline;
  }

  let hasIntro = false;
  if (title || subtitle) {
    hasIntro = true;
  }

  if (showBlogPosts) {
    if (!hasMounted) {
      return null;
    }
    return (
      <Container>
        <div className="container mt-md">
          {hasIntro && (
            <div className="max-w-5xl mx-auto">
              {title && (
                <Typography
                  as="h2"
                  variant="h2"
                  className="mb-0 block text-center text-primaryMain"
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography as="p" variant="body" className="text-center mt-xs">
                  {subtitle}
                </Typography>
              )}
            </div>
          )}
          <div className="container md:grid grid-cols-12 md:gap-sm xl:gap-md mb-sm">
            {latestBlogPosts?.map((blogPost) => {
              const id = blogPost.id;
              const title = blogPost.blogPost?.title;
              const slug = blogPost.compose__page?.[0]?.slug;
              const linkTarget = getFullLinkByContent(slug, PAGE_BLOG, locale);

              const startDate = blogPost.blogPost?.publicationDate;
              const resourceType = 'Blog | ' + startDate;

              const image = blogPost.blogPost?.featureImage?.gatsbyImageData;
              const alt = blogPost.blogPost?.featureImage?.description;
              const description =
                blogPost.blogPost?.shortDescription?.shortDescription;

              return (
                <ResourceCardContainer
                  key={`resource-item-${id}`}
                  className="col-span-12 lg:col-span-4 mt-sm first:mt-0 lg:mt-0"
                >
                  <LocalizedLink
                    language={locale}
                    to={linkTarget}
                    className="flex flex-col no-underline text-current"
                  >
                    <ImageContainer className="rounded shadow-50 overflow-hidden">
                      {image && (
                        <GatsbyImage
                          image={getImage(image)}
                          alt={alt || ``}
                          className="h-full w-full"
                        />
                      )}
                    </ImageContainer>
                    <div className="mt-xs">
                      <Typography variant="caption" className="uppercase">
                        {resourceType}
                      </Typography>
                    </div>
                    {title && (
                      <div className="mt-xs">
                        <Typography variant="h5" className="text-primaryMain">
                          <span>{title}</span>
                          <IconContainer>
                            <ChevronRight />
                          </IconContainer>
                        </Typography>
                      </div>
                    )}
                    {description && (
                      <div className="mt-xs">
                        <Typography variant="body">{description}</Typography>
                      </div>
                    )}
                  </LocalizedLink>
                </ResourceCardContainer>
              );
            })}
          </div>
        </div>
        {data?.ctaButton && (
          <div className="flex justify-center mt-sm md:mt-md mb-sm">
            <Button variant={data?.ctaButton?.variant}>
              {data?.ctaButton?.link?.page && (
                <LocalizedLink
                  language={locale}
                  to={getFullLink(data?.ctaButton?.link?.page, locale)}
                >
                  <span>{data?.ctaButton?.text}</span>
                  <ArrowForward className="ml-1" />
                </LocalizedLink>
              )}
            </Button>
          </div>
        )}
      </Container>
    );
  } else if (showPressReleases) {
    if (!hasMounted) {
      return null;
    }
    return (
      <Container>
        <div className="container mt-md">
          {hasIntro && (
            <div className="max-w-5xl mx-auto">
              {title && (
                <Typography
                  as="h2"
                  variant="h2"
                  className="mb-0 block text-center text-primaryMain"
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography as="p" variant="body" className="text-center mt-xs">
                  {subtitle}
                </Typography>
              )}
            </div>
          )}
          <div className="container md:grid grid-cols-12 md:gap-xs xl:gap-md my-sm">
            {latestPressReleases?.map((pressRelease) => {
              const id = pressRelease.id;
              const title = pressRelease.title;
              const slug = pressRelease.compose__page?.[0]?.slug;
              const linkTarget = getFullLinkByContent(
                slug,
                PAGE_PRESS_RELEASE,
                locale,
              );

              const description = pressRelease.excerpt?.excerpt;
              const resourceType = pressRelease.publishDate;

              return (
                <ResourceCardContainer
                  key={`resource-item-${id}`}
                  className="col-span-12 lg:col-span-4 first:mt-0 lg:mt-0 bg-white rounded shadow-30 hover:shadow-50 p-4"
                >
                  <LocalizedLink
                    language={locale}
                    to={linkTarget}
                    className="flex flex-col no-underline text-current"
                  >
                    <div className="">
                      <Typography variant="caption" className="uppercase">
                        {resourceType}
                      </Typography>
                    </div>
                    {title && (
                      <div className="mt-xs">
                        <Typography variant="h5" className="text-primaryMain">
                          <span>{title}</span>
                        </Typography>
                      </div>
                    )}
                    {description && (
                      <div className="mt-xs">
                        <Typography variant="body">
                          {description}&nbsp;
                          <span className="text-primaryMain inline-block">
                            Read more
                            <IconContainer>
                              <ChevronRight />
                            </IconContainer>
                          </span>
                        </Typography>
                      </div>
                    )}
                  </LocalizedLink>
                </ResourceCardContainer>
              );
            })}
          </div>
        </div>

        {data?.ctaButton && (
          <div className="flex justify-center mt-sm md:mt-md mb-sm">
            <Button variant={data?.ctaButton?.variant}>
              {data?.ctaButton?.link?.page && (
                <LocalizedLink
                  language={locale}
                  to={getFullLink(data?.ctaButton?.link?.page, locale)}
                >
                  <span>{data?.ctaButton?.text}</span>
                  <ArrowForward className="ml-1" />
                </LocalizedLink>
              )}
            </Button>
          </div>
        )}
        {bottomCopy && (
          <div className="mt-sm mb-xs text-center">{bottomCopy}</div>
        )}
        {data?.showSocialLinks && (
          <SocialMediaList
            color="dark"
            className="mb-sm mx-auto"
            align="justify-center"
          />
        )}
      </Container>
    );
  } else {
    if (layout === 'Horizontal') {
      return (
        <Container>
          <div className="container max-w-5xl mx-auto">
            {hasIntro && (
              <div className="container mb-sm">
                {title && (
                  <Typography
                    as="h2"
                    variant="h2"
                    className="mb-0 block text-center text-primaryMain"
                  >
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    as="p"
                    variant="body"
                    className="text-center mt-xs"
                  >
                    {subtitle}
                  </Typography>
                )}
              </div>
            )}
            {resources?.map((resource) => {
              let topic, page, image, alt, title, description, cardClasses;
              let ctaText = 'Learn more';

              // type resource in order to find correct fields
              switch (resource.internal?.type) {
                case Constants.TOPIC_BLOG:
                  // eslint-disable-next-line no-case-declarations
                  const blogPost = resource as ContentfulTopicBlog;
                  topic = 'Blog';
                  ctaText = 'Read the blog post';

                  page = getFullLinkByContent(
                    blogPost.page__blog?.[0]?.compose__page?.[0]?.slug,
                    Constants.TOPIC_BLOG,
                    locale,
                  );

                  image = blogPost.featureImage?.gatsbyImageData;
                  alt = blogPost.featureImage?.description;
                  title = blogPost.title;
                  description = blogPost.shortDescription?.shortDescription;
                  break;
                case Constants.TOPIC_CASE_STUDY:
                  // eslint-disable-next-line no-case-declarations
                  const caseStudy = resource as ContentfulTopicCaseStudy;
                  topic = 'Case Study';
                  ctaText = 'Read the case study';

                  page = getFullLinkByContent(
                    caseStudy.page__case_study?.[0]?.compose__page?.[0]?.slug,
                    Constants.PAGE_CASE_STUDY,
                    locale,
                  );

                  image = caseStudy.featureImage?.gatsbyImageData;
                  alt = caseStudy.featureImage?.description;
                  title = caseStudy.title;
                  description = caseStudy.shortDescription?.shortDescription;
                  break;
                case Constants.TOPIC_CUSTOMER_STORY:
                  // eslint-disable-next-line no-case-declarations
                  const customerStory =
                    resource as ContentfulTopicCustomerStory;
                  topic = 'Customer Story';
                  ctaText = 'Read the customer story';

                  page = getFullLinkByContent(
                    customerStory.page__customer_story?.[0]?.compose__page?.[0]
                      ?.slug,
                    Constants.PAGE_CUSTOMER_STORY,
                    locale,
                  );

                  image = customerStory.featureImage?.gatsbyImageData;
                  alt = customerStory.featureImage?.description;
                  title = customerStory.title;
                  description =
                    customerStory.shortDescription?.shortDescription;
                  break;
                case Constants.TOPIC_WEBINAR:
                  // eslint-disable-next-line no-case-declarations
                  const webinar = resource as ContentfulTopicWebinar;
                  topic = 'Webinar';
                  ctaText = 'Watch the webinar';

                  page = getFullLinkByContent(
                    webinar.page__webinar?.[0]?.compose__page?.[0]?.slug,
                    Constants.PAGE_WEBINAR,
                    locale,
                  );

                  image = webinar.featureImage?.gatsbyImageData;
                  alt = webinar.featureImage?.description;
                  title = webinar.title;
                  description = webinar.shortDescription?.shortDescription;
                  break;
                case Constants.TOPIC_VIDEO:
                  // eslint-disable-next-line no-case-declarations
                  const video = resource as ContentfulTopicVideo;
                  topic = 'Video';
                  ctaText = 'Watch the video';

                  page = getFullLinkByContent(
                    video.page__video?.[0]?.compose__page?.[0]?.slug,
                    Constants.PAGE_VIDEO,
                    locale,
                  );

                  image = video.featureImage?.gatsbyImageData;
                  alt = video.featureImage?.description;
                  title = video.title;
                  description = video.shortDescription?.shortDescription;
                  break;
                case Constants.PAGE_PRESS_RELEASE:
                  // eslint-disable-next-line no-case-declarations
                  const pressRelease = resource as ContentfulPagePressRelease;
                  // topic = 'Press Release';
                  topic = null;

                  page = getFullLinkByContent(
                    pressRelease?.compose__page?.[0]?.slug,
                    Constants.PAGE_PRESS_RELEASE,
                    locale,
                  );
                  image = null;
                  alt = null;
                  title = pressRelease.title;
                  description = pressRelease.publishDate;
                  cardClasses =
                    'bg-white transition p-4 rounded shadow-30 hover:shadow-50';
                  break;
              }

              if (!page) return null;
              return (
                <ResourceCardHorizontal
                  key={`resource-item-${resource.id}`}
                  className="rounded mb-sm overflow-hidden"
                >
                  <div className="md:w-1/3 lg:w-1/2 relative">
                    {topic && <HorizontalCardTag>{topic}</HorizontalCardTag>}
                    {image && (
                      <ImageContainer>
                        <GatsbyImage
                          image={getImage(image)}
                          alt={alt || ``}
                          className="h-full w-full"
                        />
                      </ImageContainer>
                    )}
                  </div>
                  <div className="md:w-2/3 lg:w-1/2 p-xs md:p-sm">
                    {title && (
                      <Typography
                        variant="h5"
                        className="text-primaryMain block mb-4"
                      >
                        {title}
                      </Typography>
                    )}
                    {description && (
                      <div>
                        <Typography variant="body">{description}</Typography>
                        <LocalizedLink
                          language={locale}
                          to={page}
                          className="block mt-xs font-bold text-primaryMain"
                        >
                          <span>{ctaText}</span>
                          <ArrowForward className="ml-1" />
                        </LocalizedLink>
                      </div>
                    )}
                  </div>
                </ResourceCardHorizontal>
              );
            })}
          </div>
        </Container>
      );
    } else {
      return (
        <Container>
          <div className="container max-w-5xl mx-auto">
            {hasIntro && (
              <div className="container">
                {title && (
                  <Typography
                    as="h2"
                    variant="h2"
                    className="mb-0 block text-center text-primaryMain"
                  >
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    as="p"
                    variant="body"
                    className="text-center mt-xs"
                  >
                    {subtitle}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <div className="container py-sm">
            <div className="md:grid grid-cols-12 md:gap-sm xl:gap-md">
              {resources?.map((resource) => {
                let topic, page, image, alt, title, description, cardClasses;

                // type resource in order to find correct fields
                switch (resource.internal?.type) {
                  case Constants.TOPIC_BLOG:
                    // eslint-disable-next-line no-case-declarations
                    const blogPost = resource as ContentfulTopicBlog;
                    topic = 'Blog';

                    page = getFullLinkByContent(
                      blogPost.page__blog?.[0]?.compose__page?.[0]?.slug,
                      Constants.TOPIC_BLOG,
                      locale,
                    );

                    image = blogPost.featureImage?.gatsbyImageData;
                    alt = blogPost.featureImage?.description;
                    title = blogPost.title;
                    description = blogPost.shortDescription?.shortDescription;
                    break;
                  case Constants.TOPIC_CASE_STUDY:
                    // eslint-disable-next-line no-case-declarations
                    const caseStudy = resource as ContentfulTopicCaseStudy;
                    topic = 'Case Study';

                    page = getFullLinkByContent(
                      caseStudy.page__case_study?.[0]?.compose__page?.[0]?.slug,
                      Constants.PAGE_CASE_STUDY,
                      locale,
                    );

                    image = caseStudy.featureImage?.gatsbyImageData;
                    alt = caseStudy.featureImage?.description;
                    title = caseStudy.title;
                    description = caseStudy.shortDescription?.shortDescription;
                    break;
                  case Constants.TOPIC_CUSTOMER_STORY:
                    // eslint-disable-next-line no-case-declarations
                    const customerStory =
                      resource as ContentfulTopicCustomerStory;
                    topic = 'Customer Story';

                    page = getFullLinkByContent(
                      customerStory.page__customer_story?.[0]
                        ?.compose__page?.[0]?.slug,
                      Constants.PAGE_CUSTOMER_STORY,
                      locale,
                    );

                    image = customerStory.featureImage?.gatsbyImageData;
                    alt = customerStory.featureImage?.description;
                    title = customerStory.title;
                    description =
                      customerStory.shortDescription?.shortDescription;
                    break;
                  case Constants.TOPIC_WEBINAR:
                    // eslint-disable-next-line no-case-declarations
                    const webinar = resource as ContentfulTopicWebinar;
                    topic = 'Webinar';

                    page = getFullLinkByContent(
                      webinar.page__webinar?.[0]?.compose__page?.[0]?.slug,
                      Constants.PAGE_WEBINAR,
                      locale,
                    );

                    image = webinar.featureImage?.gatsbyImageData;
                    alt = webinar.featureImage?.description;
                    title = webinar.title;
                    description = webinar.shortDescription?.shortDescription;
                    break;
                  case Constants.TOPIC_VIDEO:
                    // eslint-disable-next-line no-case-declarations
                    const video = resource as ContentfulTopicVideo;
                    topic = 'Video';

                    page = getFullLinkByContent(
                      video.page__video?.[0]?.compose__page?.[0]?.slug,
                      Constants.PAGE_VIDEO,
                      locale,
                    );

                    image = video.featureImage?.gatsbyImageData;
                    alt = video.featureImage?.description;
                    title = video.title;
                    description = video.shortDescription?.shortDescription;
                    break;
                  case Constants.PAGE_PRESS_RELEASE:
                    // eslint-disable-next-line no-case-declarations
                    const pressRelease = resource as ContentfulPagePressRelease;
                    topic = 'Press Release | ' + pressRelease.publishDate;
                    // topic = null;

                    page = getFullLinkByContent(
                      pressRelease?.compose__page?.[0]?.slug,
                      Constants.PAGE_PRESS_RELEASE,
                      locale,
                    );
                    // image = null;
                    // alt = null;
                    image = pressRelease.featureImage?.gatsbyImageData;
                    alt = pressRelease.featureImage?.description;

                    title = pressRelease.title;
                    description = pressRelease.excerpt?.excerpt;
                    // description = pressRelease.publishDate;
                    // cardClasses =
                    // 'bg-white transition p-4 rounded shadow-30 hover:shadow-50';
                    break;
                }

                if (!page) return null;
                return (
                  <ResourceCardContainer
                    key={`resource-item-${resource.id}`}
                    className={`col-span-12 md:col-span-6 lg:col-span-4 mt-md first:mt-0 md:mt-0 ${cardClasses}`}
                  >
                    <LocalizedLink
                      language={locale}
                      to={page}
                      className="flex flex-col no-underline text-current"
                    >
                      {image && (
                        <ImageContainer className="rounded shadow-50 overflow-hidden mb-xs">
                          <GatsbyImage
                            image={getImage(image)}
                            alt={alt || ``}
                            className="h-full w-full"
                          />
                        </ImageContainer>
                      )}
                      {topic && (
                        <div className="mb-xs">
                          <Typography variant="caption" className="uppercase">
                            {topic}
                          </Typography>
                        </div>
                      )}
                      {title && (
                        <div className="mb-xs">
                          <Typography variant="h5" className="text-primaryMain">
                            <span>{title}</span>
                            <IconContainer>
                              <ChevronRight />
                            </IconContainer>
                          </Typography>
                        </div>
                      )}
                      {description && (
                        <div>
                          <Typography variant="body">{description}</Typography>
                        </div>
                      )}
                    </LocalizedLink>
                  </ResourceCardContainer>
                );
              })}
            </div>
            {data?.ctaButton && (
              <div className="flex justify-center mt-md md:mt-lg">
                <Button variant={data?.ctaButton?.variant}>
                  <LocalizedLink
                    language={locale}
                    to={getFullLink(data?.ctaButton?.link?.page, locale)}
                  >
                    <span>{data?.ctaButton?.text}</span>
                    <ArrowForward className="ml-1" />
                  </LocalizedLink>
                </Button>
              </div>
            )}
            {bottomCopy && (
              <div className="mt-sm text-center">{bottomCopy}</div>
            )}
            {data?.showSocialLinks && (
              <div className="my-sm">
                <SocialMediaList color="dark" align="justify-center" />
              </div>
            )}
          </div>
        </Container>
      );
    }
  }
};
export default ResourceCardSet;
