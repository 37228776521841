import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward } from '@material-ui/icons';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { ContentfulComponentSolutionsCardCarousel } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { getFullLinkByContent } from '@/utils/helpers';
import * as Constants from '@/types/Constants';

import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper';

const Container = styled.div<{ textColour?: string }>`
  ${(props) => {
    switch (props.textColour) {
      case 'Light':
        return `color: ${props.theme.color.white};`;
      case 'Dark':
      default:
        return `color: ${props.theme.color.black};`;
    }
  }}
  .swiper-pagination {
  }
  .swiper-pagination-bullet {
    background: #fff;
    width: 15px;
    height: 15px;
    opacity: 0.4;
    transition: all 0.2s;
  }
  .swiper-pagination-bullet:hover {
    opacity: 0.9;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${(props) => props.theme.color.primaryMain};
  }
  .swiper-slide {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
`;
const HeadingContainer = styled.div`
  max-width: 650px;
`;

const SlideBody = styled.a``;

const SlideText = styled.div<{ textColour: string }>`
  color: ${(props) =>
    props.textColour === 'Light'
      ? props.theme.color.white
      : props.theme.color.black};
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
`;

const ArrowContainer = styled.div`
  color: ${(props) => props.theme.color.primaryMain};
  background-color: ${(props) => props.theme.color.white};
  border-radius: 50%;
  // font-size: ${(props) => props.theme.fontSize.h3};
  height: ${(props) => props.theme.fontSize.h3};
  width: ${(props) => props.theme.fontSize.h3};
`;

const CardContainer = styled.div``;

const CardWrapper = styled.div``;

const Card = styled.a``;

const CardText = styled.div<{ textColour: string }>`
  color: ${(props) =>
    props.textColour === 'Light'
      ? props.theme.color.white
      : props.theme.color.black};
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
`;
interface SolutionsCardCarouselProps {
  data?: ContentfulComponentSolutionsCardCarousel;
  className?: string;
}
const SolutionsCardCarousel: React.FC<SolutionsCardCarouselProps> = (props) => {
  const { data, className } = props;
  const { locale } = useLocalization();

  const btnVariant =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton?.variant;
  const textcolorHeadline = data?.blueHeader ? 'text-primaryMain' : '';

  if (data?.isCarousel) {
    return (
      <Container
        textColour={data?.textColour}
        className={`${className || ``} mt-xl`}
      >
        <div className="container">
          <HeadingContainer className="mx-auto text-center mb-sm">
            {data?.headline && (
              <Typography
                as="h2"
                variant="h2"
                className={`!${textcolorHeadline}`}
              >
                {data?.headline}
              </Typography>
            )}
            {data?.subheading && (
              <Typography as="p" variant="body" className="mt-xs">
                {data?.subheading}
              </Typography>
            )}
          </HeadingContainer>
        </div>
        <Swiper
          slidesPerView={1}
          initialSlide={0}
          centeredSlides={true}
          spaceBetween={20}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1.3,
              spaceBetween: 20,
              initialSlide: 0,
            },
            640: {
              slidesPerView: 1.75,
              spaceBetween: 20,
              initialSlide: 0,
            },
            768: {
              slidesPerView: 2.25,
              spaceBetween: 30,
              initialSlide: 0,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
              initialSlide: 1,
            },
            1280: {
              slidesPerView: 3.5,
              spaceBetween: 30,
              initialSlide: 1,
            },
            1536: {
              slidesPerView: 4.25,
              spaceBetween: 30,
              initialSlide: 1,
            },
          }}
          modules={[Pagination]}
          className="mio-carousel"
        >
          {data?.cards?.map((card) => {
            const link =
              card.topic?.page__solution?.[0]?.compose__page?.[0]?.slug &&
              !card?.hideArrow
                ? getFullLinkByContent(
                    card.topic?.page__solution?.[0]?.compose__page?.[0]?.slug,
                    Constants.PAGE_SOLUTION,
                    locale,
                  )
                : '/';
            return (
              <SwiperSlide
                key={`carousel-${card?.topic?.title}`}
                className="px-4 sm:px-0 rounded overflow-hidden mb-md"
              >
                <SlideBody
                  href={link}
                  className="block relative rounded overflow-hidden"
                >
                  <GatsbyImage
                    image={getImage(card?.backgroundImage?.gatsbyImageData)}
                    alt=""
                    style={{ height: '100%' }}
                    objectFit="cover"
                  />
                  <SlideText
                    className="p-6 h-full flex flex-col"
                    textColour={card.textColour}
                  >
                    <Typography as="h3" variant="h4">
                      {card?.topic?.title}
                    </Typography>
                    <Typography
                      as="p"
                      variant="body"
                      className="mt-xxs max-w-[325px]"
                    >
                      {card?.topic?.shortDescription?.shortDescription}
                    </Typography>
                    {!card?.hideArrow && (
                      <ArrowContainer className="flex items-center justify-center mt-auto">
                        <ArrowForward />
                      </ArrowContainer>
                    )}
                  </SlideText>
                </SlideBody>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {data?.ctaButton && (
          <div className="flex justify-center md:justify-center">
            <Button variant={btnVariant} className="mt-xs">
              {data?.ctaButton?.link && (
                <LocalizedLink
                  language={locale}
                  to={
                    data?.ctaButton.link?.url ||
                    getFullLink(data?.ctaButton.link?.page, locale)
                  }
                  className={``}
                >
                  {data?.ctaButton.text}
                  <ArrowForward className="ml-xxs" />
                </LocalizedLink>
              )}
            </Button>
          </div>
        )}
      </Container>
    );
  } else {
    return (
      <Container
        textColour={data?.textColour}
        className={`${className || ``} mt-xl`}
      >
        <div className="container">
          <HeadingContainer className="mx-auto text-center mb-sm">
            {data?.headline && (
              <Typography
                as="h2"
                variant="h2"
                className={`${textcolorHeadline}`}
              >
                {data?.headline}
              </Typography>
            )}
            {data?.subheading && (
              <Typography as="p" variant="body" className="mt-xs">
                {data?.subheading}
              </Typography>
            )}
            {data?.shortDescription?.shortDescription && (
              <Typography as="p" variant="body" className="mt-xs">
                {data?.shortDescription?.shortDescription}
              </Typography>
            )}
          </HeadingContainer>
        </div>
        <CardContainer
          className={`container md:flex flex-wrap justify-center ${
            data?.cards?.length > 3 ? 'gap-[25px]' : ''
          }`}
        >
          {data?.cards?.map((card) => {
            const link =
              card.topic?.page__solution?.[0]?.compose__page?.[0]?.slug &&
              !card?.hideArrow
                ? getFullLinkByContent(
                    card.topic?.page__solution?.[0]?.compose__page?.[0]?.slug,
                    Constants.PAGE_SOLUTION,
                    locale,
                  )
                : null;
            return (
              <CardWrapper
                key={`carousel-${card?.topic?.title}`}
                className={`px-0 mb-12 md:mb-8  lg:mb-12 xxl:mb-24 w-full  ${
                  data?.cards?.length > 3
                    ? 'max-w-[325px]'
                    : 'md:px-4 xxl:px-12 lg:px-6  md:w-1/2 xl:w-1/3'
                }`}
              >
                {link ? (
                  <Card
                    className="block relative rounded overflow-hidden"
                    href={link}
                  >
                    <GatsbyImage
                      image={getImage(card?.backgroundImage?.gatsbyImageData)}
                      alt=""
                      style={{ height: '100%' }}
                      objectFit="cover"
                    />
                    <CardText
                      className="p-6 h-full flex flex-col"
                      textColour={card.textColour}
                    >
                      <Typography as="h3" variant="h4">
                        {card?.topic?.title}
                      </Typography>
                      <Typography
                        as="p"
                        variant="body"
                        className="mt-xxs max-w-[325px]"
                      >
                        {card?.topic?.shortDescription?.shortDescription}
                      </Typography>
                      {!card?.hideArrow && (
                        <ArrowContainer className="flex items-center justify-center mt-auto">
                          <ArrowForward />
                        </ArrowContainer>
                      )}
                    </CardText>
                  </Card>
                ) : (
                  <div className="block relative rounded overflow-hidden">
                    <GatsbyImage
                      image={getImage(card?.backgroundImage?.gatsbyImageData)}
                      alt=""
                      style={{ height: '100%' }}
                      objectFit="cover"
                    />
                    <CardText
                      className="p-6 h-full flex flex-col"
                      textColour={card.textColour}
                    >
                      <Typography as="h3" variant="h4">
                        {card?.topic?.title}
                      </Typography>
                      <Typography
                        as="p"
                        variant="body"
                        className="mt-xxs max-w-[325px]"
                      >
                        {card?.topic?.shortDescription?.shortDescription}
                      </Typography>
                      {!card?.hideArrow && (
                        <ArrowContainer className="flex items-center justify-center mt-auto">
                          <ArrowForward />
                        </ArrowContainer>
                      )}
                    </CardText>
                  </div>
                )}
              </CardWrapper>
            );
          })}
        </CardContainer>
        {data?.ctaButton && (
          <div className="flex justify-center md:justify-center">
            <Button variant={btnVariant} className="mt-xs">
              {data?.ctaButton?.link && (
                <LocalizedLink
                  language={locale}
                  to={
                    data?.ctaButton.link?.url ||
                    getFullLink(data?.ctaButton.link?.page, locale)
                  }
                  className={``}
                >
                  {data?.ctaButton.text}
                  <ArrowForward className="ml-xxs" />
                </LocalizedLink>
              )}
            </Button>
          </div>
        )}
      </Container>
    );
  }
};

export default SolutionsCardCarousel;
