import React, { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled, { ThemeContext } from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import { ContentfulComponentHeroBanner } from '@/../graphql-types';
import Button from '@/components/layout/Button';
import Typography from '@/components/layout/Typography';
import { getFullLink } from '@/utils/helpers';

import {
  BasicHero,
  Version2Hero,
  Vesion2Gradient,
  Empty,
} from '@/components/heroes';

/** This styled-component ensures that the mobile background image size is
 * responsive until 640px, at which point we must fix the height
 * due to fixed max-width container.
 */
const Container = styled.header<{
  mobileAspectRatio: number;
  desktopAspectRatio: number;
  maxHeight: number;
}>`
  height: calc(100vw * ${(props) => props.mobileAspectRatio});
  max-width: ${(props) => props.theme.container.extra};
  transition: opacity 0.2s;
  @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
    /* background heros will be always this proportion */
    height: calc(100vw * ${(props) => props.desktopAspectRatio});
    /* stops being responsive at this breakpoint due to fixed width container */
    max-height: ${(props) => props.maxHeight}px;
  }
  &.mioIS_heroLoad {
    opacity: 0;
  }
`;

// const BasicContainer = styled.header``;

/** This styled-component ensures the content area is always within
 * the hero banner background image's box, so that internal content
 * can position itself correctly
 */
const HeroBannerContentArea = styled.div`
  height: 100%;
  padding: 15% 0 30%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding: 0 0 20%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0;
    padding-left: 3%;
    height: 60%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding-left: 5%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.xl}) {
    padding-left: 7%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.xxl}) {
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.mobileH1};
  font-weight: ${(props) => props.theme.fontWeight.book};
  letter-spacing: 0px;
  line-height: 40px;

  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    font-size: ${(props) => props.theme.fontSize.h2};
    line-height: 1.235;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.xl}) {
    font-size: ${(props) => props.theme.fontSize.h1};
  }
`;
const Subheading = styled.p`
  @media (max-width: calc(${(props) => props.theme.breakpoint.xs} - 1px)) {
    font-size: ${(props) => props.theme.fontSize.body};
  }
`;
const LinesWrapper = styled.div`
  position: absolute;
  left: 1em;
  top: 0;
`;

interface HeroBannerProps {
  data?: ContentfulComponentHeroBanner;
  className?: string;
}
const HeroBanner: React.FC<HeroBannerProps> = (props) => {
  const { data, className } = props;
  const { locale } = useLocalization();
  const themeContext = React.useContext(ThemeContext);

  const [isClient, setClient] = useState(false);
  const [showHero, setShowHero] = useState(false);
  const key = isClient ? 'client' : 'server';

  // FOR INTERATION STUDIO CONTROLLED HEROES
  let hideHeroForIS;
  if (data?.hideOnLoad) {
    hideHeroForIS = 'mioIS_heroLoad';
  }

  useEffect(() => {
    setTimeout(() => {
      setShowHero(true);
    }, 1000);
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-4">{children}</p>
      ),
      [BLOCKS.HR]: (node, children) => <hr className="my-8" />,
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, '')
        .split('\n')
        .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
  };

  const fontWeights = {
    Light: 'font-light',
    Normal: '',
    Bold: 'font-bold',
  };

  let titleWeight = fontWeights['Normal'];
  if (data?.titleFontWeight) {
    titleWeight = fontWeights[data?.titleFontWeight];
  }

  let h1Colour = 'text-primaryMain';
  let textColour = null;
  if (data.textColour === 'Light') {
    h1Colour = 'text-white';
    textColour = 'text-white';
  }

  // determine how to size responsive background image
  const mobileAspectRatio =
    data?.mobileImage?.file?.details?.image?.height /
    data?.mobileImage?.file?.details?.image?.width;
  const desktopAspectRatio =
    data?.featureImage?.file?.details?.image?.height /
    data?.featureImage?.file?.details?.image?.width;
  const maxHeight =
    parseInt(themeContext.breakpoint.extra) * desktopAspectRatio;

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  let ctaText2, ctaLocalLink2, ctaExternalLink2, target2;
  if (data?.ctaButton2) {
    if (data?.ctaButton2.link?.page?.slug) {
      ctaLocalLink2 = getFullLink(data?.ctaButton2?.link?.page, locale);
    } else if (data?.ctaButton2?.link?.url) {
      ctaExternalLink2 = data?.ctaButton2?.link?.url;
    }

    if (data?.ctaButton2?.text) {
      ctaText2 = data?.ctaButton2?.text;
    } else if (data?.ctaButton2?.link?.text) {
      ctaText2 = data?.ctaButton2?.link?.text;
    }

    if (data?.ctaButton2?.link?.openInNewWindow) {
      target2 = '_blank';
    }
  }

  let title;
  if (data?.title) {
    title = data?.title
      .replace('®', '<sup style="font-size:0.5em; top:-0.8em;">&reg;</sup>')
      .replace('™', '<sup style="top:-0.5em">&trade;</sup>');
  }

  let descriptionDisplay;
  const displayClasses = {
    Mobile: 'block sm:hidden',
    Tablet: 'hidden sm:block lg:hidden',
    Desktop: 'hidden lg:block',
    'Mobile Desktop': 'block sm:hidden lg:block',
    'Mobile Tablet': 'block lg:hidden',
    'Tablet Desktop': 'hidden sm:block',
  };

  if (data?.showDescription) {
    descriptionDisplay = displayClasses[data?.showDescription];
  }

  if (data?.variant === 'Empty IS') {
    return <div id="is_hero_banner_insert"></div>;
  } else if (data?.variant === 'Information Studio') {
    return <Empty key={key} data={data} id="IS_hero_wrapper" />;
  } else if (data?.variant === 'Basic Page Title') {
    return <BasicHero key={key} title={data?.title} />;
  } else if (data?.variant === 'Version2') {
    return <Version2Hero key={key} data={data} />;
  } else if (data?.variant === 'Version2 - gradient alone') {
    return <Vesion2Gradient key={key} data={data} />;
  } else {
    return (
      <>
        <div id="is_hero_banner_insert"></div>
        <Container
          key={key}
          id="hero_wrapper"
          mobileAspectRatio={mobileAspectRatio}
          desktopAspectRatio={desktopAspectRatio}
          maxHeight={maxHeight}
          className={`relative mx-auto ${className || ``} ${
            showHero ? '' : hideHeroForIS
          }`}
        >
          <div className="absolute z-0 w-full h-full top-0 left-0">
            <>
              <GatsbyImage
                image={getImage(data?.featureImage?.gatsbyImageData)}
                alt=""
                className="hidden sm:block h-full"
              />
              <GatsbyImage
                image={getImage(data?.mobileImage?.gatsbyImageData)}
                alt=""
                className="sm:hidden w-full h-full"
              />
            </>
          </div>
          <HeroBannerContentArea
            className={`${data.variant ? data.variant.toLowerCase() : ''}`}
          >
            <div className="h-full relative z-10 grid-cols-12 gap-x-sm sm:grid">
              <div className="h-full flex flex-col col-span-7 lg:col-span-6 items-center text-center sm:text-left px-xs xxs:px-sm sm:px-0 sm:pl-sm md:px-8 sm:items-start sm:justify-center xl:pl-sm">
                {title && (
                  <Title
                    id="hero_title"
                    variant="h1"
                    className={` ${h1Colour} ${titleWeight}`}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></Title>
                )}
                {data?.subheading && (
                  <Subheading
                    id="hero_description"
                    variant="body"
                    className={`${textColour} mt-xxs md:mt-xs sm:text-sm md:text-lg md:pr-8	`}
                  >
                    {data?.subheading}
                  </Subheading>
                )}
                {data?.longDescription?.raw && (
                  <div
                    id="hero_longDescription"
                    className={`mt-8 ${textColour} ${descriptionDisplay} max-w-[575px]`}
                  >
                    {renderRichText(
                      data?.longDescription as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                      options,
                    )}
                  </div>
                )}
                <div className="flex gap-4">
                  {ctaLocalLink && (
                    <div className="mt-sm sm:mt-sm mb-xxs xxs:mb-xs sm:mb-0">
                      <Button variant={data?.ctaButton?.variant}>
                        <a href={ctaLocalLink} id="hero_cta" target={target}>
                          <div className="flex gap-2 items-center">
                            {data?.ctaButton?.text}{' '}
                            {data?.arrow && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="12"
                                viewBox="0 0 15 12"
                                fill="none"
                              >
                                <path
                                  d="M14.3937 6.53282L14.925 6.00157L14.3937 5.47032L9.59374 0.670319L9.06249 0.139069L8.00312 1.19844L8.53437 1.72969L12.0531 5.25157H1.71249H0.962494V6.75157H1.71249H12.05L8.53124 10.2703L7.99999 10.8016L9.05937 11.8609L9.59062 11.3297L14.3937 6.53282Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                          </div>
                        </a>
                      </Button>
                      {/* <Button variant={data?.ctaButton?.variant}>
                      {ctaLocalLink && (
                        <LocalizedLink
                          id="hero_cta"
                          language={locale}
                          to={ctaLocalLink}
                          target={target}
                        >
                          <span>{data?.ctaButton?.text}</span>
                        </LocalizedLink>
                      )}
                    </Button> */}
                    </div>
                  )}
                  {ctaExternalLink && (
                    <div className="mt-sm sm:mt-sm mb-xxs xxs:mb-xs sm:mb-0 ">
                      <Button variant={data?.ctaButton?.variant}>
                        <a href={ctaExternalLink} id="hero_cta" target={target}>
                          <div className="flex gap-2 items-center">
                            {data?.ctaButton?.text}{' '}
                            {data?.arrow && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="12"
                                viewBox="0 0 15 12"
                                fill="none"
                              >
                                <path
                                  d="M14.3937 6.53282L14.925 6.00157L14.3937 5.47032L9.59374 0.670319L9.06249 0.139069L8.00312 1.19844L8.53437 1.72969L12.0531 5.25157H1.71249H0.962494V6.75157H1.71249H12.05L8.53124 10.2703L7.99999 10.8016L9.05937 11.8609L9.59062 11.3297L14.3937 6.53282Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                          </div>
                        </a>
                      </Button>
                    </div>
                  )}
                  {ctaLocalLink2 && (
                    <div className="mt-sm sm:mt-sm mb-xxs xxs:mb-xs sm:mb-0 flex items-center">
                      <div className="flex items-center">
                        <a
                          className="text-white"
                          href={ctaLocalLink2}
                          id="hero_cta"
                          target={target2}
                        >
                          <div className="flex items-center gap-2">
                            {data?.ctaButton2?.text}{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="12"
                              viewBox="0 0 15 12"
                              fill="none"
                            >
                              <path
                                d="M14.3937 6.53282L14.925 6.00157L14.3937 5.47032L9.59374 0.670319L9.06249 0.139069L8.00312 1.19844L8.53437 1.72969L12.0531 5.25157H1.71249H0.962494V6.75157H1.71249H12.05L8.53124 10.2703L7.99999 10.8016L9.05937 11.8609L9.59062 11.3297L14.3937 6.53282Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>

                      {/* <Button variant={data?.ctaButton?.variant}>
                      {ctaLocalLink && (
                        <LocalizedLink
                          id="hero_cta"
                          language={locale}
                          to={ctaLocalLink}
                          target={target}
                        >
                          <span>{data?.ctaButton?.text}</span>
                        </LocalizedLink>
                      )}
                    </Button> */}
                    </div>
                  )}
                  {ctaExternalLink2 && (
                    <div className="mt-sm sm:mt-sm mb-xxs xxs:mb-xs sm:mb-0 flex items-center">
                      <div className="flex items-center">
                        <a
                          className="text-white"
                          href={ctaExternalLink2}
                          id="hero_cta"
                          target={target2}
                        >
                          <div className="flex items-center gap-2">
                            {data?.ctaButton2?.text}{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="12"
                              viewBox="0 0 15 12"
                              fill="none"
                            >
                              <path
                                d="M14.3937 6.53282L14.925 6.00157L14.3937 5.47032L9.59374 0.670319L9.06249 0.139069L8.00312 1.19844L8.53437 1.72969L12.0531 5.25157H1.71249H0.962494V6.75157H1.71249H12.05L8.53124 10.2703L7.99999 10.8016L9.05937 11.8609L9.59062 11.3297L14.3937 6.53282Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </HeroBannerContentArea>
        </Container>
      </>
    );
  }
};
export default HeroBanner;
