import React from 'react';
import styled from 'styled-components';
import { useLocalization } from 'gatsby-theme-i18n';
import { ContentfulComponentRegistrationForm } from '@/../graphql-types';

import {
  Template1_2ColumnContent,
  Template1_FullWidth,
  Template2_FullWidth,
  Template_Minimum,
  Template1_singleColumn,
} from '@/components/forms';
import Template_Contact from '@/components/forms/template_contact_sitewide';

/* 

template1 - full width
template2 - full width

template1 - 2 columns with content


*/

const Container = styled.div``;

interface RegistrationFormProps {
  data?: ContentfulComponentRegistrationForm;
  className?: string;
}

const RegistrationForm: React.FC<RegistrationFormProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  // EU nations, to determine whether to show GDPR compliance text
  const gdprMap = [
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Cyyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'United Kingdom',
  ];

  function ShowForm(props) {
    const formType = props.type;

    if (formType === 'template1 - 2 columns with content') {
      return <Template1_2ColumnContent data={data} gdprMap={gdprMap} />;
    } else if (formType === 'template2 - full width') {
      return <Template2_FullWidth data={data} gdprMap={gdprMap} />;
    } else if (formType === 'template - minimum') {
      return <Template_Minimum data={data} gdprMap={gdprMap} />;
    } else if (formType === 'template1 - singleColumn') {
      return <Template1_singleColumn data={data} gdprMap={gdprMap} />;
    } else if (formType === 'template - contactus') {
      return <Template_Contact data={data} gdprMap={gdprMap} />;
    } else {
      // default 'template1 - full width'
      return <Template1_FullWidth data={data} gdprMap={gdprMap} />;
    }
  }

  return (
    <Container className="mt-sm" id="form">
      <div className="container">
        <ShowForm type={data?.type} />
      </div>
    </Container>
  );
};
export default RegistrationForm;
