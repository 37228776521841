import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { ContentfulAsset } from '@/../graphql-types';

interface ContentfulImageProps {
  image?: ContentfulAsset;
  [properties: string]: any;
}
/**
 * This component takes an image of type ContentfulAsset and determines
 * whether it should use the GatsbyImage element for .pngs, .jpgs, etc.,
 * or a standard <img /> element for .svgs.
 *
 * The GraphQL query must contain either image.gatsbyImageData for
 * static images or image.file.contentType and image.file.url if it is
 * an SVG.
 */
const ContentfulImage: React.FC<ContentfulImageProps> = (props) => {
  const { image, alt, ...properties } = props;
  const isSvg = image?.file?.contentType === 'image/svg+xml';

  return isSvg ? (
    <img src={image?.file?.url} alt={alt} {...properties} />
  ) : (
    <GatsbyImage
      image={getImage(image?.gatsbyImageData)}
      alt={alt}
      {...properties}
    />
  );
};
export default ContentfulImage;
