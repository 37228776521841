import React from 'react';
import styled from 'styled-components';
import { CheckCircleOutline } from '@material-ui/icons';

import { ContentfulComponentPlatformFeatureStats } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

const StatWrapper = styled.div`
  height: 160px;
  width: 160px;
  padding: 4px;
  border-radius: 100%;
  background: #f00;
  @media (min-width: calc(${(props) => props.theme.breakpoint.md} - 1px)) {
    width: 200px;
    height: 200px;
  }
`;
const StatInner = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

interface PlatformFeatureStatsProps {
  data?: ContentfulComponentPlatformFeatureStats;
  className?: string;
}
const PlatformFeatureStats: React.FC<PlatformFeatureStatsProps> = (props) => {
  const { data } = props;

  // flip the stats column if necessary
  const statsPosition = data.statsAlign === 'Right' ? 'order-last' : '';

  return (
    <Container className="">
      <div className="container grid items-center grid-cols-1 lg:grid-cols-2 gap-sm md:gap-md">
        {data?.stats?.length > 0 && (
          <div
            className={`grid grid-cols-1 md:grid-cols-2 gap-sm md:gap-md ${
              statsPosition || ``
            }`}
          >
            {data?.stats?.map((stat) => {
              return (
                <>
                  <div className="text-center">
                    <StatWrapper
                      key={`stat-${stat.title}`}
                      className="bg-yellow text-yellowMain font-bold text-4xl md:text-6xl mx-auto mb-xs"
                    >
                      <StatInner>{stat.title}</StatInner>
                    </StatWrapper>
                    <p>{stat?.subheading}</p>
                  </div>
                </>
              );
            })}
          </div>
        )}
        <div>
          {data?.topic.title && (
            <Typography
              as="h2"
              variant="h2"
              className="text-primaryMain mb-xs block text-center md:text-left"
            >
              {data?.topic.title}
            </Typography>
          )}
          {data?.topic?.subheading && (
            <p className="text-center md:text-left">{data.topic.subheading}</p>
          )}
          {data?.topic?.highlights && data?.topic?.highlights.length > 0 && (
            <div>
              <ul className="mt-sm">
                {data.topic?.highlights?.map((highlight) => {
                  return (
                    <li
                      key={`highlight-list-item-${highlight.replace(' ', '-')}`}
                      className="flex items-center mt-xs first:mt-0"
                    >
                      <span>
                        <CheckCircleOutline className="text-primaryMain" />
                      </span>
                      <Typography variant="body" className="ml-xs">
                        {highlight}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
export default PlatformFeatureStats;
