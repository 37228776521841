import React from 'react';
import styled from 'styled-components';

interface TypographyContainerProps {
  variant?: string;
}
export const Container = styled.div<TypographyContainerProps>`
  color: inherit;

  ${(props) => {
    switch (props.variant) {
      case 'h1':
        return `
        font-size: ${props.theme.fontSize.mobileH1};
        font-weight: ${props.theme.fontWeight.book};
        letter-spacing: 0px;
        line-height: 1.15;

        @media (min-width: ${props.theme.breakpoint.tablet}) {
          font-size: ${props.theme.fontSize.h2};
        }

        @media (min-width: ${props.theme.breakpoint.desktop}) {
          font-size: ${props.theme.fontSize.h1};
        }
      `;
      case 'h2':
        return `
        font-size: ${props.theme.fontSize.mobileH2};
        font-weight: ${props.theme.fontWeight.light};
        letter-spacing: 0.25px;
        line-height: 40px;

        @media (min-width: ${props.theme.breakpoint.tablet}) {
          font-size: ${props.theme.fontSize.h3};
          line-height: 1.235;
        }

        @media (min-width: ${props.theme.breakpoint.desktop}) {
          font-size: ${props.theme.fontSize.h2};
        }
      `;
      case 'h3':
        return `
        font-size: ${props.theme.fontSize.mobileH3};
        font-weight: ${props.theme.fontWeight.light};
        letter-spacing: 0px;
        line-height: 1.334;

        @media (min-width: ${props.theme.breakpoint.tablet}) {
          font-size: ${props.theme.fontSize.h3};
        }
      `;
      case 'h4':
        return `
        font-size: ${props.theme.fontSize.h4};
        font-weight: ${props.theme.fontWeight.book};
        letter-spacing: 0.15px;
        line-height: 1.6;
      `;
      case 'h5':
        return `
        font-size: ${props.theme.fontSize.h5};
        font-weight: ${props.theme.fontWeight.book};
        letter-spacing: 0.15px;
        line-height: 1.35;
      `;
      case `body`:
        return `
        font-size: ${props.theme.fontSize.body};
        font-weight: ${props.theme.fontWeight.book};
        letter-spacing: 0.15px;
        line-height: 1.6;
      `;
      case `caption`:
        return `
        font-size: ${props.theme.fontSize.caption};
        font-weight: ${props.theme.fontWeight.bold};
        letter-spacing: 0.4px;
        line-height: 1.66;
        `;
      case `captionCaps`:
        return `
          font-size: ${props.theme.fontSize.caption};
          font-weight: ${props.theme.fontWeight.book};
          letter-spacing: 0.15px;
          line-height: 1.6;
          text-transform: uppercase;
          `;
      case `subtitle`:
        return `
        font-size:  ${props.theme.fontSize.subtitle};
        font-weight: ${props.theme.fontWeight.book};
        letter-spacing: 0.1px;
        line-height: 1.57;
        `;
      case `blockquote`:
        return `
          font-size:  ${props.theme.fontSize.h4};
          font-weight: ${props.theme.fontWeight.light};
          line-height: 1.57;
          @media (min-width: ${props.theme.breakpoint.tablet}) {
            font-size: ${props.theme.fontSize.h3};
          }
          `;
      case `helperText`:
        return `
          font-family: ${props.theme.fontFamily.arboria};
          font-size: ${props.theme.fontSize.caption};
          font-weight: ${props.theme.fontWeight.book};
          line-height: 20px;
        `;
      default:
        return `
        background-color: red;
        font-size: ${props.theme.fontSize.body};
        `;
    }
  }}
`;
interface TypographyProps {
  as?: keyof JSX.IntrinsicElements;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body'
    | 'caption'
    | 'captionCaps'
    | 'subtitle'
    | 'blockquote';
  children?: React.ReactNode;
  className?: string;
  id?: string;
}
const Typography: React.FC<TypographyProps> = ({
  as = 'span',
  variant = 'body',
  id,
  children,
  className,
}) => {
  return (
    <Container
      id={id}
      as={as}
      variant={variant}
      className={`${className || ``}`}
    >
      {children}
    </Container>
  );
};

export default Typography;
