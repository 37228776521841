import React from 'react';
import styled from 'styled-components';

import { ContentfulComponentTestimonial } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { getFullLink } from '@/utils/helpers';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import Button from '@/components/layout/Button';
import { ArrowForward } from '@material-ui/icons';

const Container = styled.div``;

interface TestimonialProps {
  data?: ContentfulComponentTestimonial;
  className?: string;
}
const Testimonial: React.FC<TestimonialProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  let padding;
  if (!data.background) {
    padding = 'py-sm';
  }

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  const btnVariant =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton?.variant;

  return (
    <Container className={` ${padding}`}>
      <div className="container max-w-6xl text-center">
        <blockquote>
          {data?.testimonial?.testimonial?.testimonial && (
            <Typography variant="blockquote" className="block">
              {data?.testimonial?.testimonial?.testimonial}
            </Typography>
          )}
          {data?.testimonial?.source && (
            <cite className="block max-w-2xl mx-auto mt-sm text-xl md:text-2xl text-primaryMain not-italic">
              {data?.testimonial?.source}
            </cite>
          )}
        </blockquote>
        <div className="flex justify-center">
          {ctaLocalLink && (
            <div className="flex justify-center md:justify-start mt-sm">
              <Button variant={btnVariant}>
                {ctaLocalLink && (
                  <LocalizedLink
                    language={locale}
                    to={ctaLocalLink}
                    target={target}
                    className={`button cta_${data?.topic?.title
                      .split(' ')
                      .join('-')}`}
                  >
                    <span>{ctaText}</span>
                    <span className="inline-block ml-xxs">
                      <ArrowForward />
                    </span>
                  </LocalizedLink>
                )}
              </Button>
            </div>
          )}
          {ctaExternalLink && (
            <div className="flex justify-center md:justify-start mt-sm">
              <Button variant={btnVariant}>
                <a
                  href={ctaExternalLink}
                  target={target}
                  className={`button cta_${data?.topic?.title
                    .split(' ')
                    .join('-')}`}
                >
                  <span>{ctaText}</span>
                  <span className="inline-block ml-xxs">
                    <ArrowForward />
                  </span>
                </a>
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
export default Testimonial;
