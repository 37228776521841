import React from 'react';

import { ContentfulComponentTextBlock } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

interface TextBlockProps {
  data: ContentfulComponentTextBlock;
}

// treated as Intro text on pages

const TextBlock: React.FC<TextBlockProps> = (props) => {
  const { data } = props;

  let introText;
  if (data?.text) {
    introText = data?.text
      .replace('®', '<sup>&reg;</sup>')
      .replace('™', '<sup>&trade;</sup>');
  } else if (data?.content?.content) {
    introText = data?.content?.content
      .replace('®', '<sup>&reg;</sup>')
      .replace('™', '<sup>&trade;</sup>');
  }

  return (
    <div className="container py-sm lg:mt-sm">
      <p
        className="text-center text-primaryMain px-xs md:px-md lg:px-xxl font-light text-4xl"
        dangerouslySetInnerHTML={{ __html: introText }}
      ></p>
    </div>
  );
};
export default TextBlock;
