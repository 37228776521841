import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';

import { ContentfulComponentNavItem } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';

const Container = styled.div``;

interface NavItemProps {
  data?: ContentfulComponentNavItem;
  className?: string;
}
const NavItem: React.FC<NavItemProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();
  return (
    <Container className="mt-lg">
      <div className="container">
        TODO --- NavItem COMPONENT ---
        src/components/contentful/components/NavItem.tsx
      </div>
    </Container>
  );
};
export default NavItem;
