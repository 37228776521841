import React from 'react';
import styled from 'styled-components';

import { ContentfulTopicResourceCategory } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

const LinesWrapper = styled.div`
  transform: translateX(24px);
`;

interface ResourceIntroProps {
  data?: ContentfulTopicResourceCategory;
  className?: string;
}
const ResourceIntro: React.FC<ResourceIntroProps> = (props) => {
  const { data } = props;

  return (
    <Container className="mb-md">
      <div className="container md:flex gap-x-md">
        {data?.title && (
          <>
            <div className="w-1/4 flex-shrink-0 text-center my-sm md:my-0 md:text-left relative">
              <Typography
                as="h1"
                variant="h3"
                className="text-primaryMain font-normal md:mt-md"
              >
                {data?.title}
              </Typography>
              <LinesWrapper className="hidden md:block absolute top-0 right-0">
                <svg
                  width="49"
                  height="150"
                  viewBox="0 0 49 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25 2L25 104"
                    stroke="#00A566"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <line
                    x1="1.5"
                    y1="-1.5"
                    x2="14.5"
                    y2="-1.5"
                    transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 24.5 133.215)"
                    stroke="#00A566"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <circle
                    r="2"
                    transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 25.5 147.215)"
                    fill="#00A566"
                  />
                  <path
                    d="M47 64L47 2"
                    stroke="#006DFF"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <path
                    d="M2 42L2 2"
                    stroke="#FFB700"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                </svg>
              </LinesWrapper>
            </div>
          </>
        )}

        {data?.shortDescription?.shortDescription && (
          <div className="md:mt-md">
            {data?.shortDescription?.shortDescription}
          </div>
        )}
      </div>
    </Container>
  );
};
export default ResourceIntro;
