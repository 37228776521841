import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
// import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward } from '@material-ui/icons';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';

import { ContentfulComponentEventCardSet } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div``;

const EventImageContainer = styled.div`
  height: 215px;
  @media (min-width: ${(props) => props.theme.breakpoint.xs}) {
    height: 300px;
  }
  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    max-height: 215px;
  }
`;

interface EventCardSetProps {
  data?: ContentfulComponentEventCardSet;
  className?: string;
  columns?: boolean;
}
const EventCardSet: React.FC<EventCardSetProps> = (props) => {
  const { data, columns } = props;
  const { locale } = useLocalization();

  let hasFutureEvent = false;
  const today = new Date();

  data.eventCards?.map((card) => {
    const hasFutureStartDate = card.event?.startDate;
    const hasFutureEndDate = card.event?.endDate;
    let hasFutureCheckDate = new Date(hasFutureStartDate);
    if (hasFutureEndDate) {
      hasFutureCheckDate = new Date(hasFutureEndDate);
    }

    if (!hasFutureEvent && today < hasFutureCheckDate) {
      hasFutureEvent = true;
    }
  });

  let wrapperClasses;
  let cardClasses = 'lg:grid gap-x-sm grid-cols-12';
  if (columns) {
    wrapperClasses = 'grid grid-cols-12 md:gap-x-sm';
    cardClasses = 'col-span-12 md:col-span-6';
  }

  return (
    <Container className="mt-md">
      <div className="container">
        {hasFutureEvent && data?.headline && (
          <Typography as="h2" variant="h2" className="text-center mb-md">
            {data?.headline}
          </Typography>
        )}

        <div className={wrapperClasses}>
          {data.eventCards?.map((card) => {
            // let page, icon, subHeading, product, slug;
            const title = card.event?.title;
            const startDate = card.event?.startDate;
            const endDate = card.event?.endDate;
            const subHeading = card.event?.subheading;
            let link, slug, target, rel;
            let checkDate = startDate;
            if (endDate) {
              checkDate = endDate;
            }

            let showEndDate = true;
            if (startDate === endDate) {
              showEndDate = false;
            }

            // links
            const ctaText = card.ctaButton?.text;
            if (card?.ctaButton?.link) {
              slug = card?.ctaButton?.link.url
                ? card.ctaButton.link.url
                : getFullLink(card.ctaButton.link.page, locale);
              target = data?.ctaButton?.link?.openInNewWindow ? '_blank' : '';
              rel = data?.ctaButton?.link?.openInNewWindow
                ? 'noreferrer noopener'
                : '';
            }

            if (card.event?.registrationPage) {
              link = card.event?.registrationPage;
            } else {
              link = slug;
            }

            // check date
            const checkFuture = new Date(checkDate);

            if (today < checkFuture) {
              return (
                <div
                  key={`platform-${title}`}
                  className={`rounded shadow-30 overflow-hidden p-4 mb-sm max-w-4xl mx-auto ${cardClasses}`}
                >
                  <div className="col-span-12 lg:col-span-4">
                    <EventImageContainer className="flex justify-center rounded-8 overflow-hidden">
                      {card?.event?.featureImage.gatsbyImageData && (
                        <GatsbyImage
                          image={getImage(
                            card?.event?.featureImage?.gatsbyImageData,
                          )}
                          alt=""
                          // className="rounded-8"
                          style={{ maxHeight: 'inherit' }}
                        />
                      )}
                    </EventImageContainer>
                  </div>

                  <div className="col-span-12 text-center lg:text-left lg:col-span-8 py-4">
                    <div className="font-bold text-xs mb-2">
                      {/* <span className="uppercase">Event</span> */}
                      <span className="inline-block">
                        {startDate}
                        {showEndDate && endDate && ' - ' + endDate}
                      </span>
                    </div>
                    <Typography
                      as="h3"
                      variant="h4"
                      className="leading-tight font-normal text-primaryMain mb-4"
                    >
                      {title}
                    </Typography>
                    <Typography variant="body" className="block mb-4">
                      {subHeading}
                    </Typography>

                    {link && (
                      <Button variant={data?.ctaButton?.variant}>
                        {link && (
                          <LocalizedLink
                            language={locale}
                            to={link}
                            target={target}
                            rel={rel}
                            className="cta_eventCard"
                          >
                            <span>{ctaText}</span>
                            <ArrowForward className="ml-xs" />
                          </LocalizedLink>
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              );
            } else {
              return;
            }
          })}
        </div>
      </div>
    </Container>
  );
};
export default EventCardSet;
