import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ContentfulComponentTabbedFeatures } from '../../../../graphql-types';
import PlatformCard from './PlatformCard';
import Typography from '../../layout/Typography';
import Button from '../../layout/Button';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div`
  .twoUp {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tabContainer {
    display: flex;
    justify-content: flex-start;
    gap: 4%;

    .tabPanel {
      flex-grow: 1;
    }

    .tabContent {
      flex-grow: 1;
      max-width: 620px;
      padding-right: 20px;
    }

    .cardContent {
      flex-grow: 1;
      max-width: 500px;
      min-width: 320px;

      > div + div {
        margin-top: 20px;
      }
    }

    .tabsList {
      flex-shrink: 0;

      .MuiTabs-indicator {
        right: unset;
        left: 10px;
        width: 5px;
        max-height: 36px;
        margin-top: 8px;
        background-color: #006dff;
      }

      .singleTab {
        &.Mui-selected {
          background-color: rgba(0, 109, 255, 0.05);
        }

        &.MuiTab-root {
          text-transform: none;
          font-family: arboria, Helvetica, Arial, Tahoma, sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #006dff;
          text-align: left;
          border-radius: 5px;
          margin-bottom: 24px;
          padding: 12px 18px 12px 28px;
        }
        .MuiTab-wrapper {
          align-items: flex-start;
        }
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .accordionContainer {
    display: none;

    .MuiAccordion-root {
      margin-bottom: 16px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;

      &::before {
        display: none;
      }
    }

    .accordionSummary,
    .accordionIcon {
      color: #006dff;
    }

    .accordionSummary.Mui-expanded {
      border-bottom: 1px solid rgba(216, 216, 216, 1);
    }

    .MuiAccordionDetails-root {
      padding: 16px 16px 48px;
    }

    @media (max-width: 1024px) {
      display: block;
    }
  }
`;

const Highlights = styled.ul`
  padding: 0;
  list-style: none;

  .check {
    margin-right: 22px;
  }

  li + li {
    margin-top: 22px;
  }
`;

interface TabPanelProps {
  index: number;
  value: number;
  content: any;
}

interface TabbedFeaturesProps {
  data?: ContentfulComponentTabbedFeatures;
  className?: string;
}

const ContentBlock = (props: any) => {
  const { content } = props;
  const { locale } = useLocalization();

  let link = '/';
  if (content?.ctaButton?.link?.url) {
    link = content?.ctaButton?.link?.url;
  } else if (content?.ctaButton?.link?.page) {
    link = getFullLink(content?.ctaButton?.link?.page, locale);
  }
  let target;
  if (content?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  return (
    <Box className="tabContent">
      {content?.topic?.subheading && (
        <Typography as="h2" variant="h3" className="text-primaryMain mb-8">
          {content?.topic?.subheading}
        </Typography>
      )}
      {content?.topic?.shortDescription?.shortDescription && (
        <Typography as="p" variant="body" className="mb-8">
          {content?.topic?.shortDescription?.shortDescription}
        </Typography>
      )}
      <Highlights>
        {content?.topic?.highlights?.map((highlight: any, i: number) => (
          <Typography key={`highlight-${i}`} variant="body" as="li">
            <CheckCircleOutline className="check text-primaryMain" />
            {highlight}
          </Typography>
        ))}
      </Highlights>
      {content?.ctaButton?.link && (
        <Button variant="Outline" className="mt-sm text-black">
          {link && (
            <LocalizedLink
              language={locale}
              to={
                content.ctaButton?.link?.url ||
                getFullLink(content.ctaButton?.link?.page, locale) ||
                '/'
              }
              target={target}
              className={`cta_${content?.topic?.title.split(' ').join('-')}`}
            >
              {content.ctaButton?.text}
              <ArrowForward className="ml-xxs" />
            </LocalizedLink>
          )}
        </Button>
      )}
    </Box>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { content, value, index, ...other } = props;

  // handle images, if present
  let featureImage, featureAlt;
  if (content?.topic?.featureImage?.gatsbyImageData) {
    featureImage = content?.topic?.featureImage?.gatsbyImageData;
    featureAlt = content?.topic?.featureImage?.description;
  } else if (content?.featureImage?.gatsbyImageData) {
    featureImage = content?.featureImage?.gatsbyImageData;
    featureAlt = content?.featureImage?.description;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className="tabPanel"
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="twoUp">
          <ContentBlock content={content} />
          <Box className="cardContent">
            {content.platformCards?.map((card: any, index: number) => (
              <PlatformCard
                key={`platform-card-${card.id}-${index}`}
                data={card}
              />
            ))}
            {featureImage && (
              <GatsbyImage
                image={getImage(featureImage)}
                objectFit="contain"
                style={{ maxWidth: '584px', margin: '0 auto' }}
                alt={featureAlt || ''}
              />
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const TabbedFeatures: React.FC<TabbedFeaturesProps> = (props) => {
  const { data } = props;
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState('panel1');

  let hasIntro = false;
  if (data?.title || data.subheadingLong?.subheadingLong) {
    hasIntro = true;
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, [data]);

  if (!hasMounted) {
    return null;
  }

  return (
    <Container className="container py-sm">
      {hasIntro && (
        <div className="intro max-w-5xl mx-auto mb-md">
          {data?.title && (
            <Typography
              as="h2"
              variant="h2"
              className="text-primaryMain text-center mb-8"
            >
              {data?.title}
            </Typography>
          )}
          {data?.subheadingLong?.subheadingLong && (
            <Typography as="p" variant="body" className="text-center">
              {data?.subheadingLong?.subheadingLong}
            </Typography>
          )}
        </div>
      )}
      <div className="tabContainer">
        <Tabs
          orientation="vertical"
          variant="standard"
          value={value}
          onChange={handleTabChange}
          aria-label="Product Information tabs"
          className="tabsList"
        >
          {data.tabbedFeatures?.map((feature: any, i: number) => (
            <Tab
              key={`tab-${feature.id}-${i}`}
              label={feature.topic?.title}
              className="singleTab"
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
        {data.tabbedFeatures?.map((feature: any, i: number) => (
          <TabPanel
            key={`tabPanel-${feature.id}-${i}`}
            value={value}
            index={i}
            content={feature}
          />
        ))}
      </div>

      <div className="accordionContainer">
        {data.tabbedFeatures?.map((feature: any, i: number) => (
          <Accordion
            key={`accordion-${feature.id}-${i}`}
            expanded={expanded === `panel${i + 1}`}
            onChange={handleAccordionChange(`panel${i + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="accordionIcon" />}
              aria-controls={`panel${i + 1}bh-content`}
              id={`panel${i + 1}bh-header`}
              className="accordionSummary"
            >
              <Typography>{feature.topic?.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ContentBlock content={feature} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Container>
  );
};
export default TabbedFeatures;
