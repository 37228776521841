import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ContentfulComponentJazzHr } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { ArrowForward } from '@material-ui/icons';
import { LocalizedLink } from 'gatsby-theme-i18n';
import Button from '@/components/layout/Button';

/* 

"id": "job_20211213215506_PDIN5YRZOWJ8SVKS",
"team_id": "",
"title": "Training & Enablement Specialist",
"country_id": "Canada",
"city": "Kitchener",
"state": "Ontario",
"zip": "",
"department": "Marketing",
"description": "",
"minimum_salary": "0",
"maximum_salary": "0",
"notes": "",
"original_open_date": "2021-12-13",
"type": "Full Time",
"status": "Open",
"send_to_job_boards": "Yes",
"hiring_lead": "usr_20210322141510_JOWRVNV7KZ7F3ZAS",
"board_code": "tebuHdUaek",
"internal_code": "",
"questionnaire": "questionnaire_20200805172142_NB22SY2QFCEQXOL7"

*/

const Container = styled.div`
  .jobList a:hover,
  .jobList a:focus,
  .jobList a:active {
    text-decoration: none;
  }
  .jobList a svg {
    transition: all 0.2s;
  }
  .jobList a:hover svg {
    transform: translateX(2px);
  }
`;

interface JazzHrProps {
  data?: ContentfulComponentJazzHr;
  className?: string;
}
const JazzHr: React.FC<JazzHrProps> = (props) => {
  const API_KEY = process.env.GATSBY_JAZZHR_API_KEY;
  const JAZZHR_ENDPOINT =
    'https://api.resumatorapi.com/v1/jobs/status/open?apikey=';
  const API_FULL_URL = `${JAZZHR_ENDPOINT}${API_KEY}`;

  const { data } = props;
  const [jazzData, setJazzData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const result = fetch(API_FULL_URL)
      .then((response) => response.json())
      .then((data) => {
        if (!(data instanceof Array)) {
          setJazzData([data]);
        } else {
          setJazzData(data);
        }
      })
      .then(() => setIsLoading(false));
  }, []);

  if (jazzData && jazzData.length) {
    // sorts by department
    jazzData.sort(function (a, b) {
      const sortA = a.department.toUpperCase();
      const sortB = b.department.toUpperCase();
      if (sortA < sortB) {
        return -1;
      }
      if (sortA > sortB) {
        return 1;
      }
      return 0;
    });
  }

  let title, subtitle;

  if (data?.title) {
    title = data?.title;
  }
  if (data?.subtitle?.subtitle) {
    subtitle = data?.subtitle?.subtitle;
  }

  let hasIntro = false;
  if (title || subtitle) {
    hasIntro = true;
  }

  let currentDepartment, showDepartment;

  return (
    <Container className="mt-lg" id="jobPosts">
      {hasIntro && (
        <div className="container mb-md">
          {title && (
            <Typography
              as="h2"
              variant="h2"
              className="mb-0 block text-center text-primaryMain"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography as="p" variant="body" className="text-center mt-xs">
              {subtitle}
            </Typography>
          )}
        </div>
      )}

      <div className="container max-w-4xl mx-auto jobList">
        <div className="mb-lg">
          {isLoading && (
            <Typography as="p" variant="body" className="text-center">
              Loading...
            </Typography>
          )}
          {jazzData && jazzData.length && (
            <>
              {jazzData?.map((jobEntry) => {
                if (currentDepartment !== jobEntry.department) {
                  showDepartment = true;
                  currentDepartment = jobEntry.department;
                } else {
                  showDepartment = false;
                }

                let location = 'Remote';
                let city, country;

                if (jobEntry.country_id) {
                  country = jobEntry.country_id;
                }

                if (jobEntry.city) {
                  city = jobEntry.city;
                  location = city + ', ' + country;
                }

                return (
                  <div key={jobEntry.id}>
                    {showDepartment && (
                      <Typography
                        as="h3"
                        variant="h3"
                        className="text-primaryMain px-xs mt-6 mb-2"
                      >
                        {currentDepartment}
                      </Typography>
                    )}
                    <a
                      href={`https://miovision.applytojob.com/apply/${jobEntry.board_code}`}
                      target="_blank"
                      rel="noreferrer"
                      className="block md:flex items-center justify-between p-xs hover:bg-grey4 focus:bg-grey4 rounded-md transition"
                    >
                      <div>
                        <Typography
                          as="p"
                          variant="body"
                          className="text-black font-bold"
                        >
                          {jobEntry.title}
                        </Typography>
                        <span className="uppercase text-sm text-grey3 font-medium">
                          {location && <>{location}</>}
                        </span>
                      </div>
                      <span className="text-primaryMain">
                        Apply <ArrowForward />
                      </span>
                    </a>
                  </div>
                );
              })}
            </>
          )}
          <hr className="my-8" />
          <div className="max-w-xs">
            <a
              href="https://www.glassdoor.ca/Overview/Working-at-Miovision-Technologies-EI_IE302835.11,33.htm"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://www.glassdoor.ca/api/widget/horizontalStarRating.htm?e=302835" />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default JazzHr;
