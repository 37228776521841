import React from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContentfulGlobalMicrocopyResource } from '../../graphql-types';

import RouteMappingEnUS from '@/config/routeMapping.en_US.json';

/**
 * getMicroCopyByName
 * search through a microcopy array for the given name and return an object
 * that can be used via contentful rich text.  if the name isn't found in the array
 * a string is returned with the default text
 *
 * @param referenceName string - the name of the item being searched for
 * @param itemList array - an array of microcopy items
 * @param notFoundResponse string (optional) - a default string to display if the name isn't found
 * @returns (microcopy | string) - either microcopy if found, or string if not
 */
export const getMicroCopyByName = (
  referenceName: string,
  itemList: ContentfulGlobalMicrocopyResource[],
  notFoundResponse = '',
): ContentfulGlobalMicrocopyResource | string => {
  if (itemList === null) return notFoundResponse;
  const obj = itemList.find((o) => o.name === referenceName);
  return obj ? obj : notFoundResponse;
};

/**
 * getPlaintTextFromMicrocopy
 * Returns a plain text string of the Contentful rich text object. Useful for
 * labels and text areas that have multiple translations without the needed
 * styling from Contentful.
 *
 * @param microcopy ContentfulGlobalMicrocopyResource - A Globa: Microcopy Contentful field.
 * @return string - Plain text version of the microcopy.
 */
export const getPlainTextFromMicrocopyField = (
  microcopy: ContentfulGlobalMicrocopyResource,
): string => {
  let text = '';
  let json;
  try {
    json = JSON.parse(microcopy?.copy?.raw);
    text = documentToPlainTextString(json);
  } catch (e) {
    // noop
  }
  return text;
};

const options = {
  // TODO: reserved for future use
  // renderMark: {
  //   [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  // },
  // renderNode: {
  //   [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  // },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

/**
 * renderRichText
 * Helper function that renders Contentful rich text fields as React elements.
 *
 *
 * @param document Docjument - A contentful rich text `raw` rich text field.
 * @return React.ReactNode - The corresponding React elements for the rich text.
 */
export const renderRichText = (document): React.ReactNode => {
  try {
    const json = JSON.parse(document);
    return documentToReactComponents(json, options);
  } catch (e) {
    return React.Fragment;
  }
};

export const getFullLinkByContent = (
  slug: string,
  contentType: string,
  locale = 'en_US',
): string => {
  // grab the appropriate routing table
  const localizedRoutes = RouteMappingEnUS;

  const localeRoute = localizedRoutes.find(
    (route) => route.contentModel === contentType,
  );

  const parentPath = localeRoute?.path ? localeRoute.path : '';
  const resolvedUrl = slug?.charAt(0) === '/' ? slug : `/${slug}`;
  return `${parentPath}${resolvedUrl}`;
};

export const getFullLink = (link, locale = 'en_US'): string | null => {
  let resolvedUrl = null;
  // grab the appropriate routing table
  const localizedRoutes = RouteMappingEnUS;

  if (link?.slug)
    resolvedUrl = link.slug.charAt(0) === '/' ? link.slug : `/${link.slug}`;

  if (link?.content?.__typename) {
    const localeRoute = localizedRoutes.find(
      (route) => route.contentModel === link.content.__typename,
    );

    let parentPath = localeRoute?.path ? localeRoute.path : '';

    if (link?.content?.topic) {
      const page = link.content.topic.product?.page__product?.find(
        (el) => el.compose__page !== null,
      );
      const composePage = page?.compose__page[0] || '';
      parentPath = composePage === '' ? '' : `/${composePage.slug}`;
    }

    resolvedUrl = parentPath + resolvedUrl;
  }
  // some extra checks to ensure consistent results
  if (resolvedUrl === null) return '/';
  return resolvedUrl.charAt(0) === '/' ? resolvedUrl : `/${resolvedUrl}`;
};

export const slugify = (str): string | null => {
  str = str.replace(/^\s+|\s+$/g, '');
  // Make the string lowercase
  str = str.toLowerCase();
  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // Collapse dashes
    .replace(/-+/g, '-');

  return str;
};
