import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';

import {
  ContentfulTopicEvent,
  ContentfulComponentCtaButton,
} from '@/../graphql-types';
import { ArrowDownward, ArrowForward } from '@material-ui/icons';
import Button from '@/components/layout/Button';
import Typography from '@/components/layout/Typography';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div``;

interface EventDetailsProps {
  data?: ContentfulTopicEvent;
  button?: ContentfulComponentCtaButton;
}

const EventDetails: React.FC<EventDetailsProps> = (props) => {
  const { data, button } = props;
  const { locale } = useLocalization();

  const startDate = data.date ? new Date(data.date) : new Date(data.startDate);
  const endDate = data.endDate ? new Date(data.endDate) : null;

  const eventDate = startDate.toDateString();

  let eventEndDate = eventDate;
  let isSingleDay = true;

  if (endDate) {
    eventEndDate = endDate.toDateString();
    if (eventDate !== eventEndDate) {
      isSingleDay = false;
    }
  }

  const eventTime = startDate.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  });

  let showDetails = true;
  if (eventDate === 'Invalid Date') {
    showDetails = false;
  }
  const ctaLink = button?.link?.url || getFullLink(button?.link?.page, locale);

  let target;
  if (button?.link?.openInNewWindow) {
    target = '_blank';
  }

  return (
    <Container>
      <div className="container lg:flex justify-center gap-x-md mb-sm">
        {showDetails && (
          <div className="event-details w-full lg:w-1/3">
            <Typography
              as="h2"
              variant="h3"
              className="text-primaryMain mb-xs col-span-12 font-normal"
            >
              Event Details
            </Typography>
            <div className="w-full">
              {eventDate && (
                <div className="grid grid-cols-3 gap-x-md pb-xs">
                  <div className="text-primaryMain uppercase">Date:</div>
                  <div className="col-span-2">
                    {eventDate}
                    {!isSingleDay && ' - ' + eventEndDate}
                  </div>
                </div>
              )}
              {eventTime && isSingleDay && (
                <div className="grid grid-cols-3 gap-x-md pb-xs">
                  <div className="text-primaryMain uppercase">Time:</div>
                  <div className="col-span-2">{eventTime}</div>
                </div>
              )}
              {data.venue && (
                <div className="grid grid-cols-3 gap-x-md">
                  <div className="text-primaryMain uppercase">Venue:</div>
                  <div className="col-span-2">{data.venue}</div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="w-full lg:w-2/3 mt-sm">
          <Typography variant="body">
            {data?.shortDescription?.shortDescription}
          </Typography>
        </div>
      </div>
      {button && (
        <div className="w-full mt-md text-center mb-lg">
          <Button variant={button?.variant}>
            {ctaLink && (
              <LocalizedLink language={locale} to={ctaLink} target={target}>
                <span>{button?.text}</span>
                <span className="inline-block ml-xxs">
                  {button?.variant === 'Jump-Link' ? (
                    <ArrowDownward />
                  ) : (
                    <ArrowForward />
                  )}
                </span>
              </LocalizedLink>
            )}
          </Button>
        </div>
      )}
    </Container>
  );
};
export default EventDetails;
