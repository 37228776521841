export const COMPONENT_CTA_BUTTON = 'ContentfulComponentCtaButton';
export const COMPONENT_EVENT_CARD_SET = 'ContentfulComponentEventCardSet';
export const COMPONENT_SITE_FOOTER = 'ContentfulComponentSiteFooter';
export const COMPONENT_SITE_HEADER = 'ContentfulComponentSiteHeader';
export const COMPONENT_ICON = 'ContentfulComponentIcon';
export const COMPONENT_INFO_BLOCKS = 'ContentfulComponentInfoBlocks';
export const COMPONENT_LOGO = 'ContentfulComponentLogo';
export const COMPONENT_MENU = 'ContentfulComponentMenu';
export const COMPONENT_NAV_ITEM = 'ContentfulComponentNavItem';
export const COMPONENT_NAV = 'ContentfulComponentNavigation';
export const COMPONENT_PLATFORM_CARD = 'ContentfulComponentPlatformCard';
export const COMPONENT_PLATFORM_CARD_SET = 'ContentfulComponentPlatformCardSet';
export const COMPONENT_PLATFORM_FEATURE_CARDS =
  'ContentfulComponentPlatformFeatureCards';
export const COMPONENT_PLATFORM_FEATURE_INTRO =
  'ContentfulComponentPlatformFeatureIntro';
export const COMPONENT_PLATFORM_FEATURE_LARGE =
  'ContentfulComponentPlatformFeatureLarge';
export const COMPONENT_PLATFORM_FEATURE_SINGLE =
  'ContentfulComponentPlatformFeatureSingle';
export const COMPONENT_PLATFORM_FEATURE_DOUBLE =
  'ContentfulComponentPlatformFeatureDouble';
export const COMPONENT_PLATFORM_FEATURE_SET =
  'ContentfulComponentPlatformFeatureSet';
export const COMPONENT_PLATFORM_FEATURE_STATS =
  'ContentfulComponentPlatformFeatureStats';
export const COMPONENT_PROMO_POPUP = 'ContentfulComponentPopUp';
export const COMPONENT_FORM = 'ContentfulComponentRegistrationForm';
export const COMPONENT_WEBINARGATE = 'ContentfulComponentFullWebinarGate';
export const COMPONENT_RESOURCE_CARD_SET = 'ContentfulComponentResourceCardSet';
export const COMPONENT_RICH_TEXT = 'ContentfulComponentRichText';
export const COMPONENT_RICH_TEXT_COLUMNS = 'ContentfulComponentRichTextColumns';
export const COMPONENT_SELECT_DROPDOWN = 'ContentfulComponentSelectDropdown';
export const COMPONENT_SOLUTIONS_CARD = 'ContentfulComponentSolutionsCard';
export const COMPONENT_TABBED_FEATURES = 'ContentfulComponentTabbedFeatures';
export const COMPONENT_TABBED_RESOURCES = 'ContentfulComponentTabbedResources';
export const COMPONENT_TESTIMONIAL = 'ContentfulComponentTestimonial';
export const COMPONENT_FOOTER_CTA = 'ContentfulComponentFooterCta';
export const COMPONENT_TEXT_BLOCK = 'ContentfulComponentTextBlock';
export const COMPONENT_TEXT_CTA = 'ContentfulComponentTextCta';
export const COMPONENT_TWO_COLUMN_RICHTEXT =
  'ContentfulComponentTwoColumnRichText';
export const COMPONENT_CUSTOMER_LOGOS = 'ContentfulComponentCustomerLogos';
export const COMPONENT_EVENT_CARD = 'ContentfulComponentEventCard';
export const COMPONENT_HERO_BANNER = 'ContentfulComponentHeroBanner';
export const COMPONENT_JAZZ_HR = 'ContentfulComponentJazzHr';
export const COMPONENT_SOLUTIONS_CAROUSEL =
  'ContentfulComponentSolutionsCardCarousel';
export const COMPONENT_SPACER = 'ContentfulComponentSpacer';
export const COMPONENT_STATS_CLOUD = 'ContentfulComponentStatsCloud';

export const PAGE_BLOG = 'ContentfulPageBlog';
export const PAGE_CASE_STUDY = 'ContentfulPageCaseStudy';
// export const PAGE_GUIDES = 'ContentfulPageGuides';
export const PAGE_CONTACT = 'ContentfulPageContactUs';
export const PAGE_CUSTOMER_STORY = 'ContentfulPageCustomerStory';
export const PAGE_EVENT = 'ContentfulPageEvent';
export const PAGE_FLEX = 'ContentfulPageFlexible';
export const PAGE_LANDING = 'ContentfulPageLanding';
export const PAGE_PRESS_RELEASE = 'ContentfulPagePressRelease';
export const PAGE_PRODUCT = 'ContentfulPageProduct';
export const PAGE_PRODUCT_FEATURE = 'ContentfulPageProductFeature';
export const PAGE_REDIRECT = 'ContentfulPageRedirect';
export const PAGE_RESOURCE_CATEGORY = 'ContentfulPageResourceCategory';
export const PAGE_RESOURCES_OVERVIEW = 'ContentfulPageResourcesOverview';
export const PAGE_SOLUTION = 'ContentfulPageSolution';
export const PAGE_SOLUTIONS_OVERVIEW = 'ContentfulPageSolutionsOverview';
export const PAGE_VIDEO = 'ContentfulPageVideo';
export const PAGE_WEBINAR = 'ContentfulPageWebinar';
export const PAGE_WHERE_TO_BUY = 'ContentfulPageWhereToBuy';

export const TOPIC_CONTACT = 'ContentfulTopicContactUs';
export const TOPIC_RESOURCES_OVERVIEW = 'ContentfulTopicResourcesOverview';
export const TOPIC_RESOURCE_CATEGORY = 'ContentfulTopicResourceCategory';
export const TOPIC_CASE_STUDY = 'ContentfulTopicCaseStudy';
export const TOPIC_GUIDES = 'ContentfulTopicGuides';
export const TOPIC_CUSTOMER_STORY = 'ContentfulTopicCustomerStory';
export const TOPIC_EVENT = 'ContentfulTopicEvent';
export const TOPIC_WEBINAR = 'ContentfulTopicWebinar';
export const TOPIC_VIDEO = 'ContentfulTopicVideo';
export const TOPIC_FLEX = 'ContentfulTopicFlexible';
export const TOPIC_LANDING = 'ContentfulTopicLanding';
export const TOPIC_PRODUCT = 'ContentfulTopicProduct';
export const TOPIC_PRODUCT_FEATURE = 'ContentfulTopicProductFeature';
export const TOPIC_SOLUTION = 'ContentfulTopicSolution';
export const TOPIC_SOLUTIONS_OVERVIEW = 'ContentfulTopicSolutionsOverview';
export const TOPIC_BLOG = 'ContentfulTopicBlog';

export const COMPONENT_VIDEO = 'ContentfulComponentVideo';
