import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';

import { ContentfulComponentLogo } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';

const Container = styled.div``;

interface LogoProps {
  data?: ContentfulComponentLogo;
  className?: string;
}
const Logo: React.FC<LogoProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();
  return (
    <Container className="mt-lg">
      <div className="container">
        TODO --- Logo COMPONENT ---
        src/components/contentful/components/Logo.tsx
      </div>
    </Container>
  );
};
export default Logo;
