import React, { useState } from 'react';
import styled from 'styled-components';
import { ContentfulComponentInfoBlocks } from '@/../graphql-types';
import InfoBlock from '@/components/layout/InfoBlock';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

interface InfoBlocksProps {
  data?: ContentfulComponentInfoBlocks;
  className?: string;
}
const InfoBlocks: React.FC<InfoBlocksProps> = (props) => {
  const { data } = props;

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const titleColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';

  let title, subtitle;

  if (data?.title) {
    title = data?.title;
  }
  if (data?.infoBlockSubtitle) {
    subtitle = data?.infoBlockSubtitle;
  }

  let hasIntro = false;
  if (title || subtitle) {
    hasIntro = true;
  }

  const blockWidth =
    data?.topics?.length === 2 ? 'lg:col-span-6' : 'lg:col-span-4';

  return (
    <Container className="mt-lg">
      <div className="container">
        {hasIntro && (
          <div className="max-w-5xl mx-auto">
            {title && (
              <Typography
                as="h2"
                variant="h2"
                className={`mb-0 block text-center ${titleColor}`}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                as="p"
                variant="body"
                className={`text-center mt-xs ${textColor}`}
              >
                {subtitle}
              </Typography>
            )}
          </div>
        )}

        <div className="container md:grid grid-cols-12 md:gap-sm mb-sm mt-sm rounded">
          {data?.topics?.map((topic, i: number) => {
            const id = topic.id;
            return (
              <InfoBlock
                key={`resource-item-${id}-${i}`}
                className={blockWidth}
                textColor={textColor}
                topic={topic}
              />
            );
          })}
        </div>
      </div>
    </Container>
  );
};
export default InfoBlocks;
