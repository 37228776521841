import React, { useState } from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';

import { ContentfulComponentNavigation } from '@/../graphql-types';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div``;
const OpenButton = styled.button`
  cursor: pointer;
  width: 48px;
  height: 48px;
  &:before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 1em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 1em;
    border-color: black;
    transition: all 0.25s;
  }

  &.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  &.bottom:before {
    top: 0;
    transform: rotate(135deg);
  }

  &.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }
`;
interface NavigationProps {
  data?: ContentfulComponentNavigation;
  label?: string;
  currentPage?: string;
  className?: string;
}
const Navigation: React.FC<NavigationProps> = (props) => {
  const [navOpen, setNavOpen] = useState(false);

  const { data, currentPage } = props;
  const { locale } = useLocalization();

  const handleToggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <Container className="w-full bg-gray-200 ">
      <div className="container flex flex-col items-center justify-between sm:flex-row m:px-6 py-0 ">
        <div className="w-full sm:w-auto self-start sm:self-center flex flex-row sm:flex-none flex-no-wrap justify-between items-center">
          <span className="text-xl font-regular tracking-wide py-3 sm:py-0">
            {data.title}
          </span>
          <OpenButton
            className={`block sm:hidden focus:outline-none ${
              !navOpen && 'bottom'
            }`}
            type="button"
            onClick={handleToggleNav}
          />
        </div>

        <div
          className={`h-full w-full ml-auto sm:w-auto self-end sm:self-center items-center py-0 sm:py-0 sm:pb-0 sm:flex ${
            !navOpen && 'hidden'
          }`}
        >
          <ul
            className={`flex w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full sm:py-0 sm:pb-0`}
          >
            {data?.navItems?.map((navItem) => {
              const navItemID = navItem.link.page.id;

              let isCurrent = false;
              if (currentPage === navItemID) {
                isCurrent = true;
              }
              const link = navItem?.link?.page
                ? getFullLink(navItem.link.page, locale)
                : '';
              return (
                <li
                  key={`secondaryNav-list-item-${navItem.text.replace(
                    ' ',
                    '-',
                  )}`}
                  className={`inline-block first:ml-0  sm:bg-transparent sm:border-t-4 sm:border-transparent border-t border-grey border-opacity-50	w-full pr-2 md:pr-4 ml-0 py-xs sm:py-0 md:text-center ${
                    isCurrent && 'bg-grey2'
                  }`}
                >
                  {isCurrent ? (
                    <span className="text-primaryMain pl-xs sm:p-0 sm:py-4 sm:px-1 md:px-3 inline-block whitespace-nowrap border-b-4 border-transparent">
                      {navItem.text}
                    </span>
                  ) : (
                    link && (
                      <LocalizedLink
                        language={locale}
                        to={link}
                        className="no-underline whitespace-nowrap	pl-xs sm:py-4 sm:px-1 md:px-3 inline-block sm:border-b-4 border-transparent hover:border-primaryMain focus:border-primaryMain"
                      >
                        {navItem.text}
                      </LocalizedLink>
                    )
                  )}
                </li>
              );
            })}
          </ul>
          {/* <div className="ml-xs border-l pl-xs">Get a demo</div> */}
        </div>
      </div>
    </Container>
  );
};
export default Navigation;
