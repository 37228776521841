import React, { useState } from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { getFullLink } from '@/utils/helpers';
import { ArrowDownward, ArrowForward } from '@material-ui/icons';
import Button from '@/components/layout/Button';
import Typography from '@/components/layout/Typography';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const InfoBlockWrapper = styled.div``;
const InfoBlockTitle = styled.div`
  background-image: ${(props) => props.theme.backgroundGradient.blue};
  max-height: 250px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: max-height 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    background-image: ${(props) => props.theme.backgroundGradient.blueHover};
  }
  span svg {
    width: 2em;
    height: 2em;
    transition: all 0.15s;
  }
  &:hover span svg {
    transform: translateY(5px);
  }
  ${InfoBlockWrapper}.open & {
    max-height: 575px;
    background: #fff;
    transition: max-height 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;
const InfoBlockBody = styled.div`
  position: relative;
  pointer-events: none;
  opacity: 0;
  transition: none;
  @media (max-width: ${(props) => props.theme.breakpoint.md}) {
    max-height: 250px;
  }

  ${InfoBlockWrapper}.open & {
    pointer-events: all;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    @media (max-width: ${(props) => props.theme.breakpoint.md}) {
      max-height: 600px;
    }
  }
`;

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

interface InfoBlockProps {
  topic?: any;
  id?: string;
  textColor?: string;
  className?: string;
}
const InfoBlock: React.FC<InfoBlockProps> = (props) => {
  const { topic, className, textColor, id } = props;
  const { locale } = useLocalization();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  let link;
  if (topic?.ctaButton?.link?.url) {
    link = topic?.ctaButton?.link?.url;
  } else if (topic?.ctaButton?.link?.page) {
    link = getFullLink(topic?.ctaButton?.link?.page, locale);
  }

  let target;
  if (topic?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  let hasRichText = false;
  if (topic?.customInfo?.raw) {
    hasRichText = true;
  }

  return (
    <InfoBlockWrapper
      key={`resource-item-${topic?.id}-${id}`}
      className={`relative col-span-12 rounded mt-md first:mt-0 lg:mt-0 ${className}
      ${isOpen ? 'open' : ''}
      `}
      id={id}
    >
      <InfoBlockTitle
        className={`px-6 py-8 w-full rounded flex flex-col items-center justify-center ${textColor}`}
        onClick={handleToggle}
      >
        <Typography as="h3" variant="h3" className={`text-center w-full`}>
          {topic?.title}
        </Typography>
        <span className="block w-full mt-auto text-center">
          <ArrowDownward />
        </span>
      </InfoBlockTitle>
      <InfoBlockBody
        className="h-full flex flex-col items-center justify-between px-6 py-8"
        onClick={handleToggle}
      >
        <div>
          {hasRichText ? (
            <div>{renderRichText(topic?.customInfo, options)}</div>
          ) : (
            <>
              <p className="mb-4">
                {topic?.shortDescription?.shortDescription}
              </p>
              {topic?.highlights && topic?.highlights.length > 0 && (
                <ul className="list-disc list-outside ml-4 mb-sm">
                  {topic?.highlights?.map((highlight) => {
                    return (
                      <li
                        key={`highlight-list-item-${highlight.replace(
                          ' ',
                          '-',
                        )}`}
                      >
                        {highlight}
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          )}
        </div>
        {topic?.ctaButton && (
          <Button variant={topic?.variant} className="w-full mt-auto">
            {link && (
              <LocalizedLink
                language={locale}
                to={link}
                target={target}
                className={`block text-center cta_${topic?.title
                  .split(' ')
                  .join('-')}`}
              >
                {topic?.ctaButton.text}
                <ArrowForward className="ml-xxs" />
              </LocalizedLink>
            )}
          </Button>
        )}
      </InfoBlockBody>
    </InfoBlockWrapper>
  );
};
export default InfoBlock;
