import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';

import {
  ContentfulComponentPlatformFeatureLarge,
  ContentfulComponentPlatformFeatureDouble,
  ContentfulComponentPlatformFeatureSingle,
  ContentfulComponentPlatformFeatureSet,
  ContentfulTopicProduct,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="font-light text-3xl mb-4">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const Container = styled.div<{ wider?: boolean; textBlack?: boolean }>`
  .custom-description-styling {
    text-align: center;
    max-width: ${(props) => (props?.wider ? '650px' : '450px')};
    margin: 0 auto;
  }
  .isNotLight h2,
  .isNotLight h3,
  .text-blueDark h2 {
    color: ${(props) => props.theme.color.primaryMain};
  }
  .isLight p a {
    opacity: 0.8;
    text-decoration: underline;
  }
  .isLight p a:hover,
  .isLight p a:focus {
    opacity: 1;
    color: #fff;
  }
  .isNotLight a {
    opacity: 1;
    color: ${(props) => props.theme.color.primaryMain};
  }
  .isNotLight a:hover,
  .isNotLight a:focus {
    opacity: 1;
    color: ${(props) => props.theme.color.primaryDark};
  }
  .isNotLight h2 {
    color: ${(props) =>
      props?.textBlack ? '#000' : props.theme.color.primaryMain};
  }
`;
const HeadingContainer = styled.div`
  max-width: 650px;
`;

interface PlatformFeatureSetProps {
  data?: ContentfulComponentPlatformFeatureSet;
  className?: string;
}
const PlatformFeatureSet: React.FC<PlatformFeatureSetProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const headerTextColor =
    data?.textColour === 'Light'
      ? 'text-white'
      : data?.textColour === 'Blue-Dark'
      ? 'text-blueDark'
      : '';

  const isLight = data?.textColour === 'Light' ? 'isLight' : 'isNotLight';
  const headerColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-mainPrimary';

  let id = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
  if (data?.title) {
    const titleHasSpace = /\s/.test(data?.title);
    if (titleHasSpace) {
      id = data?.title.substring(0, data?.title.indexOf(' ')).toLowerCase();
    } else {
      id = data?.title.toLowerCase();
    }
  }

  let indexTotal = 0;

  return (
    <Container
      wider={Boolean(data?.wider)}
      textBlack={Boolean(data?.textBlack)}
      className="mt-sm"
      id={id}
    >
      <div className="container max-w-6xl mx-auto">
        {data?.title && data?.subheading && (
          <HeadingContainer
            className={`text-center mx-auto ${headerTextColor}`}
          >
            {data?.title && (
              <Typography as="h2" variant="h2" className={headerColor}>
                {data?.title}
              </Typography>
            )}
            {data?.subheading && (
              <Typography as="p" variant="body" className="mt-xs">
                {data?.subheading}
              </Typography>
            )}
          </HeadingContainer>
        )}
      </div>
      {data?.platformFeatures?.length > 0 && (
        <div className="container">
          <ul className="mt-sm">
            {data?.platformFeatures?.map((platformFeature, index) => {
              let featuredImage;
              indexTotal = index;
              if (index > 0) {
                if (
                  data?.platformFeatures[index - 1].internal.type ===
                  'ContentfulComponentPlatformFeatureSingle'
                ) {
                  indexTotal = 0;
                }
              }
              const isEvenItem = indexTotal % 2 === 0;

              // set the image
              if (platformFeature?.featureImage) {
                featuredImage = platformFeature?.featureImage?.gatsbyImageData;
              }

              if (
                platformFeature.internal.type ===
                'ContentfulComponentPlatformFeatureDouble'
              ) {
                // USES PlatformFeatureDouble:

                const platformFeatureItem: ContentfulComponentPlatformFeatureDouble =
                  platformFeature;

                return (
                  <li
                    key={`platform-feature-list-item-${platformFeature.id}`}
                    className="mt-sm md:mt-xl first:mt-0"
                  >
                    <div className="md:grid gap-x-sm grid-flow-col-dense grid-cols-12">
                      <div
                        className={`col-span-7 flex ${
                          isEvenItem
                            ? 'justify-start'
                            : 'col-start-6 justify-end'
                        }`}
                      >
                        {featuredImage && (
                          <GatsbyImage
                            image={getImage(featuredImage)}
                            objectFit="contain"
                            style={{ maxWidth: '584px', margin: '0 auto' }}
                            alt={featuredImage?.description || ''}
                          />
                        )}
                      </div>
                      <div
                        className={`col-span-5 mt-sm md:mt-0 ${
                          isEvenItem ? '' : ''
                        }`}
                      >
                        <div className="flex flex-col h-full justify-center">
                          {platformFeatureItem?.features?.map(
                            (platformFeatureFeature) => {
                              return (
                                <div
                                  className="mb-sm"
                                  key={`platform-feature-item-${platformFeatureFeature.id}`}
                                >
                                  {platformFeatureFeature?.title && (
                                    <Typography
                                      as="h2"
                                      variant="h3"
                                      className={`mt-xxs text-center md:text-left ${headerColor}`}
                                    >
                                      {platformFeatureFeature?.title}
                                    </Typography>
                                  )}
                                  {platformFeatureFeature?.shortDescription
                                    ?.shortDescription && (
                                    <Typography
                                      as="p"
                                      variant="body"
                                      className={`mt-xs text-center md:text-left ${textColor}`}
                                    >
                                      {
                                        platformFeatureFeature?.shortDescription
                                          ?.shortDescription
                                      }
                                    </Typography>
                                  )}
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
              if (
                platformFeature.internal.type ===
                'ContentfulComponentPlatformFeatureSingle'
              ) {
                const platformFeatureItem: ContentfulComponentPlatformFeatureSingle =
                  platformFeature;
                const ctaButton = platformFeatureItem?.ctaButton;
                let link;
                if (ctaButton?.link?.url) {
                  link = ctaButton?.link?.url;
                } else if (ctaButton?.link?.page) {
                  link = getFullLink(ctaButton?.link?.page, locale);
                }
                const btnVariant =
                  data?.textColour === 'Light'
                    ? 'Outline-Light'
                    : ctaButton?.variant;

                // override image if necessary
                // if (!featuredImage && topic?.featureImage) {
                //   featuredImage = topic?.featureImage?.gatsbyImageData;
                // }

                let hasRichText = false;
                if (platformFeatureItem?.richText?.raw) {
                  hasRichText = true;
                }

                // columns alternate every row
                return (
                  <li
                    key={`platform-feature-list-item-${platformFeature.id}`}
                    className="mt-sm md:mt-xl first:mt-0"
                  >
                    <div className="md:grid gap-x-sm grid-cols-12">
                      <div
                        className={`col-span-12 mt-sm md:mt-0 custom-description-styling`}
                      >
                        <div className="flex flex-col h-full justify-center">
                          {hasRichText ? (
                            <div className={`${textColor} ${isLight}`}>
                              {platformFeatureItem?.name && (
                                <Typography
                                  as="h3"
                                  variant="h3"
                                  className={`mt-0 mb-xs text-center ${headerColor}`}
                                >
                                  {platformFeatureItem?.name}
                                </Typography>
                              )}
                              {renderRichText(
                                platformFeatureItem?.richText,
                                options,
                              )}
                            </div>
                          ) : (
                            <div className={isLight}></div>
                          )}
                          {ctaButton && (
                            <div className="flex justify-center md:justify-center">
                              <Button variant={btnVariant} className="mt-xs">
                                {ctaButton?.link && (
                                  <LocalizedLink
                                    language={locale}
                                    to={
                                      ctaButton.link?.url ||
                                      getFullLink(ctaButton.link?.page, locale)
                                    }
                                    className={``}
                                  >
                                    {ctaButton.text}
                                    <ArrowForward className="ml-xxs" />
                                  </LocalizedLink>
                                )}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={`col-span-12 flex mt-sm`}>
                        {featuredImage && (
                          <GatsbyImage
                            image={getImage(featuredImage)}
                            objectFit="contain"
                            style={{ maxWidth: '1024px', margin: '0 auto' }}
                            alt={featuredImage?.description || ''}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                );
              } else if (
                platformFeature.internal.type ===
                'ContentfulComponentPlatformFeatureLarge'
              ) {
                // AS IT WAS, USES PlatformFeatureLarge
                const platformFeatureItem: ContentfulComponentPlatformFeatureLarge =
                  platformFeature;
                let imagePosition = '';
                if (platformFeatureItem?.imagePosition === 'Right') {
                  imagePosition = 'lg:order-last';
                }
                const topic: ContentfulTopicProduct =
                  platformFeatureItem?.topic;
                const ctaButton = platformFeatureItem?.ctaButton;
                let link;
                if (ctaButton?.link?.url) {
                  link = ctaButton?.link?.url;
                } else if (ctaButton?.link?.page) {
                  link = getFullLink(ctaButton?.link?.page, locale);
                }
                const btnVariant =
                  data?.textColour === 'Light'
                    ? 'Outline-Light'
                    : ctaButton?.variant;

                // override image if necessary
                if (!featuredImage && topic?.featureImage) {
                  featuredImage = topic?.featureImage?.gatsbyImageData;
                }

                let hasRichText = false;
                if (platformFeatureItem?.richText?.raw) {
                  hasRichText = true;
                }

                // columns alternate every row
                return (
                  <li
                    key={`platform-feature-list-item-${platformFeature.id}`}
                    className="mt-sm md:mt-xl first:mt-0"
                  >
                    <div className="md:grid gap-x-sm grid-flow-col-dense grid-cols-12">
                      <div
                        className={`col-span-7 flex ${imagePosition} ${
                          isEvenItem
                            ? 'justify-start'
                            : 'col-start-6 justify-end'
                        }`}
                      >
                        {featuredImage && (
                          <GatsbyImage
                            image={getImage(featuredImage)}
                            objectFit="contain"
                            style={{ maxWidth: '584px', margin: '0 auto' }}
                            alt={featuredImage?.description || ''}
                          />
                        )}
                      </div>
                      <div
                        className={`col-span-5 mt-sm md:mt-0 ${
                          isEvenItem ? '' : ''
                        }`}
                      >
                        <div className="flex flex-col h-full justify-center">
                          {hasRichText ? (
                            <div className={`${textColor} ${isLight}`}>
                              {renderRichText(
                                platformFeatureItem?.richText,
                                options,
                              )}
                            </div>
                          ) : (
                            <div className={isLight}>
                              {topic?.productMarket?.title && (
                                <Typography
                                  as="span"
                                  variant="body"
                                  className="uppercase text-center md:text-left"
                                >
                                  {topic?.productMarket?.title}
                                </Typography>
                              )}
                              {topic?.title && (
                                <Typography
                                  as="h3"
                                  variant="h3"
                                  className={`mt-0 mb-xs text-center md:text-left ${headerColor}`}
                                >
                                  {topic?.title}
                                </Typography>
                              )}
                              {topic?.subheading && (
                                <Typography
                                  as="p"
                                  variant="body"
                                  className={`my-xs text-center md:text-left ${textColor}`}
                                >
                                  {topic?.subheading}
                                </Typography>
                              )}
                              {topic?.shortDescription?.shortDescription && (
                                <Typography
                                  as="p"
                                  variant="body"
                                  className={`my-xs mb-sm text-center md:text-left ${textColor}`}
                                >
                                  {topic?.shortDescription?.shortDescription}
                                </Typography>
                              )}
                              {topic?.highlights &&
                                topic?.highlights.length > 0 && (
                                  <div>
                                    <ul className="mt-0">
                                      {topic?.highlights?.map((highlight) => {
                                        return (
                                          <li
                                            key={`highlight-list-item-${highlight.replace(
                                              ' ',
                                              '-',
                                            )}`}
                                            className={`flex items-center mt-xs first:mt-0 ${textColor}`}
                                          >
                                            <span>
                                              <CheckCircleOutline className="text-primaryMain" />
                                            </span>
                                            <Typography
                                              variant="body"
                                              className="ml-xs"
                                            >
                                              {highlight}
                                            </Typography>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          )}
                          {ctaButton && (
                            <div className="flex justify-center md:justify-start">
                              <Button variant={btnVariant} className="mt-sm">
                                {ctaButton?.link && (
                                  <LocalizedLink
                                    language={locale}
                                    to={
                                      ctaButton.link?.url ||
                                      getFullLink(ctaButton.link?.page, locale)
                                    }
                                    className={`cta_${topic?.title
                                      ?.split(' ')
                                      ?.join('-')}`}
                                  >
                                    {ctaButton.text}
                                    <ArrowForward className="ml-xxs" />
                                  </LocalizedLink>
                                )}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      )}
    </Container>
  );
};
export default PlatformFeatureSet;
