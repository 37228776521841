import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { CookiesProvider } from 'react-cookie';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
} from '@/../graphql-types';
import { theme } from '@/theme/mainTheme';
import {
  SiteHeader as Header,
  SiteFooter as Footer,
} from '@/components/contentful/components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: white;
    color: black;
    font-family: ${(props) => props.theme.fontFamily.arboria};
    font-size: 16px;
    margin: 0;
  }

  a {
    &:hover, &:focus, &:active {
      color: ${(props) => props.theme.color.primaryMain};
      text-decoration: underline;
    }
  }
`;

/** Used to override Material UI theme colours with Miovision colours  */
const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.primaryMain,
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        'background-color': 'transparent',
        'border-width': '1px',
        'border-bottom-color': theme.color.primaryMain,
        'border-bottom-width': '2px',
      },
      underline: {
        '&::after': {
          'border-bottom': '0',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        'border-color': theme.color.primaryMain,
        'border-width': '2px',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'start',
        '& .MuiTypography-root': {
          fontFamily: theme.fontFamily.arboria,
          fontSize: theme.fontSize.caption,
          fontWeight: theme.fontWeight.book,
          lineHeight: '20px',
          paddingTop: '9px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: theme.fontFamily.arboria,
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&.Mui-checked': {
          color: theme.color.primaryMain,
        },
      },
    },
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'mio_',
});

interface LayoutProps {
  location: Location;
  children?: React.ReactNode;
  header?: ContentfulComponentSiteHeader;
  page?: ContentfulComposePage;
  footer?: ContentfulComponentSiteFooter;
}
const Layout: React.FC<LayoutProps> = (props) => {
  const { location, children, header, footer, page } = props;

  const today = new Date();
  let expires, redirectionDestination;
  if (page?.expires) {
    expires = new Date(page.expires);
  }
  if (page?.redirectionDestination) {
    redirectionDestination = page.redirectionDestination;
  }

  if (expires < today && redirectionDestination) {
    useEffect(() => {
      const url = new URL(location?.href);
      const queryString = url?.search;
      navigate(redirectionDestination + queryString);
    }, []);

    return null;
  } else {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={muiTheme}>
              <GlobalStyle />
              {header && <Header location={location} data={header} />}
              {children}
              {footer && <Footer location={location} data={footer} />}
            </MuiThemeProvider>
          </ThemeProvider>
        </CookiesProvider>
      </StylesProvider>
    );
  }
};
export default Layout;
