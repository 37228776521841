import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ChevronRight } from '@material-ui/icons';

import Typography from '@/components/layout/Typography';
import { getFullLinkByContent } from '@/utils/helpers';
import * as Constants from '@/types/Constants';

const Container = styled.div``;

const ImageContainer = styled.div`
  height: 280px;
  transition: box-shadow 0.1s ease-in;
`;

const IconContainer = styled.span`
  opacity: 0;
  transition: opacity 0.1s ease-in;
`;

const CardContainer = styled.div`
  &:hover {
    ${ImageContainer} {
      box-shadow: ${(props) => props.theme.boxShadow[48]} !important;
    }
    ${IconContainer} {
      opacity: 1 !important;
    }
  }
`;

interface ResourceCollectionProps {
  collection?: any;
  className?: string;
}
const ResourceCollection: React.FC<ResourceCollectionProps> = (props) => {
  const { collection } = props;
  const { locale } = useLocalization();

  return (
    <Container className="mb-md">
      <div className="container md:flex gap-x-md">
        {collection ? (
          <>
            <div className="w-1/4 flex-shrink-0 border-r border-gray2 h-72">
              <Typography
                as="h2"
                variant="body"
                className="uppercase font-bold mb-xs"
              >
                Filter by Product
              </Typography>
            </div>
            <div className="md:grid grid-cols-12 md:gap-sm xl:gap-md">
              {collection.edges.map((resource) => {
                let resourceType,
                  id,
                  slug,
                  topic,
                  page,
                  image,
                  alt,
                  title,
                  description;

                if (resource.node.eventDetails) {
                  // EVENTS
                  resourceType = 'Event';
                  id = resource.node.eventDetails.id;
                  slug =
                    resource.node.eventDetails.page__event?.[0]
                      ?.compose__page?.[0]?.slug;
                  page = getFullLinkByContent(
                    slug,
                    Constants.PAGE_EVENT,
                    locale,
                  );
                  image =
                    resource.node.eventDetails.featureImage?.gatsbyImageData;
                  title = resource.node.eventDetails.title;
                  description =
                    resource.node.eventDetails.shortDescription
                      ?.shortDescription;
                } else if (resource.node.caseStudy) {
                  // CASE STUDIES
                  resourceType = 'Case Study';
                  id = resource.node.caseStudy.id;
                  slug =
                    resource.node.caseStudy.page__case_study?.[0]
                      ?.compose__page?.[0]?.slug;
                  page = getFullLinkByContent(
                    slug,
                    Constants.PAGE_CASE_STUDY,
                    locale,
                  );
                  image = resource.node.caseStudy.featureImage?.gatsbyImageData;
                  title = resource.node.caseStudy.title;
                  description =
                    resource.node.caseStudy.shortDescription?.shortDescription;
                } else if (resource.node.customerStory) {
                  // CUSTOMER STORIES
                  resourceType = 'Customer Story';
                  id = resource.node.customerStory.id;
                  slug =
                    resource.node.customerStory.page__customer_story?.[0]
                      ?.compose__page?.[0]?.slug;
                  page = getFullLinkByContent(
                    slug,
                    Constants.PAGE_CUSTOMER_STORY,
                    locale,
                  );
                  image =
                    resource.node.customerStory.featureImage?.gatsbyImageData;
                  title = resource.node.customerStory.title;
                  description =
                    resource.node.customerStory.shortDescription
                      ?.shortDescription;
                } else if (resource.node.webinar) {
                  // WEBINARS
                  resourceType = 'Webinar';
                  id = resource.node.webinar.id;
                  slug =
                    resource.node.webinar.page__webinar?.[0]?.compose__page?.[0]
                      ?.slug;
                  page = getFullLinkByContent(
                    slug,
                    Constants.PAGE_WEBINAR,
                    locale,
                  );
                  image = resource.node.webinar.featureImage?.gatsbyImageData;
                  title = resource.node.webinar.title;
                  description =
                    resource.node.webinar.shortDescription?.shortDescription;
                } else {
                  resourceType = null;
                  title = 'unknown';
                }

                return (
                  <CardContainer
                    key={`resource-item-${id}`}
                    className="col-span-12 md:col-span-6 xxl:col-span-4 mt-md first:mt-0 md:mt-0"
                  >
                    {page && (
                      <LocalizedLink
                        language={locale}
                        to={page}
                        className="flex flex-col no-underline text-current"
                      >
                        <ImageContainer className="rounded shadow-50 overflow-hidden">
                          <GatsbyImage
                            image={getImage(image)}
                            alt={alt || ``}
                            className="h-full w-full"
                          />
                        </ImageContainer>
                        {resourceType && (
                          <div className="mt-xs">
                            <Typography variant="caption" className="uppercase">
                              {resourceType}
                            </Typography>
                          </div>
                        )}
                        {title && (
                          <div className="mt-xs">
                            <Typography
                              variant="h5"
                              className="text-primaryMain"
                            >
                              <span>{title}</span>
                              <IconContainer>
                                <ChevronRight />
                              </IconContainer>
                            </Typography>
                          </div>
                        )}
                        {description && (
                          <div className="mt-xs">
                            <Typography variant="body">
                              {description}
                            </Typography>
                          </div>
                        )}
                      </LocalizedLink>
                    )}
                  </CardContainer>
                );
              })}
              {/* {collection?.edges.map((resource) => {
                console.log(resource);
                let title = 'title!';

                return { title };
              })} */}
            </div>
          </>
        ) : (
          <div className="w-full text-center">Sorry. Nothing to see here.</div>
        )}
      </div>
    </Container>
  );
};
export default ResourceCollection;
