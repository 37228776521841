import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const WidgetWrapper = styled.div`
    border-radius: 5px;
    height: 24px; 
    overflow: hidden;
    filter: grayscale(100%);
  }
`;

function LinkedInWidget() {
  const divRef = useRef();
  const htmlString = `
    <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
    <script type="IN/FollowCompany" data-id="145267" data-counter="bottom"></script>
  `;

  useEffect(() => {
    const fragment = document
      .createRange()
      .createContextualFragment(htmlString);
    divRef?.current?.append(fragment);
  }, []);

  return <WidgetWrapper ref={divRef} />;
}

export default LinkedInWidget;
