import React from 'react';
import styled from 'styled-components';

import { ContentfulComponentVideo } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

interface VideoProps {
  data?: ContentfulComponentVideo;
  className?: string;
}
const Video: React.FC<VideoProps> = (props) => {
  const { data } = props;
  const autoPlay = data.autoPlay
    ? data.autoPlay === true
      ? true
      : false
    : false;
  const muted = data.muted ? (data.muted === true ? true : false) : false;
  const loop = data.runOnLoop
    ? data.runOnLoop === true
      ? true
      : false
    : false;
  const controls = data.hideControls
    ? data.hideControls === true
      ? false
      : true
    : true;
  return (
    <Container className="mb-lg">
      <div className="container mx-auto text-center">
        {data?.mediaFile?.file?.url && (
          <video
            className="rounded-2xl w-full"
            autoPlay={autoPlay}
            muted={muted}
            controls={controls}
            loop={loop}
          >
            <source src={data.mediaFile.file.url} type="video/mp4" />
            <p>
              Your browser does not support HTML video. Here is a
              <a href={data.mediaFile.file.url}>link to the video</a> instead.
            </p>
          </video>
        )}
      </div>
    </Container>
  );
};

export default Video;
