import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';

import {
  ContentfulComponentPlatformFeatureCards,
  ContentfulTopicProductFeature,
  ContentfulTopicCaseStudy,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getFullLink, getFullLinkByContent } from '@/utils/helpers';
import * as Constants from '@/types/Constants';

const Container = styled.div``;

const CardBase = styled.div`
  position: relative;
  transition: box-shadow 0.1s ease-in;
  color: #fff;
  &:hover {
    box-shadow: ${(props) => props.theme.boxShadow[48]} !important;
  }
  a {
    min-height: 250px;
  }
`;

const Card = styled(CardBase)<{ color: string }>`
  ${(props) => {
    const bgBlue = props.theme.backgroundGradient.blue;
    const bgBlueHover = props.theme.backgroundGradient.blueHover;
    const bgGreen = props.theme.backgroundGradient.green;
    const bgGreenHover = props.theme.backgroundGradient.greenHover;
    const bgYellow = props.theme.backgroundGradient.yellow;
    const bgYellowHover = props.theme.backgroundGradient.yellowHover;
    if (props.color === 'Green') {
      return `
       background-image: ${bgGreen};
       &:hover {
        background-image: ${bgGreenHover};
      }
      `;
    } else if (props.color === 'Yellow') {
      return ` 
        color: #000;
        background-image: ${bgYellow};
        &:hover {
          background-image: ${bgYellowHover};
        }
      `;
    } else {
      return `
        background-image: ${bgBlue};
        &:hover {
          background-image: ${bgBlueHover};
        }
      `;
    }
  }}
`;

const CardIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 24px;
  z-index: 0;
  width: 56px;
`;

interface PlatformFeatureCardsProps {
  data?: ContentfulComponentPlatformFeatureCards;
  className?: string;
}
const PlatformFeatureCards: React.FC<PlatformFeatureCardsProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  const ctaLink =
    data?.ctaButton?.link?.url ||
    getFullLink(data?.ctaButton?.link?.page, locale);
  const btnVariant =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton?.variant;
  let target;
  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';

  return (
    <Container className="mt-lg">
      <div className="container">
        <div className="max-w-4xl mx-auto mb-0 lg:mb-md">
          {data?.topic.title && (
            <Typography
              as="h2"
              variant="h2"
              className={`text-center mb-sm ${textColor}`}
            >
              {data?.topic.title}
            </Typography>
          )}
          {data?.topic.shortDescription?.shortDescription && (
            <Typography
              as="p"
              variant="body"
              className={`text-center ${textColor}`}
            >
              {data?.topic.shortDescription?.shortDescription}
            </Typography>
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-lg mt-0 lg:my-sm max-w-5xl mx-auto">
          {data?.topic?.highlights && data?.topic?.highlights.length > 0 && (
            <div>
              <ul className={`mt-sm mb-md ${textColor}`}>
                {data.topic?.highlights?.map((highlight, index) => {
                  return (
                    <li
                      key={`highlight-list-item-${highlight.replace(
                        ' ',
                        '-',
                      )}-${index}`}
                      className="flex mt-xs first:mt-0"
                    >
                      <span>
                        <CheckCircleOutline className="" />
                      </span>
                      <Typography variant="body" className="ml-xs">
                        {highlight}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
              {data?.ctaButton && ctaLink && (
                <div className="flex justify-center md:justify-start">
                  <Button variant={btnVariant} className="mt-sm">
                    {ctaLink && (
                      <LocalizedLink
                        language={locale}
                        to={ctaLink}
                        target={target}
                        className={`cta_${data?.topic?.title
                          .split(' ')
                          .join('-')}`}
                      >
                        {data?.ctaButton.text}
                        <ArrowForward className="ml-xxs" />
                      </LocalizedLink>
                    )}
                  </Button>
                </div>
              )}
            </div>
          )}
          <div>
            {data.platformCards?.map((card) => {
              let page, title, product, newWindow;

              const icon = card?.backgroundImage?.gatsbyImageData;
              const color = card?.backgroundColor;

              // type cards in order to find correct fields
              switch (card?.topic?.internal?.type) {
                case Constants.TOPIC_PRODUCT_FEATURE:
                  // eslint-disable-next-line no-case-declarations
                  const productFeature =
                    card.topic as ContentfulTopicProductFeature;

                  // eslint-disable-next-line no-case-declarations
                  const productSlug =
                    productFeature?.product?.page__product?.find(
                      (productPage) => productPage.compose__page !== null,
                    ).compose__page?.[0]?.slug;

                  // handle possibility of null compose__page, set slug for links
                  productFeature.page__product_feature?.forEach((element) => {
                    if (element.compose__page) {
                      page = `/${productSlug}/${element.compose__page?.[0].slug}`;
                    }
                  });

                  product = productFeature.product?.title.replace(
                    '®',
                    '<sup>&reg;</sup>',
                  );

                  break;
                case Constants.TOPIC_CASE_STUDY:
                  // eslint-disable-next-line no-case-declarations
                  const caseStudy = card.topic as ContentfulTopicCaseStudy;

                  caseStudy.page__case_study?.forEach((element) => {
                    if (element.compose__page) {
                      page = getFullLinkByContent(
                        element.compose__page?.[0]?.slug,
                        Constants.PAGE_CASE_STUDY,
                        locale,
                      );
                    }
                  });
                  break;
              }

              title = card?.topic?.title;
              if (card?.title) {
                title = card?.title;
              }
              if (card?.label) {
                product = card?.label;
              }
              if (card?.link?.url) {
                page = card?.link?.url;
              }
              if (card?.link?.openInNewWindow) {
                newWindow = '_blank';
              }

              return (
                <Card
                  key={`platform-${title}`}
                  className="rounded shadow-30 overflow-hidden mb-sm"
                  color={`${color}`}
                >
                  <CardIcon>
                    <GatsbyImage image={getImage(icon)} alt="" />
                  </CardIcon>

                  {page && (
                    <LocalizedLink
                      language={locale}
                      to={page}
                      className="h-full block relative z-10 p-edge text-current no-underline"
                      target={newWindow}
                    >
                      <span
                        className="uppercase font-light text-xs tracking-wide"
                        dangerouslySetInnerHTML={{ __html: product }}
                      />
                      <Typography
                        as="h3"
                        variant="h3"
                        className="leading-snug font-normal"
                      >
                        {title}
                      </Typography>
                    </LocalizedLink>
                  )}
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default PlatformFeatureCards;
