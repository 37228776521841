import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ContentfulComponentStatsCloud } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="font-light text-3xl mb-4">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0.5em;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [INLINES.HYPERLINK]: (node) => {
      return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a href={node?.data?.target?.file?.url} rel="noreferrer">
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const Container = styled.div``;

const StatWrapper = styled.div`
  height: 170px;
  width: 170px;
  padding: 4px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 100%;
  margin-bottom: ${({ theme }) => theme.gap.xs};
  & .statInner {
    padding: 1.5em;
  }
  & .statInner .statInnerNumber {
    font-size: 2.25rem;
    line-height: 1.25;
  }
  &.stat_0 {
    width: 260px;
    height: 260px;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
  &.stat_0 .statInner {
    font-size: 1.2rem;
  }
  &.stat_0 .statInner .statInnerNumber {
    font-size: 4.5rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    &.stat_0 {
      width: 300px;
      height: 300px;
    }
    &.stat_0 .statInner .statInnerNumber {
      font-size: 5.25rem;
    }
  }
  &.stat_1 {
    width: 220px;
    height: 220px;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
  &.stat_1 .statInner .statInnerNumber {
    font-size: 3.5rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    &.stat_1 {
      width: 240px;
      height: 240px;
    }
    &.stat_1 .statInner .statInnerNumber {
      font-size: 4rem;
    }
  }
  &.showLine:before {
    content: '';
    width: 1px;
    height: 60px;
    border-left: 1px solid ${({ theme }) => theme.color.grey2};
    position: absolute;
    bottom: -25px;
    left: calc(50% + 1px);
  }
  &.showLine.stat_2:before {
    height: 40px;
    bottom: -15px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 300px;
    height: 300px;
    &.stat_0,
    &.stat_1 {
      width: 300px;
      height: 300px;
    }
    &.stat_0 .statInner .statInnerNumber,
    &.stat_1 .statInner .statInnerNumber,
    &.stat_2 .statInner .statInnerNumber {
      font-size: 3.5rem;
    }
  }
`;
const StatInner = styled.div`
  width: 95%;
  height: 95%;
  margin-left: 2.5%;
  margin-top: 2.5%;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

interface StatsCloudProps {
  data?: ContentfulComponentStatsCloud;
  className?: string;
}
const StatsCloud: React.FC<StatsCloudProps> = (props) => {
  const { data } = props;

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const titleColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';

  const bubbleInnerColor =
    data?.textColour === 'Light' ? 'bg-black3' : 'bg-white';

  let bubbleGradient = 'bg-yellow';
  let bubbleTextColor = 'text-yellowMain';

  if (data.gradientColor === 'Blue') {
    bubbleGradient = 'bg-blue';
    bubbleTextColor = 'text-primaryMain';
  }

  let layout = 'Classic';
  if (data?.layout) {
    layout = data?.layout;
  }

  if (layout === 'Enhanced') {
    return (
      <Container className="">
        <div className="max-w-7xl mx-auto">
          <div className="lg:grid lg:grid-cols-2 items-center">
            <div className="md:grid md:grid-rows-3 grid-flow-col">
              {data?.stats?.map(({ title, subTitle, subheading }, index) => {
                const statCount = 'stat_' + index;
                let gridPlacement;
                if (index === 0) {
                  gridPlacement = 'row-span-2 col-span-2';
                } else if (index === 1) {
                  gridPlacement = 'row-start-2 row-span-2 col-span-1';
                } else if (index === 2) {
                  gridPlacement = 'row-start-3 col-start-2 ol-span-1';
                }
                return (
                  <Fragment key={statCount}>
                    <div
                      className={`w-full text-center mb-sm ${textColor} ${gridPlacement}`}
                    >
                      <StatWrapper
                        className={`${statCount} ${bubbleGradient} mx-auto ${
                          subheading ? 'showLine' : 'noLine'
                        }`}
                        data-sal="slide-up"
                        data-sal-duration="600"
                        data-sal-easing="ease-out-expo"
                      >
                        <StatInner className={`statInner ${bubbleInnerColor}`}>
                          <span
                            className={`statInnerNumber ${bubbleTextColor}`}
                          >
                            {title}
                          </span>
                          {subTitle && (
                            <span className={`leading-tight ${textColor}`}>
                              {subTitle}
                            </span>
                          )}
                        </StatInner>
                      </StatWrapper>
                      {subheading && (
                        <p
                          className="text-xl max-w-[300px] md:max-w-[220px] mx-auto"
                          data-sal="slide-up"
                          data-sal-duration="800"
                          data-sal-easing="ease-out-expo"
                        >
                          {subheading}
                        </p>
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </div>
            <div className={`px-sm ${textColor}`}>
              {data?.title && (
                <Typography
                  as="h2"
                  variant="h2"
                  className={`mb-sm ${titleColor}`}
                >
                  {data?.title}
                </Typography>
              )}
              {data?.subheading && (
                <Typography as="p" variant="body" className="">
                  {data?.subheading}
                </Typography>
              )}
              {data?.content?.raw && (
                <>
                  {renderRichText(
                    data?.content as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    options,
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    );
  } else {
    return (
      <Container className="">
        <div className="container">
          <div className="max-w-2xl mx-auto mb-md">
            {data?.title && (
              <Typography
                as="h2"
                variant="h2"
                className="text-center text-primaryMain mb-3"
              >
                {data?.title}
              </Typography>
            )}
            {data?.subheading && (
              <Typography as="p" variant="body" className="text-center">
                {data?.subheading}
              </Typography>
            )}
          </div>
          <div className="flex flex-wrap items-center justify-center max-w-4xlx mx-auto">
            {data?.stats?.map(({ title, subTitle, subheading }) => {
              return (
                <Fragment key={`stat-${title}`}>
                  <div className="w-full md:w-1/2 lg:w-1/4 xl:w-1/6 text-center mb-sm">
                    <StatWrapper className={`${bubbleGradient} mx-auto mb-xs`}>
                      <StatInner className={`statInner ${bubbleInnerColor}`}>
                        <span
                          className={`${bubbleTextColor} font-bold text-5xl`}
                        >
                          {title}
                        </span>
                        {subTitle && <span className="">{subTitle}</span>}
                      </StatInner>
                    </StatWrapper>
                    {subheading && <p className="text-xl">{subheading}</p>}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </Container>
    );
  }
};
export default StatsCloud;
