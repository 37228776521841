import React from 'react';
import styled from 'styled-components';

import { ContentfulComponentBackground } from '@/../graphql-types';
import ContentfulImage from '@/components/ContentfulImage';

const Container = styled.div<{
  gradient: boolean;
  gradientColor: string;
  lineVariant: string;
  customSpacing?: string;
}>`
  position: relative;
  margin: ${(props) => props.theme.spacing.sm} auto;
  max-width: ${(props) => props.theme.container.super};

  ${(props) => {
    // conditional padding if there is a gradient
    if (props.gradient && props.lineVariant === 'Bottom-right') {
      return `
        padding-bottom: ${props.theme.spacing.lg};
        margin-top: ${props.theme.spacing.sm};
      
        @media (min-width: ${props.theme.breakpoint.md}) {
          padding-bottom: calc(${props.theme.spacing.xxl} + 120px);
          margin-top: ${props.theme.spacing.md};
        }
   
        /* Small padding-top ensures child component margin don't collapse */
        padding-top: 0.1px;
      `;
    } else if (props.gradient) {
      if (props.gradientColor === 'Grey Reversed') {
        return `
        margin-top: ${props.theme.spacing.lg};
        /* Small padding-top ensures child component margin don't collapse */
        padding-top: 0.1px;
      `;
      } else if (props.gradientColor === 'Grey') {
        return `
        padding-bottom: ${props.theme.spacing.lg};
        /* Small padding-top ensures child component margin don't collapse */
        padding-top: 0.1px;
      `;
      } else {
        return `
          /* margin-top forces skewed gradients to fall back into the proper
          * space of the container, where padding-bottom allows the background to
          * be shown after the content. */
          padding-bottom: ${props.theme.spacing.md};
          margin-top: ${props.theme.spacing.md};
        
          @media (min-width: ${props.theme.breakpoint.md}) {
            padding-bottom: ${props.theme.spacing.xxl};
            margin-top: ${props.theme.spacing.xxl};
          }
        
          /* Small padding-top ensures child component margin don't collapse */
          padding-top: 0.1px;
        `;
      }
    }

    // conditional padding if there is a position background image
    if (props.lineVariant === 'Top') {
      return `
      /* line work only shows up on desktop screens */
     @media (min-width: ${props.theme.breakpoint.md}) {
       margin-top: ${props.theme.spacing.xxl};
     }
   
     /* Small padding-top ensures child component margin don't collapse */
     padding-top: 0.1px;
      `;
    }
    if (props.lineVariant === 'Bottom') {
      return `
      /* line work only shows up on desktop screens */
      @media (min-width: ${props.theme.breakpoint.md}) {
      }
      `;
    }
  }}

  ${(props) => {
    if (props.customSpacing === 'Pull up - tight') {
      return `
        margin-top: 18px !important;
      `;
    }
  }}
`;
const ContentContainer = styled.div`
  position: relative;
  z-index: 3;
`;
const GradientContainer = styled.div<{
  gradient: string;
}>`
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 1;

  ${(props) => {
    switch (props.gradient) {
      case 'Black':
        return `background: ${props.theme.backgroundGradient.black};`;
      case 'Black Reversed':
        return `background: ${props.theme.backgroundGradient.blackReversed};`;
      case 'Grey':
        return `background: ${props.theme.backgroundGradient.grey};`;
      case 'Grey Reversed':
        return `background: ${props.theme.backgroundGradient.greyReversed};`;
    }
  }}
  overflow: hidden;
  transform: skewY(3.5deg);
  transform-origin: 100%;
`;
const BackgroundImageContainer = styled.div<{
  variant: string;
  gradient: boolean;
}>`
  position: absolute;
  width: 100%;
  z-index: 2;

  ${(props) => {
    switch (props.variant) {
      case 'Top':
        return `
          top: 0;
          left: 0;
          transform: translateY(-100%);
        `;
      case 'Bottom':
        return `
          bottom: 0px;
          left: 0;
          .bottom-line {
            bottom: -24px !important;
            @media (max-width: ${props.theme.breakpoint.sm}) {
              bottom: 0 !important;
            }
            @media (min-width: ${props.theme.breakpoint.xl}) {
              bottom: -74px !important;
            }
          }
        `;
      case 'Bottom-right':
        return `
          bottom: 0;
          right: 0;
        `;
      case 'Cover':
        return `
          bottom: 0;
          left: 0;
          height: 100%;
          padding-bottom: ${props.theme.spacing.lg};
          ${
            // gradient adds extra padding we need to reposition to
            props.gradient &&
            `
            top: calc(-1 * ${props.theme.spacing.md} / 2);
            @media (min-width: ${props.theme.breakpoint.md}) {
              top: calc(-1 * ${props.theme.spacing.xxl} / 2);
            }
          `
          }
        `;
      case 'Behind':
        return `
          bottom: 30px;
          left: 0;
          transform: translateY(-40px);
        `;
    }
  }}
`;
interface ContentfulImageProps {
  data?: ContentfulComponentBackground;
  [properties: string]: unknown;
}
/**
 * This component wraps around components that have a Background field.
 * The background can contain a gradient and/or an image for line work,
 * and also where to position the image. It adds enough marging and padding
 * around the component to account for the angled design elements.
 */
const BackgroundWrapper: React.FC<ContentfulImageProps> = (props) => {
  const { data, customSpacing, children, className, ...properties } = props;

  let hasLinesSpacing = '';
  if (data?.lineImage && data?.lineVariant === 'Bottom') {
    hasLinesSpacing = 'mb-xl';
  }

  return (
    <Container
      gradient={data?.gradient != null}
      gradientColor={data?.gradient}
      lineVariant={data?.lineVariant}
      customSpacing={customSpacing}
      {...properties}
      className={`mx-auto grid items-center ${hasLinesSpacing} ${
        className || ''
      }`}
    >
      {data?.gradient && <GradientContainer gradient={data?.gradient} />}
      {data?.lineImage && (
        <BackgroundImageContainer
          gradient={data?.gradient != null}
          variant={data?.lineVariant}
          className={`${
            properties.fullLines && 'h-full'
          } md:block flex items-center`}
        >
          <ContentfulImage
            image={data?.lineImage}
            alt=""
            className={`${
              properties.fullLines &&
              'absolute top-1/2 transform -translate-y-1/2'
            } ${
              data?.lineVariant === 'Cover' &&
              `absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-auto h-auto min-w-full min-h-full`
            } ${
              data?.lineVariant === 'Bottom' &&
              `absolute bottom-0 left-0 w-1/2 h-auto bottom-line`
            } ${
              data?.lineVariant === 'Bottom-right' &&
              `absolute bottom-0 right-0 w-1/2 h-auto bottom-line`
            }
          `}
          />
        </BackgroundImageContainer>
      )}
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
export default BackgroundWrapper;
