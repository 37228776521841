import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ArrowForward } from '@material-ui/icons';

import {
  ContentfulComponentBackground,
  ContentfulComponentFooterCta,
  ContentfulEntry,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import BackgroundWrapper from '@/components/BackgroundWrapper';
import { getFullLink } from '@/utils/helpers';

/** This styled-component ensures that the mobile background image size is
 * responsive until 640px, at which point we must fix the height
 * due to fixed max-width container.
 */
const Container = styled.div<{
  mobileAspectRatio: number;
  desktopAspectRatio: number;
  maxHeight: number;
}>``;

/** This styled-component ensures the content area is always within
 * the footer cta background image's box, so that internal content
 * can position itself correctly
 */
const FooterCtaContentArea = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
  }
  @media (min-width: ${(props) => props.theme.breakpoint.extra}) {
  }
`;
interface FooterCtaProps {
  data?: ContentfulComponentFooterCta;
  className?: string;
}

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const FooterCta: React.FC<FooterCtaProps> = (props) => {
  const { data, className } = props;
  const { locale } = useLocalization();
  const themeContext = React.useContext(ThemeContext);

  // if the component has a background, then wrap it with a background
  const wrapBackground = (data, element) => {
    if (data.background) {
      return (
        <BackgroundWrapper
          key={`background-component-${data.id}`}
          data={(data as BackgroundComponent)?.background}
          className="w-full flex justify-center"
          fullLines
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  const ctaLink =
    data?.ctaButton?.link?.url ||
    getFullLink(data?.ctaButton?.link?.page, locale);

  // determine how to size responsive background image
  const mobileAspectRatio =
    data?.mobileImage?.file?.details?.image?.height /
    data?.mobileImage?.file?.details?.image?.width;
  const desktopAspectRatio =
    data?.featureImage?.file?.details?.image?.height /
    data?.featureImage?.file?.details?.image?.width;
  const maxHeight =
    parseInt(themeContext.breakpoint.extra) * desktopAspectRatio;

  return (
    <Container
      mobileAspectRatio={mobileAspectRatio}
      desktopAspectRatio={desktopAspectRatio}
      maxHeight={maxHeight}
      className={`relative mx-auto mt-md flex flex-col items-center ${
        className || ``
      }`}
    >
      {data?.featureImage?.gatsbyImageData &&
        wrapBackground(
          data,
          <div
            className={data.variant === 'Full Width' ? `w-full` : `max-w-xl`}
          >
            <GatsbyImage
              image={getImage(data?.featureImage?.gatsbyImageData)}
              alt=""
              className="block mb-sm"
            />
          </div>,
        )}

      <FooterCtaContentArea className="mb-lg px-sm lg:px-0 text-center max-w-4xl mx-auto">
        <div className="">
          {data?.title && (
            <Typography as="h2" variant="h2" className="text-primaryMain">
              {data?.title}
            </Typography>
          )}
          {data?.subheading && (
            <Typography as="p" variant="body" className="mt-xs">
              {data?.subheading}
            </Typography>
          )}
          {data?.ctaButton && (
            <div className="mt-sm">
              <Button
                variant={data?.ctaButton?.variant}
                className="text-current"
              >
                {ctaLink && (
                  <LocalizedLink
                    language={locale}
                    to={ctaLink}
                    className="cta_footer"
                  >
                    <span>{data?.ctaButton?.text}</span>
                    <ArrowForward className="ml-xs" />
                  </LocalizedLink>
                )}
              </Button>
            </div>
          )}
          {data?.ctaButtons && (
            <div className="mt-sm gap-4 flex items-center justify-center flex-wrap">
              {data?.ctaButtons.map((button) => {
                const buttonLink =
                  button?.link?.url || getFullLink(button?.link?.page, locale);
                return (
                  <Button
                    key={button.id}
                    variant={button.variant}
                    className="text-current"
                  >
                    <LocalizedLink
                      language={locale}
                      to={buttonLink}
                      className="cta_footer"
                    >
                      <span>{button.text}</span>
                    </LocalizedLink>
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </FooterCtaContentArea>
    </Container>
  );
};
export default FooterCta;
