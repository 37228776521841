import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  ClickAwayListener,
  Popper,
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import {
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';

import {
  ContentfulComponentNavigation,
  ContentfulComponentNavItem,
  ContentfulTopicLink,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div``;
const NavigationDesktop = styled.nav`
  height: ${(props) => props.theme.header.desktopHeight};
`;
const NavigationMobile = styled.nav`
  height: ${(props) => props.theme.header.mobileHeight};
`;
const NavMenuItem = styled.li`
  cursor: pointer;
  position: relative;
  height: ${(props) => props.theme.header.mobileHeight};

  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    height: ${(props) => props.theme.header.desktopHeight};
  }

  &.is-popper-open {
    color: ${(props) => props.theme.color.primaryMain};
  }
  &.is-popper-open::after,
  &:hover::after {
    background-color: ${(props) => props.theme.color.primaryMain};
    bottom: 0;
    content: '';
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
  }
`;
interface SubNavigationProps {
  data: ContentfulComponentNavigation;
  location: Location;
  className?: string;
}
const SubNavigation: React.FC<SubNavigationProps> = (props) => {
  const { data, location, className } = props;
  const { locale } = useLocalization();
  const [mobileAccordions, setMobileAccordions] = React.useState({});
  const [popperElements, setPopperElements] = React.useState([]);

  const togglePopper = (event, id) => {
    const newDesktopPopperElements = [...popperElements];
    newDesktopPopperElements[id] = event.currentTarget;
    setPopperElements(newDesktopPopperElements);
  };

  const closePopper = (id) => {
    const newDesktopPopperElements = [...popperElements];
    newDesktopPopperElements[id] = null;
    setPopperElements(newDesktopPopperElements);
  };

  const toggleMobileAccordion = (event, id) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    // copy mobile accordion states
    const drawers = { ...mobileAccordions };
    // toggle accordion associated with id
    drawers[id] = !drawers[id];

    setMobileAccordions(drawers);
  };

  const buildDesktopNavItem = (item, id) => {
    // Top-level menu items can either be a direct link or contain
    // nested menus
    const hasMenus = item?.menus && item?.menus.length > 0;
    const isMultiMenu = item?.menus != null && item?.menus.length > 1;

    let menuItemElement;
    if (hasMenus) {
      menuItemElement = (
        <>
          <button
            type="button"
            aria-describedby={`menu-${item.text}`}
            onClick={(e) => togglePopper(e, id)}
            className="h-full flex items-center hover:text-primaryMain"
          >
            <Typography variant="subtitle">{item.text}</Typography>

            {popperElements[id] ? (
              <KeyboardArrowUp className="ml-1" />
            ) : (
              <KeyboardArrowDown className="ml-1" />
            )}
          </button>

          <Popper
            id={`menu-${item.id}`}
            anchorEl={popperElements[id]}
            open={Boolean(popperElements[id])}
            style={{
              zIndex: 9999,
              width: isMultiMenu ? '100%' : 'auto',
            }}
            popperOptions={{
              modifiers: {
                preventOverflow: {
                  padding: 0,
                },
              },
            }}
          >
            <ClickAwayListener onClickAway={() => closePopper(id)}>
              <div
                className={`bg-white shadow-top ${
                  isMultiMenu ? 'py-sm px-lg w-full' : 'p-edge'
                } flex`}
              >
                {item.menus?.map((menu) => {
                  return (
                    <div key={`menu-${menu.id}`} className={``}>
                      <ul>
                        {menu.menuItems &&
                          menu.menuItems.map(
                            (menuItem: ContentfulTopicLink) => (
                              <li
                                key={`menu-item-${menuItem.id}`}
                                className="mt-xs first:mt-0"
                              >
                                {menuItem?.page && (
                                  <LocalizedLink
                                    language={locale}
                                    to={getFullLink(menuItem.page, locale)}
                                  >
                                    <Typography variant="body" as={`span`}>
                                      {menuItem.text}
                                    </Typography>
                                  </LocalizedLink>
                                )}
                              </li>
                            ),
                          )}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </ClickAwayListener>
          </Popper>
        </>
      );
    } else {
      menuItemElement = (
        <>
          {item?.link?.page && (
            <LocalizedLink
              language={locale}
              to={getFullLink(item?.link?.page, locale)}
              className="no-underline"
            >
              <Typography variant="subtitle">{item.text}</Typography>
            </LocalizedLink>
          )}
        </>
      );
    }
    return (
      <NavMenuItem
        key={`primary-menu-item-${item.id}`}
        className={`flex items-center ml-edge first:ml-0 ${
          popperElements[id] ? 'is-popper-open' : ''
        }`}
      >
        {menuItemElement}
      </NavMenuItem>
    );
  };

  const buildMobileMenuItem = (navItem: ContentfulComponentNavItem) => {
    let menuItemElement = <></>;
    const hasSubMenus = navItem.menus != null;
    if (hasSubMenus) {
      // menu item has sub-menus
      menuItemElement = (
        <>
          <div className="flex w-full items-center text-left">
            <Typography variant="body">{navItem.text}</Typography>
            <ChevronRight className="ml-auto" />
          </div>
          <Accordion
            square
            expanded={mobileAccordions[navItem.id]}
            onChange={(e) => toggleMobileAccordion(e, navItem.id)}
          >
            <AccordionSummary>
              <div className="flex flex-col">
                <ul className="w-full">
                  {navItem.menus?.map((menu) => {
                    // we only want the primary links on mobile sub menus
                    let subDrawerLinkElement = <></>;
                    if (menu.primaryLink) {
                      subDrawerLinkElement = (
                        <li
                          key={`sub-menu-item-${menu?.id}`}
                          className={`flex cursor-pointer items-center text-primaryMain border-t last:border-b transition-colors hover:bg-blue-100 p-xs ${
                            location?.pathname ===
                            `/${menu.primaryLink?.page?.slug}`
                              ? 'active'
                              : ''
                          }`}
                        >
                          {menu?.primaryLink?.page && (
                            <LocalizedLink
                              language={locale}
                              to={getFullLink(menu.primaryLink?.page, locale)}
                              className="flex items-center"
                            >
                              <Typography variant="body">
                                {menu.primaryLink?.text}
                              </Typography>
                            </LocalizedLink>
                          )}
                        </li>
                      );
                    } else {
                      subDrawerLinkElement = (
                        <>
                          {menu.menuItems?.map(
                            (menuItem: ContentfulTopicLink) => {
                              return (
                                <li
                                  key={`sub-menu-item-${menu?.id}`}
                                  className={`flex cursor-pointer items-center text-primaryMain border-t last:border-b transition-colors hover:bg-blue-100 p-xs ${
                                    location?.pathname ===
                                    `/${menu.primaryLink?.page?.slug}`
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  {menuItem?.page && (
                                    <LocalizedLink
                                      key={`sub-drawer-link-${menuItem.text}`}
                                      language={locale}
                                      to={getFullLink(menuItem?.page, locale)}
                                      className="flex items-center"
                                    >
                                      <Typography variant="body">
                                        {menuItem.text}
                                      </Typography>
                                    </LocalizedLink>
                                  )}
                                </li>
                              );
                            },
                          )}
                        </>
                      );
                    }

                    return subDrawerLinkElement;
                  })}
                </ul>
              </div>
            </AccordionSummary>
          </Accordion>
        </>
      );
    } else {
      // menu item has no submenu
      // determine if internal or external link
      const isExternal = navItem.link?.url != null;
      if (isExternal) {
        // regular anchor tag
        menuItemElement = (
          <a
            href={navItem?.link?.url}
            target="_blank"
            rel="noreferrer noopener"
            className="flex items-center"
          >
            <Typography variant="body">{navItem.text}</Typography>
          </a>
        );
      } else {
        // localized link
        menuItemElement = (
          <>
            {navItem?.link?.page && (
              <LocalizedLink
                language={locale}
                to={getFullLink(navItem?.link?.page, locale)}
                className="flex items-center"
              >
                <Typography variant="body">{navItem.text}</Typography>
              </LocalizedLink>
            )}
          </>
        );
      }
    }
    return (
      <li
        key={`menu-item-${navItem.id}`}
        className={`flex flex-col items-start cursor-pointer text-primaryMain border-t last:border-b p-xs`}
        onClick={
          hasSubMenus ? (e) => toggleMobileAccordion(e, navItem.id) : null
        }
      >
        {menuItemElement}
      </li>
    );
  };

  return (
    <Container className={`${className || ''}`}>
      <NavigationDesktop className="hidden lg:flex flex-col">
        <div className="flex-1">
          <div className="container flex items-center">
            <div className="flex-initial items-center">
              {data?.title && (
                <Typography variant="h4">{data?.title}</Typography>
              )}
            </div>
            <div className="flex-initial ml-auto flex items-center">
              <ul className="h-full flex items-center">
                {data?.navItems?.map(buildDesktopNavItem)}
              </ul>
            </div>
          </div>
        </div>
      </NavigationDesktop>
      <NavigationMobile
        key={`mobile-menu-anchor`}
        className="container px-edge flex items-center lg:hidden"
      >
        <div>
          <div className="flex-initial">
            {data?.title && <Typography variant="h4">{data?.title}</Typography>}
          </div>
          <div className="flex-initial ml-auto">
            <button
              type="button"
              aria-describedby={`mobile-popper-menu-${data?.id}`}
              onClick={(e) => togglePopper(e, data?.id)}
              className="h-full flex items-center hover:text-primaryMain"
            >
              {popperElements[data?.id] ? (
                <KeyboardArrowUp className="ml-1" />
              ) : (
                <KeyboardArrowDown className="ml-1" />
              )}
            </button>

            <Popper
              id={`mobile-popper-menu-${data?.id}`}
              anchorEl={popperElements[data?.id]}
              open={Boolean(popperElements[data?.id])}
              disablePortal
              style={{
                zIndex: 9999,
              }}
              popperOptions={{
                modifiers: {
                  preventOverflow: {
                    padding: 0,
                  },
                },
              }}
            >
              <ClickAwayListener onClickAway={() => closePopper(data?.id)}>
                <div className={`bg-white shadow-top flex`}>
                  <ul className="flex items-center">
                    {data?.navItems?.map(buildMobileMenuItem)}
                  </ul>
                </div>
              </ClickAwayListener>
            </Popper>
          </div>
        </div>
      </NavigationMobile>
    </Container>
  );
};

export default SubNavigation;
