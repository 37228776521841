import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ArrowForward from '@material-ui/icons/ArrowForward';

import { ContentfulComponentEventCard } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';

const Container = styled.div``;
const EventImageContainer = styled.div`
  height: 215px;
  @media (min-width: ${(props) => props.theme.breakpoint.xs}) {
    height: 300px;
  }
  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    max-height: 215px;
  }
`;

interface EventCardProps {
  data?: ContentfulComponentEventCard;
  className?: string;
}
const EventCard: React.FC<EventCardProps> = (props) => {
  const { data, className } = props;

  const today = new Date();
  const startDate = data?.event?.startDate;
  const endDate = data?.event?.endDate;

  let showEndDate = true;
  if (startDate === endDate) {
    showEndDate = false;
  }

  let checkDate = startDate;
  if (endDate) {
    checkDate = endDate;
  }

  const target = data?.ctaButton?.link?.openInNewWindow ? '_blank' : '';

  const checkFuture = new Date(checkDate);
  if (today < checkFuture) {
    return (
      <div className="container flex justify-center">
        <Container className={`${className || ``} container py-sm`}>
          <div className="lg:grid grid-cols-12 gap-x-gutter xl:gap-x-sm items-start">
            <div className="col-span-4">
              <EventImageContainer className="flex justify-center rounded-8 shadow-50 overflow-hidden">
                {data?.event?.featureImage.gatsbyImageData && (
                  <GatsbyImage
                    image={getImage(data?.event?.featureImage?.gatsbyImageData)}
                    alt=""
                    className="w-full"
                    style={{ maxHeight: 'inherit' }}
                  />
                )}
              </EventImageContainer>
            </div>
            <div className="text-center lg:text-left col-span-6 pr-0 lg:pr-6 mt-edge lg:mt-0">
              <div className="inline-block lg:hidden">
                <Typography variant="caption">
                  {startDate}
                  {showEndDate && endDate && ' - ' + endDate}
                </Typography>
              </div>

              <div className="mt-xs lg:mt-xxs">
                <Typography
                  variant="h3"
                  as="h2"
                  className="text-primaryMain mb-4"
                >
                  Event: {data?.event?.title}
                </Typography>
                <Typography variant="body">
                  {data?.event?.shortDescription?.shortDescription}
                </Typography>
              </div>
            </div>
            <div className="col-span-2 flex justify-center mt-sm lg:mt-xs">
              <div>
                <span className="mt-xs hidden lg:block mb-xs text-center">
                  <Typography variant="caption" as="span">
                    {startDate}
                    {showEndDate && endDate && ' - ' + endDate}
                  </Typography>
                </span>
                <Button variant={data?.ctaButton?.variant}>
                  <a
                    href={data?.event?.registrationPage}
                    target={target}
                    rel="noreferrer noopener"
                    className="cta_eventCard"
                  >
                    <span>{data?.ctaButton?.text}</span>
                    <ArrowForward className="ml-xs" />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="container flex justify-center">
        <Container className={`${className || ``} container py-xs`}></Container>
      </div>
    );
  }
};
export default EventCard;
