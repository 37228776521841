import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ContentfulImage from '@/components/ContentfulImage';

import styled from 'styled-components';
import {
  ContentfulComponentTwoColumnRichText,
  ContentfulAsset,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import Button from '@/components/layout/Button';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { getFullLink } from '@/utils/helpers';
import ArrowForward from '@material-ui/icons/ArrowForward';

const IframeContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Container = styled.div<{ color?: string; textBlack?: boolean }>`
  a.link:hover {
    color: ${(props) => props.color} !important;
  }
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
  .isNotLight h2 {
    color: ${(props) =>
      props?.textBlack ? '#000' : props.theme.color.primaryMain};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom: 1.5em;
  :last-child {
    margin-bottom: 0;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="text-4xl mb-4 font-light">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="font-bold text-xl mb-2">{children}</p>
);
const Heading_5_GREEN = ({ children }) => (
  <h5 className="text-4xl mb-4 text-[#4CAF50]">{children}</h5>
);
const Heading_5_ORANGE = ({ children }) => (
  <h5 className="text-4xl mb-4 text-[#F5A623]">{children}</h5>
);
const Heading_5_BLUE = ({ children }) => (
  <h5 className="text-4xl mb-4 text-[#0F75DB]">{children}</h5>
);
const Heading_5 = ({ children }) => (
  <h5 className="text-3xl mb-4">{children}</h5>
);

// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom: 0;
  }
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HEADING_5]: (node, children) => {
      if (node?.content?.[0]?.value?.includes('GREEN')) {
        children = node?.content?.[0]?.value?.replace('GREEN', '');
        return <Heading_5_GREEN>{children}</Heading_5_GREEN>;
      } else if (node?.content?.[0]?.value?.includes('ORANGE')) {
        children = node?.content?.[0]?.value?.replace('ORANGE', '');
        return <Heading_5_ORANGE>{children}</Heading_5_ORANGE>;
      } else if (node?.content?.[0]?.value?.includes('BLUE')) {
        children = node?.content?.[0]?.value?.replace('BLUE', '');
        return <Heading_5_BLUE>{children}</Heading_5_BLUE>;
      }
      return <Heading_5>{children}</Heading_5>;
    },
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      switch (node.data?.target.__typename) {
        case 'ContentfulAsset': {
          const contentfulAsset = node.data?.target as ContentfulAsset;
          return (
            <GatsbyImage
              image={getImage(contentfulAsset?.gatsbyImageData)}
              alt={contentfulAsset?.gatsbyImageData?.description || ''}
              className="inline-block"
            />
          );
        }
      }
    },
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.indexOf('youtube.com/embed') !== -1) {
        return (
          <IframeContainer>
            <iframe
              id="ytplayer"
              src={node.data.uri}
              width="640"
              height="360"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
            />
          </IframeContainer>
        );
      } else return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a href={node?.data?.target?.file?.url} rel="noreferrer">
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

interface LogoProps {
  data?: ContentfulComponentTwoColumnRichText;
  className?: string;
}
const TwoColumns: React.FC<LogoProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  const btnVariant =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton?.variant;

  let title, subtitle;

  if (data?.title) {
    title = data?.title;
  }
  if (data?.subtitle?.subtitle) {
    subtitle = data?.subtitle?.subtitle;
  }
  let hasIntro = false;
  if (title || subtitle) {
    hasIntro = true;
  }

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const isLight = data?.textColour === 'Light' ? 'isLight' : 'isNotLight';
  const titleColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';

  let isVerticalCards = false;
  if (data?.style === 'Vertical Items') {
    isVerticalCards = true;
  }

  return (
    <Container
      textBlack={Boolean(data?.textBlack)}
      color={data?.textColour === 'Light' ? '' : '#fff'}
      className={`mt-sm max-w-6xl mx-auto ${isLight}`}
    >
      {hasIntro && (
        <div className="container mb-md">
          {title && (
            <Typography
              as="h2"
              variant="h2"
              className={`mb-0 block text-center ${titleColor}`}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              as="p"
              variant="body"
              className={`text-center mt-xs ${textColor}`}
            >
              {subtitle}
            </Typography>
          )}
        </div>
      )}
      <div className="container grid grid-cols-12">
        {data.columns?.map((column) => {
          let textAlign = '';
          if (column?.textAlign === 'Right') {
            textAlign = 'text-right';
          } else if (column?.textAlign === 'Center') {
            textAlign = 'text-center';
          }
          let verticalPos = 'start';
          if (column?.verticalPosition === 'Center') {
            verticalPos = 'center';
          }
          let extraPossibleColumn = '';
          let flexCol = '';
          let justifyPos = 'between';
          const columnWidths = {
            'One quarter': 'lg:col-span-3',
          };
          let columnWidth;
          if (column?.columnWidth) {
            columnWidth = columnWidths[column?.columnWidth];
          }
          if (isVerticalCards) {
            extraPossibleColumn = columnWidth ? columnWidth : 'lg:col-span-4';
            flexCol = 'flex-col';
            verticalPos = 'center';
            justifyPos = 'center';
          }
          return (
            <div
              className={`col-span-12 mb-sm md:col-span-6 ${extraPossibleColumn} md:flex ${flexCol} items-${verticalPos} sm:px-sm justify-${justifyPos} ${textColor} ${isLight}`}
              key={`columnItem-${column?.id}`}
            >
              {column?.icon && (
                <ContentfulImage
                  image={column?.icon}
                  alt=""
                  width="80px"
                  style={{
                    maxHeight: '158px',
                    objectFit: 'contain',
                    marginTop: isVerticalCards ? 60 : 0,
                  }}
                  // imgStyle={{objectFit: 'contain'}}
                  objectFit={'contain'}
                  className={isVerticalCards ? 'mb-4' : 'mr-8'}
                />
              )}
              {column?.body?.raw && (
                <div className={`w-full ${textAlign}`}>
                  {renderRichText(
                    column.body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    options,
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {data?.ctaButton && (
        <div className="flex justify-center md:justify-center">
          <Button variant={btnVariant} className="mt-xs">
            {data?.ctaButton?.link && (
              <LocalizedLink
                language={locale}
                to={
                  data?.ctaButton.link?.url ||
                  getFullLink(data?.ctaButton.link?.page, locale)
                }
                className={`${textColor} hover:text-inherit link`}
              >
                {data?.ctaButton.text}
                <ArrowForward className="ml-xxs" />
              </LocalizedLink>
            )}
          </Button>
        </div>
      )}
    </Container>
  );
};
export default TwoColumns;
