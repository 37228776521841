import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ChevronRight } from '@material-ui/icons';

import { ContentfulComponentTabbedResources } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { getFullLinkByContent } from '@/utils/helpers';
import * as Constants from '@/types/Constants';

const Container = styled.div``;

const ImageContainer = styled.div`
  height: 280px;
  transition: box-shadow 0.1s ease-in;
`;

const IconContainer = styled.span`
  opacity: 0;
  transition: opacity 0.1s ease-in;
`;

const CardContainer = styled.div`
  &:hover {
    ${ImageContainer} {
      box-shadow: ${(props) => props.theme.boxShadow[48]} !important;
    }
    ${IconContainer} {
      opacity: 1 !important;
    }
  }
`;

interface TabbedResourcesProps {
  data?: ContentfulComponentTabbedResources;
  collection?: any;
  className?: string;
  showTabs?: boolean;
}

const TabbedResources: React.FC<TabbedResourcesProps> = ({
  data,
  collection,
  showTabs,
}) => {
  const { locale } = useLocalization();

  const [resourceFilter, setResourceFilter] = useState('');
  const [filteredResources, setFilteredResources] = useState([]);

  const handleFilterContent = (id: string) => {
    // console.log(`handle filter content ${id}`);
    setResourceFilter(id);
  };

  // find the resource filter based on the current page
  useEffect(() => {
    if (typeof window !== undefined && resourceFilter !== '') {
      if (window?.location?.pathname?.indexOf('webinars') !== -1) {
        setFilteredResources(
          collection?.edges?.filter((edge) =>
            edge.node.webinar?.topic?.find((t) => t.id === resourceFilter),
          ),
        );
      } else if (window?.location?.pathname?.indexOf('events') !== -1) {
        setFilteredResources(
          collection?.edges?.filter((edge) =>
            edge.node.eventDetails?.topic?.find((t) => t.id === resourceFilter),
          ),
        );
      } else if (window?.location?.pathname?.indexOf('case-studies') !== -1) {
        setFilteredResources(
          collection?.edges?.filter((edge) =>
            edge.node.caseStudy?.topic?.find((t) => t.id === resourceFilter),
          ),
        );
      } else if (
        window?.location?.pathname?.indexOf('customer-stories') !== -1
      ) {
        setFilteredResources(
          collection?.edges?.filter((edge) =>
            edge.node.customerStory?.topic?.find(
              (t) => t.id === resourceFilter,
            ),
          ),
        );
      } else if (window?.location?.pathname?.indexOf('blog') !== -1) {
        setFilteredResources(
          collection?.edges?.filter((edge) =>
            edge.node.blogPost?.topic?.find((t) => t.id === resourceFilter),
          ),
        );
      } else if (window?.location?.pathname?.indexOf('press-releases') !== -1) {
        setFilteredResources(
          collection?.edges?.filter((edge) =>
            edge?.topic?.find((t) => t.id === resourceFilter),
          ),
        );
      } else if (window?.location?.pathname?.indexOf('resources') !== -1) {
        setFilteredResources(
          collection?.edges?.filter((edge) => {
            const theGeneralFilter = Object.values(edge.node)[0];
            return theGeneralFilter?.topic?.find(
              (t) => t.id === resourceFilter,
            );
          }),
        );
      } else {
        setFilteredResources(collection?.edges);
      }
    } else {
      setFilteredResources(collection?.edges);
    }
  }, [resourceFilter]);

  // capture a hash on load, if it exists apply it to the filtered resources
  useEffect(() => {
    if (typeof window !== undefined) {
      if (window.location.hash !== '') {
        const filter = data?.topicFilters?.find(
          ({ title }) =>
            `#${title.toLowerCase()}` ===
            window.location.hash.split('-').join(' '),
        );
        if (filter) setResourceFilter(filter.id);
      }
    }
  }, []);

  return (
    <Container className="mb-md">
      <div className="container md:flex gap-x-md">
        {collection ? (
          <>
            <div className="w-full md:w-1/4 flex-shrink-0 md:border-r md:border-gray2 h-lg md:h-96">
              {showTabs && (
                <>
                  <Typography
                    as="h2"
                    variant="body"
                    className="uppercase font-bold mb-xs"
                  >
                    {data?.title}
                  </Typography>
                  <select
                    className="md:hidden border-black border rounded-sm p-xxs"
                    onChange={(e) => handleFilterContent(e.currentTarget.value)}
                  >
                    {data?.allTopicsText && (
                      <option value="">{data?.allTopicsText}</option>
                    )}
                    {data?.topicFilters?.map(({ id, title }) => {
                      return (
                        <option key={`mobile-filters-${id}`} value={id}>
                          {title}
                        </option>
                      );
                    })}
                  </select>
                  <ul className="mr-xs hidden md:block">
                    {data?.allTopicsText && (
                      <li className="w-full ml-0 hover:bg-grey1 py-xs rounded-sm">
                        <a
                          href="#"
                          onClick={() => handleFilterContent('')}
                          className="ml-xxs pl-xs py-xxs no-underline whitespace-nowrap border-l-4 border-transparent hover:border-primaryMain focus:border-primaryMain"
                        >
                          {data?.allTopicsText}
                        </a>
                      </li>
                    )}
                    {data?.topicFilters?.map(({ id, title }) => {
                      return (
                        <li
                          key={`filters-${id}`}
                          className="w-full ml-0 hover:bg-grey1 py-xs rounded-sm"
                        >
                          <a
                            href={`#${title
                              .split(' ')
                              .join('-')
                              .toLowerCase()}`}
                            onClick={() => handleFilterContent(id)}
                            className="ml-xxs pl-xs py-xxs no-underline whitespace-nowrap border-l-4 border-transparent hover:border-primaryMain focus:border-primaryMain"
                          >
                            {title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
            {filteredResources && filteredResources.length === 0 ? (
              <div className="w-full">Sorry. Nothing to see here!</div>
            ) : (
              <div className="md:grid grid-cols-12 md:gap-sm xl:gap-md">
                {filteredResources &&
                  filteredResources?.map((resource) => {
                    let resourceType,
                      id,
                      slug,
                      image,
                      alt,
                      title,
                      description,
                      startDate,
                      endDate;

                    let cardClasses, showReadMore;

                    if (resource?.node?.eventDetails) {
                      // EVENTS
                      id = resource.node.eventDetails.id;

                      slug = getFullLinkByContent(
                        resource.node.eventDetails.page__event?.[0]
                          ?.compose__page?.[0]?.slug,
                        Constants.PAGE_EVENT,
                        locale,
                      );
                      image =
                        resource.node.eventDetails.featureImage
                          ?.gatsbyImageData;
                      title = resource.node.eventDetails.title;
                      description =
                        resource.node.eventDetails.shortDescription
                          ?.shortDescription;
                      startDate = resource.node.eventDetails?.startDate;
                      endDate = resource.node.eventDetails?.endDate;
                      let showDate = startDate;
                      if (endDate && startDate !== endDate) {
                        showDate = startDate + ' - ' + endDate;
                      }

                      resourceType = showDate;
                      if (
                        window?.location?.pathname?.indexOf('resources') !== -1
                      )
                        resourceType = 'Event: ' + showDate;
                    } else if (resource?.node?.caseStudy) {
                      // CASE STUDIES
                      resourceType = 'Case Study';
                      id = resource.node.caseStudy.id;

                      slug = getFullLinkByContent(
                        resource.node.caseStudy.page__case_study?.[0]
                          ?.compose__page?.[0]?.slug,
                        Constants.PAGE_CASE_STUDY,
                        locale,
                      );
                      image =
                        resource.node.caseStudy.featureImage?.gatsbyImageData;
                      title = resource.node.caseStudy.title;
                      description =
                        resource.node.caseStudy.shortDescription
                          ?.shortDescription;
                    } /* else if (resource?.node?.guides) {
                      // Guides
                      resourceType = 'Guides';
                      id = resource.node.guides.id;

                      slug = getFullLinkByContent(
                        resource.node.guides.page__guides?.[0]
                          ?.compose__page?.[0]?.slug,
                        Constants.PAGE_GUIDES,
                        locale,
                      );

                      image =
                        resource.node.guides.featureImage?.gatsbyImageData;

                      title = resource.node.guides.title;

                      description =
                        resource.node.guides.shortDescription?.shortDescription;
                    } */ else if (resource?.node?.blogPost) {
                      // BLOG Posts
                      id = resource.node.blogPost.id;

                      slug = getFullLinkByContent(
                        resource.node.blogPost.page__blog?.[0]
                          ?.compose__page?.[0]?.slug,
                        'ContentfulPageBlog',
                        locale,
                      );
                      image =
                        resource.node.blogPost.featureImage?.gatsbyImageData;
                      title = resource.node.blogPost.title;
                      description =
                        resource.node.blogPost.shortDescription
                          ?.shortDescription;
                      startDate = resource.node.blogPost?.publicationDate;
                      resourceType = 'Blog | ' + startDate;
                    } else if (resource?.node?.customerStory) {
                      // CUSTOMER STORIES
                      resourceType = 'Customer Story';
                      id = resource.node.customerStory.id;
                      slug = getFullLinkByContent(
                        resource.node.customerStory.page__customer_story?.[0]
                          ?.compose__page?.[0]?.slug,
                        Constants.PAGE_CUSTOMER_STORY,
                        locale,
                      );

                      image =
                        resource.node.customerStory.featureImage
                          ?.gatsbyImageData;
                      title = resource.node.customerStory.title;
                      description =
                        resource.node.customerStory.shortDescription
                          ?.shortDescription;
                    } else if (resource?.node?.webinar) {
                      // WEBINARS
                      resourceType = 'Webinar';
                      id = resource.node.webinar.id;
                      slug = getFullLinkByContent(
                        resource.node.webinar.page__webinar?.[0]
                          ?.compose__page?.[0]?.slug,
                        Constants.PAGE_WEBINAR,
                        locale,
                      );

                      image =
                        resource.node.webinar.featureImage?.gatsbyImageData;
                      title = resource.node.webinar.title;
                      description =
                        resource.node.webinar.shortDescription
                          ?.shortDescription;
                    } else {
                      if (
                        resource.node?.internal?.type ===
                        'ContentfulPagePressRelease'
                      ) {
                        id = resource.node?.id;
                        resourceType = resource.node?.publishDate;
                        title = resource.node?.title;
                        description = resource.node?.excerpt?.excerpt;
                        slug = getFullLinkByContent(
                          resource.node?.compose__page?.[0]?.slug,
                          Constants.PAGE_PRESS_RELEASE,
                          locale,
                        );
                        cardClasses = 'border rounded p-4 hover:shadow-50';
                        showReadMore = true;
                      } else {
                        resourceType = null;
                        title = 'unknown';
                      }
                    }

                    return (
                      <CardContainer
                        key={`resource-item-${id}`}
                        className={`col-span-12 md:col-span-6 xxl:col-span-4 mt-md first:mt-0 md:mt-0 ${cardClasses}`}
                      >
                        {slug && (
                          <LocalizedLink
                            language={locale}
                            to={slug}
                            className="flex flex-col no-underline text-current"
                          >
                            {image && (
                              <ImageContainer className="rounded shadow-50 overflow-hidden mb-xs">
                                <GatsbyImage
                                  image={getImage(image)}
                                  alt={alt || ``}
                                  className="h-full w-full"
                                />
                              </ImageContainer>
                            )}
                            {resourceType && (
                              <div className="mb-xs">
                                <Typography
                                  variant="caption"
                                  className="uppercase"
                                >
                                  {resourceType}
                                </Typography>
                              </div>
                            )}
                            {title && (
                              <div className="">
                                <Typography
                                  variant="h5"
                                  className="text-primaryMain"
                                >
                                  <span>{title}</span>
                                  {/* <IconContainer>
                                    <ChevronRight />
                                  </IconContainer> */}
                                </Typography>
                              </div>
                            )}
                            {description && (
                              <div className="mt-xs">
                                <Typography variant="body">
                                  {description}&nbsp;
                                  {showReadMore && (
                                    <span className="text-primaryMain inline-block">
                                      Read more
                                      <IconContainer>
                                        <ChevronRight />
                                      </IconContainer>
                                    </span>
                                  )}
                                </Typography>
                              </div>
                            )}
                          </LocalizedLink>
                        )}
                      </CardContainer>
                    );
                  })}
              </div>
            )}
          </>
        ) : (
          <div className="w-full text-center">Sorry. Nothing to see here.</div>
        )}
      </div>
    </Container>
  );
};
export default TabbedResources;
