import React from 'react';
import styled from 'styled-components';
import { ContentfulComponentHeroBanner } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

const LinesWrapper = styled.div`
  position: absolute;
  left: 1em;
  top: 0;
`;

interface BasicHeroProps {
  title?: string;
  // data?: ContentfulComponentHeroBanner;
  className?: string;
}

const BasicHero: React.FC<BasicHeroProps> = (props) => {
  const { title, className } = props;
  return (
    <>
      <div id="is_hero_banner_insert"></div>
      <Container className={`hero ${className}`}>
        <div className="max-w-6xl m-auto relative">
          <LinesWrapper className="hidden md:block">
            <svg
              width="49"
              height="150"
              viewBox="0 0 49 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 2L25 104"
                stroke="#00A566"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <line
                x1="1.5"
                y1="-1.5"
                x2="14.5"
                y2="-1.5"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 24.5 133.215)"
                stroke="#00A566"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <circle
                r="2"
                transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 25.5 147.215)"
                fill="#00A566"
              />
              <path
                d="M47 64L47 2"
                stroke="#006DFF"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <path
                d="M2 42L2 2"
                stroke="#FFB700"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </LinesWrapper>
          {title && (
            <>
              <Typography
                as="h1"
                variant="h1"
                className="text-primaryMain font-light text-center md:text-left ml-0 mb-sm md:ml-lg md:mb-lg pt-sm pl-xs"
              >
                {title}
              </Typography>
            </>
          )}
        </div>
      </Container>
    </>
  );
};
export default BasicHero;
