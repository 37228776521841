import React from 'react';
import styled from 'styled-components';
import LinkedInWidget from '@/components/LinkedInWidget';

const Container = styled.div``;
const List = styled.ul``;
const ListItem = styled.li`
  height: 31px;
  width: 31px;
  @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
    height: 24px;
    width: 24px;
  }
`;

interface SocialMediaListProps {
  color?: string;
  className?: string;
  align?: string;
}
const SocialMediaList: React.FC<SocialMediaListProps> = (props) => {
  const { className, color, align } = props;

  const socialItems = [
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/miovision-technologies/',
      iconLight:
        'https://images.ctfassets.net/imvg0drj972v/KyuifCJrzzD9eADzcaBGL/fcf38fb655151536361a5790a3ab1ac2/LinkedIn_white.svg',
      iconDark:
        'https://images.ctfassets.net/imvg0drj972v/6DiZ0tUHJ1ETe1vKRnOcg0/431828ea9e18eff4a7d3cb406e0561fc/LinkedIn_dark.svg',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/team_miovision/',
      iconLight:
        'https://images.ctfassets.net/imvg0drj972v/4zs8faAArbsdxbXQY1lkZJ/a597b21e7d09121396d9ca5a57aa3a1b/Instagram_white.svg',
      iconDark:
        'https://images.ctfassets.net/imvg0drj972v/3lRDTjPKNmOpR6HlWXVFlM/7b620ebf069a15e4e129115034e14f8c/Instagram_dark.svg',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/miovision',
      iconLight:
        'https://images.ctfassets.net/imvg0drj972v/1UWykd0hrnWdEkjYjddtsP/64db5fcb7b1715f2381c5d423899a826/Facebook_white.svg',
      iconDark:
        'https://images.ctfassets.net/imvg0drj972v/5Y1kQlyenM1OGgfOPS217a/2f9110f9bcaae7f9f711494fb9ab6ec6/Facebook_dark.svg',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Miovision',
      iconLight:
        'https://images.ctfassets.net/imvg0drj972v/3LJGvp6plKTxsu6RgaEvrQ/846051caa3130d12763d2ff5870cc83f/Twitter_white.svg',
      iconDark:
        'https://images.ctfassets.net/imvg0drj972v/7CA0qMmsHlHD6t7FeBe3pd/e6f6b3e18af69979c81dab3b6e57a128/Twitter_dark.svg',
    },
  ];

  return (
    <Container
      className={`${className || ''} flex items-center gap-x-4 ${align}`}
    >
      <LinkedInWidget />
      <List className={`flex ${align}`}>
        {socialItems?.map((social) => (
          <ListItem key={`social_${social.name}`} className="ml-xs first:ml-0">
            <a
              href={social.url}
              target="_blank"
              rel="noreferrer"
              className="opacity-75 hover:opacity-100 focus:opacity-100 transition duration-150"
            >
              <img
                src={color === 'dark' ? social.iconDark : social.iconLight}
                alt={social.name}
              />
            </a>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
export default SocialMediaList;
