import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';

import {
  ContentfulComponentPlatformCardSet,
  ContentfulTopicCaseStudy,
  ContentfulTopicProductFeature,
} from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { getFullLink, getFullLinkByContent } from '@/utils/helpers';
import * as Constants from '@/types/Constants';
import Button from '@/components/layout/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';

const Container = styled.div``;

const Card = styled.div<{
  layout?: string;
}>`
  height: 100%;
  min-height: 250px;
  position: relative;
  transition: box-shadow 0.1s ease-in;
  ${(props) => {
    if (props.layout === 'Info cards') {
      return `
        background-image: ${props.theme.backgroundGradient.blue} !important;
        &:hover {
        box-shadow: ${props.theme.boxShadow[50]} !important;
        background-image: ${props.theme.backgroundGradient.blueHover} !important;
      }`;
    } else {
      return `
        background-image: ${props.theme.backgroundGradient.blue} !important;
        &:hover {
        box-shadow: ${props.theme.boxShadow[50]} !important;
        background-image: ${props.theme.backgroundGradient.blueHover} !important;
      }`;
    }
  }}
  }
`;

const CardIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 24px;
  z-index: 0;
  width: 42px;
`;

interface PlatformCardSetProps {
  data?: ContentfulComponentPlatformCardSet;
  className?: string;
}
const PlatformCardSet: React.FC<PlatformCardSetProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const headlineColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';
  const layouCards =
    data.platformCards?.length <= 3
      ? `md:grid-cols-3 lg:grid-cols-3`
      : data.platformCards?.length === 4
      ? 'md:grid-cols-4 lg:grid-cols-4'
      : `md:grid-cols-3 lg:grid-cols-3`;
  let layout = 'Default';
  if (data?.layout) {
    layout = data?.layout;
  }

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  return (
    <Container className="mt-md">
      <div className="container">
        {data?.title && (
          <Typography
            as="h2"
            variant="h2"
            className={`text-center ${headlineColor}`}
          >
            {data?.title}
          </Typography>
        )}
        {data?.description && (
          <Typography
            as="p"
            variant="body"
            className={`text-center mt-xs ${textColor}`}
          >
            {data.description.description}
          </Typography>
        )}

        <div className={`mt-sm grid grid-cols-1 ${layouCards} gap-md mb-lg`}>
          {data.platformCards?.map((card) => {
            let page, title, subHeading, product, newWindow, icon;
            if (card?.backgroundImage?.gatsbyImageData) {
              icon = card?.backgroundImage?.gatsbyImageData;
            }
            if (card?.topic?.subheading) {
              subHeading = card?.topic?.subheading;
            }

            // type cards in order to find correct fields
            switch (card?.topic?.internal?.type) {
              case Constants.TOPIC_PRODUCT_FEATURE:
                // eslint-disable-next-line no-case-declarations
                const productFeature =
                  card.topic as ContentfulTopicProductFeature;

                // eslint-disable-next-line no-case-declarations
                const productSlug =
                  productFeature?.product?.page__product?.find(
                    (productPage) => productPage.compose__page !== null,
                  ).compose__page?.[0]?.slug;

                // handle possibility of null compose__page, set slug for links
                productFeature.page__product_feature?.forEach((element) => {
                  if (element.compose__page) {
                    page = `/${productSlug}/${element.compose__page?.[0]?.slug}`;
                  }
                });

                product = productFeature.product?.title.replace(
                  '®',
                  '<sup>&reg;</sup>',
                );
                break;
              case Constants.TOPIC_CASE_STUDY:
                // eslint-disable-next-line no-case-declarations
                const caseStudy = card.topic as ContentfulTopicCaseStudy;
                caseStudy.page__case_study?.forEach((element) => {
                  if (element.compose__page) {
                    page = getFullLinkByContent(
                      element.compose__page?.[0]?.slug,
                      Constants.TOPIC_CASE_STUDY,
                      locale,
                    );
                  }
                });
                break;
            }

            title = card?.topic?.title;
            if (card?.title) {
              title = card?.title;
            }
            if (card?.label) {
              product = card?.label;
            }
            if (card?.link?.url) {
              page = card?.link?.url;
            }
            if (card?.link?.openInNewWindow) {
              newWindow = '_blank';
            }
            if (card?.body?.body) {
              subHeading = card?.body?.body;
            }

            // page = null;

            if (page) {
              return (
                <Card
                  key={`platform-${card?.id}`}
                  className="rounded shadow-30 overflow-hidden text-white"
                  layout={layout}
                >
                  {icon && (
                    <CardIcon>
                      <GatsbyImage image={getImage(icon)} alt="" />
                    </CardIcon>
                  )}
                  <LocalizedLink
                    language={locale}
                    to={page}
                    className="h-full block relative z-10 p-edge text-current no-underline"
                    target={newWindow}
                  >
                    {data?.layout === 'Info cards' ? (
                      <>
                        <span
                          className="block uppercase font-bold mb-xs text-sm tracking-wide"
                          dangerouslySetInnerHTML={{ __html: product }}
                        />
                        <Typography
                          as="h3"
                          variant="h3"
                          className="leading-tight font-normal mb-xs"
                        >
                          {title}
                        </Typography>
                        <div className="pr-sm">
                          <Typography variant="body">{subHeading}</Typography>
                        </div>
                      </>
                    ) : (
                      <>
                        <span
                          className="block uppercase font-light mb-xs text-xs tracking-wide"
                          dangerouslySetInnerHTML={{ __html: product }}
                        />
                        <Typography
                          as="h3"
                          variant="h3"
                          className="leading-tight font-normal mb-xs"
                        >
                          {title}
                        </Typography>
                        <div className="pr-sm">
                          <Typography variant="body">{subHeading}</Typography>
                        </div>
                      </>
                    )}
                  </LocalizedLink>
                </Card>
              );
            } else {
              return (
                <Card
                  key={`platform-${card?.id}`}
                  className="rounded shadow-30 overflow-hidden text-white"
                  layout={layout}
                >
                  {icon && (
                    <CardIcon>
                      <GatsbyImage image={getImage(icon)} alt="" />
                    </CardIcon>
                  )}
                  {data?.layout === 'Info cards' ? (
                    <div className="h-full block relative z-10 p-edge text-current no-underline">
                      <span
                        className="block uppercase font-bold mb-xs text-sm tracking-wide"
                        dangerouslySetInnerHTML={{ __html: product }}
                      />
                      <Typography
                        as="h3"
                        variant="h3"
                        className="leading-tight font-normal mb-xs"
                      >
                        {title}
                      </Typography>
                      <div>
                        <Typography variant="body">{subHeading}</Typography>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span
                        className="block uppercase font-light mb-xs text-xs tracking-wide"
                        dangerouslySetInnerHTML={{ __html: product }}
                      />
                      <Typography
                        as="h3"
                        variant="h3"
                        className="leading-tight font-normal mb-xs"
                      >
                        {title}
                      </Typography>
                      <div className="pr-sm">
                        <Typography variant="body">{subHeading}</Typography>
                      </div>
                    </>
                  )}
                </Card>
              );
            }
          })}
        </div>
      </div>
      {ctaLocalLink && (
        <div className="w-full mt-sm text-center">
          <Button variant={data?.ctaButton?.variant}>
            {ctaLocalLink && (
              <LocalizedLink
                id="hero_cta"
                language={locale}
                to={ctaLocalLink}
                target={target}
              >
                <span>{data?.ctaButton?.text}</span>
                <span className="inline-block ml-xxs">
                  <ArrowForward />
                </span>
              </LocalizedLink>
            )}
          </Button>
        </div>
      )}
      {ctaExternalLink && (
        <div className="w-full mt-sm text-center">
          <Button variant={data?.ctaButton?.variant}>
            <a href={ctaExternalLink} id="hero_cta" target={target}>
              <span>{data?.ctaButton?.text}</span>
              <span className="inline-block ml-xxs">
                <ArrowForward />
              </span>
            </a>
          </Button>
        </div>
      )}
    </Container>
  );
};
export default PlatformCardSet;
