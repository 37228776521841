import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ContentfulImage from '@/components/ContentfulImage';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import styled from 'styled-components';
import Typography from '@/components/layout/Typography';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { ContentfulComponentRichTextColumns } from '@/../graphql-types';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

const Container = styled.div<{ color?: string }>`
  a.link:hover {
    color: ${(props) => props.color} !important;
  }
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
  .isNotLight h2,
  .isNotLight h3 {
    color: ${(props) => props.theme.color.primaryMain};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom: 1.5em;
  :last-child {
    margin-bottom: 0;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="text-4xl mb-4 font-light">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="font-bold text-xl mb-2">{children}</p>
);

// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom: 0;
  }
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      switch (node.data?.target.__typename) {
        case 'ContentfulAsset': {
          const contentfulAsset = node.data?.target as ContentfulAsset;
          return (
            <GatsbyImage
              image={getImage(contentfulAsset?.gatsbyImageData)}
              alt={contentfulAsset?.gatsbyImageData?.description || ''}
              className="inline-block"
            />
          );
        }
      }
    },
    [INLINES.HYPERLINK]: (node) => {
      return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a href={node?.data?.target?.file?.url} rel="noreferrer">
          {children}
        </a>
      );
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

interface RichTextColumnsProps {
  data?: ContentfulComponentRichTextColumns;
  className?: string;
}
const RichTextColumns: React.FC<RichTextColumnsProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  let title, subtitle;
  let id = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);

  const btnVariant =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton?.variant;

  if (data?.title) {
    title = data?.title;

    const titleHasSpace = /\s/.test(title);
    if (titleHasSpace) {
      id = title.substring(0, title.indexOf(' ')).toLowerCase();
    } else {
      id = title.toLowerCase();
    }
  }
  if (data?.subtitle?.subtitle) {
    subtitle = data?.subtitle?.subtitle;
  }
  let hasIntro = false;
  if (title || subtitle) {
    hasIntro = true;
  }

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const isLight = data?.textColour === 'Light' ? 'isLight' : 'isNotLight';
  const titleColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';
  const marginTop = data?.removeTopMargin === true ? '' : 'mt-sm';
  return (
    <Container
      color={data?.textColour === 'Light' ? '' : '#fff'}
      className={`richTextColumns ${marginTop} max-w-6xl mx-auto ${isLight}`}
      id={id}
    >
      {hasIntro && (
        <div className="container mb-md">
          {title && (
            <Typography
              as="h2"
              variant="h2"
              className={`mb-0 block text-center ${titleColor}`}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              as="p"
              variant="body"
              className={`text-center mt-xs ${textColor}`}
            >
              {subtitle}
            </Typography>
          )}
        </div>
      )}
      <div className="container grid grid-cols-12 gap-xs">
        {data.columns?.map((column) => {
          const columnWidths = {
            Half: 'lg:col-span-6',
            'One third': 'lg:col-span-4',
            'Two thirds': 'lg:col-span-8',
            'One quarter': 'lg:col-span-3',
          };

          let textAlign = '';
          if (column?.textAlign === 'Right') {
            textAlign = 'text-right';
          } else if (column?.textAlign === 'Center') {
            textAlign = 'text-center';
          }

          let columnWidth = columnWidths['Half'];
          if (column?.columnWidth) {
            columnWidth = columnWidths[column?.columnWidth];
          }
          return (
            <div
              className={`richTextColumn col-span-12 ${columnWidth} mb-xs md:flex  items-start sm:px-sm justify-between ${textColor} ${isLight}`}
              key={`columnItem-${column?.id}`}
            >
              {column?.icon && (
                <ContentfulImage
                  image={column?.icon}
                  alt=""
                  width="80px"
                  className="mr-8"
                />
              )}
              {column?.body?.raw && (
                <div className={`w-full ${textAlign}`}>
                  {renderRichText(
                    column.body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    options,
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {data?.ctaButton && (
        <div className="flex justify-center md:justify-center">
          <Button variant={btnVariant} className="mt-xs">
            {data?.ctaButton?.link && (
              <LocalizedLink
                language={locale}
                to={
                  data?.ctaButton.link?.url ||
                  getFullLink(data?.ctaButton.link?.page, locale)
                }
                className={`${textColor} link`}
              >
                {data?.ctaButton.text}
                <ArrowForward className="ml-xxs" />
              </LocalizedLink>
            )}
          </Button>
        </div>
      )}
    </Container>
  );
};
export default RichTextColumns;
