import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { ContentfulComponentPlatformFeatureDouble } from '@/../graphql-types';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { getFullLink } from '@/utils/helpers';
import Button from '@/components/layout/Button';
import { ArrowForward } from '@material-ui/icons';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

interface PlatformFeatureDoubleProps {
  data?: ContentfulComponentPlatformFeatureDouble;
  className?: string;
}
const PlatformFeatureDouble: React.FC<PlatformFeatureDoubleProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  // flip the column if necessary
  let imagePosition = '';
  if (data?.imagePosition === 'Right') {
    imagePosition = 'lg:order-last';
  }

  let target;
  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  const textColor = data?.textColour === 'Light' ? 'text-white' : '';
  const titleColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';

  // handle custom image, if present
  let featureImage, featureAlt;

  if (data?.featureImage?.gatsbyImageData) {
    featureImage = data?.featureImage?.gatsbyImageData;
    featureAlt = data?.featureImage?.description;
  }

  let link;
  if (data?.ctaButton?.link?.url) {
    link = data?.ctaButton?.link?.url;
  } else if (data?.ctaButton?.link?.page) {
    link = getFullLink(data?.ctaButton?.link?.page, locale);
  }
  let ctaId = data?.id;

  return (
    <Container className="mt-lg">
      <div className="container items-center md:grid gap-x-sm grid-flow-col-dense grid-cols-12">
        <div
          className={`block text-center col-span-12 md:col-span-6 lg:col-span-7 ${
            imagePosition || ``
          }`}
        >
          {featureImage && (
            <GatsbyImage
              image={getImage(featureImage)}
              objectFit="contain"
              style={{ maxWidth: '584px', margin: '0 auto' }}
              alt={featureAlt || ''}
            />
          )}
        </div>
        <div className="col-span-12 mb-sm md:col-span-6 lg:col-span-5">
          {data.features?.map((feature) => {
            ctaId = feature.title;
            return (
              <div
                key={`feature-${feature.title?.replace(' ', '-')}`}
                className="mt-sm first:mt-0"
              >
                {feature?.title && (
                  <Typography
                    as="h2"
                    variant="h3"
                    className={`mb-xs block text-center md:text-left ${titleColor}`}
                  >
                    {feature?.title}
                  </Typography>
                )}
                {feature?.shortDescription && (
                  <p className={` text-center md:text-left ${textColor}`}>
                    {feature?.shortDescription?.shortDescription}
                  </p>
                )}
              </div>
            );
          })}
          {data?.ctaButton && (
            <div className="flex justify-center md:justify-start">
              <Button variant={data?.ctaButton?.variant} className="mt-sm">
                {link && (
                  <LocalizedLink
                    language={locale}
                    to={
                      data?.ctaButton.link?.url ||
                      getFullLink(data?.ctaButton.link?.page, locale)
                    }
                    target={target}
                    className={`cta_${ctaId.split(' ').join('-')}`}
                  >
                    {data?.ctaButton.text}
                    <ArrowForward className="ml-xxs" />
                  </LocalizedLink>
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
export default PlatformFeatureDouble;
