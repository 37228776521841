import React from 'react';
import {
  ContentfulComponentRichText,
  ContentfulAsset,
} from '@/../graphql-types';
import {
  CheckBoxOutlineBlank,
  CheckBox,
  Check,
  Clear,
} from '@material-ui/icons';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from '@/components/layout/Button';

import { ArrowForward } from '@material-ui/icons';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { getFullLink } from '@/utils/helpers';

const IframeContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 16 / 9;
  margin: 0 auto;
  border: 1px solid ${(props) => props.theme.color.grey2};
  overflow: hidden;
  > iframe {
    height: 100%;
    width: 100%;
    max-width: 800px;
    max-height: 450px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Container = styled.div`
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
margin-bottom:1.5em;
}
`;

// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom: 0;
  }
`;

const TableWrapper = styled.table`
  width: 100%;
  background: #fff;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.boxShadow[4]};
  thead tr {
    background: ${(props) => props.theme.color.primaryMain};
    color: ${(props) => props.theme.color.white};
  }
  thead th {
    font-size: 1.1rem;
    padding: ${(props) => props.theme.spacing.xs};
  }
  tbody tr {
    background: ${(props) => props.theme.color.grey4};
    text-align: center;
  }
  tbody tr:nth-of-type(odd) {
    background: ${(props) => props.theme.color.white};
  }
  tbody td {
    padding: ${(props) => props.theme.spacing.xxs};
  }
`;

interface RichTextProps {
  data: ContentfulComponentRichText;
}
const RichText: React.FC<RichTextProps> = (props) => {
  const { data } = props;

  const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
  const UL_list = ({ children }) => (
    <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
  );
  const OL_list = ({ children }) => (
    <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
  );
  const Heading_2 = ({ children }) => (
    <h2 className="text-primaryMain font-light text-4xl mb-4">{children}</h2>
  );
  const Heading_3 = ({ children }) => (
    <h3 className="text-primaryMain text-2xl mb-4">{children}</h3>
  );
  const Heading_4 = ({ children }) => (
    <p className="text-primaryMain font-bold text-xl mb-2">{children}</p>
  );

  const ListItem = ({ children }) => (
    <ListItemWrapper>{children}</ListItemWrapper>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
      [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
      [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
      [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        switch (node.data?.target.__typename) {
          case 'ContentfulAsset': {
            const contentfulAsset = node.data?.target as ContentfulAsset;
            return (
              <GatsbyImage
                image={getImage(contentfulAsset?.gatsbyImageData)}
                alt={contentfulAsset?.gatsbyImageData?.description}
                className="inline-block"
              />
            );
          }
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.indexOf('youtube.com/embed') !== -1) {
          return (
            <IframeContainer className="rounded">
              <iframe
                id="ytplayer"
                src={node.data.uri}
                width="640"
                height="360"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              />
            </IframeContainer>
          );
        } else return <a href={node.data.uri}>{node.content[0].value}</a>;
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={node?.data?.target?.file?.url}
            rel="noreferrer"
            target="_blank"
          >
            {children}
          </a>
        );
      },
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, '')
        .split('\n')
        .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
  };

  const Table = (table?: unknown) => {
    const layout = table.table.layout;

    if (layout === 'checks') {
      return (
        <TableWrapper className="table-fixed rounded">
          {table?.table.headers && (
            <thead>
              <tr>
                {table?.table.headers?.map((header, index) => {
                  return <th key={`tablehead-${index}`}>{header}</th>;
                })}
              </tr>
            </thead>
          )}
          {table?.table?.rows && (
            <tbody>
              {table.table?.rows?.map((row, index) => {
                return (
                  <tr key={`row-${index}`}>
                    <td className="text-right">{row.label}</td>
                    {row?.data?.map((cell, index) => {
                      return (
                        <td key={`cell-${index}`}>
                          {cell ? (
                            <Check className="text-greenDark" />
                          ) : (
                            <Clear className="opacity-25" />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </TableWrapper>
      );
    } else {
      return layout;
    }
  };

  let textAlign = '';
  if (data?.textAlign === 'Right') {
    textAlign = 'text-right';
  } else if (data?.textAlign === 'Center') {
    textAlign = 'text-center';
  } else if (data?.textAlign === 'Justify') {
    textAlign = 'text-justify';
  }

  let displayOn;
  const displayClasses = {
    Mobile: 'block sm:hidden',
    Tablet: 'hidden sm:block lg:hidden',
    Desktop: 'hidden lg:block',
    'Mobile Desktop': 'block sm:hidden lg:block',
    'Mobile Tablet': 'block lg:hidden',
    'Tablet Desktop': 'hidden sm:block',
  };

  if (data?.showOnScreens) {
    displayOn = displayClasses[data?.showOnScreens];
  }

  const { locale } = useLocalization();

  const ctaLink =
    data?.ctaButton?.link?.url ||
    getFullLink(data?.ctaButton?.link?.page, locale);

  return (
    <Container
      id="features"
      className={`container max-w-6xl mx-auto mb-sm block ${textAlign} ${displayOn}`}
    >
      {data?.content?.raw && (
        <>
          {renderRichText(
            data.content as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            options,
          )}
        </>
      )}
      {data?.ctaButton && (
        <div className="mt-sm">
          <Button
            variant={data?.ctaButton?.variant}
            className="text-current grid items-center"
          >
            {ctaLink && (
              <LocalizedLink
                language={locale}
                to={ctaLink}
                className="cta_footer mx-auto"
              >
                <span>{data?.ctaButton?.text}</span>
                <ArrowForward className="ml-xs" />
              </LocalizedLink>
            )}
          </Button>
        </div>
      )}
      {data?.table && <Table table={data?.table} />}
    </Container>
  );
};
export default RichText;
