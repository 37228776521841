import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ArrowForward } from '@material-ui/icons';

import { ContentfulComponentFooterCta } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

/** This styled-component ensures that the mobile background image size is
 * responsive until 640px, at which point we must fix the height
 * due to fixed max-width container.
 */
const Container = styled.div<{
  mobileAspectRatio: number;
  desktopAspectRatio: number;
  maxHeight: number;
}>`
  height: calc(100vw * ${(props) => props.mobileAspectRatio});
  max-width: ${(props) => props.theme.container.extra};

  @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
    /* background heros will be always this proportion */
    height: calc(100vw * ${(props) => props.desktopAspectRatio});
    /* stops being responsive at this breakpoint due to fixed width container */
    max-height: ${(props) => props.maxHeight}px;
  }
`;

/** This styled-component ensures the content area is always within
 * the footer cta background image's box, so that internal content
 * can position itself correctly
 */
const FooterCtaContentArea = styled.div`
  padding-left: 6% !important;
  padding-right: 6% !important;
  padding-top: 100% !important;
  @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-bottom: 5% !important;
    padding-top: 11% !important;
  }
  @media (min-width: ${(props) => props.theme.breakpoint.extra}) {
    /* padding-bottom needs to be fixed as the container
        area stop being responsive */
    padding-bottom: 80px !important;
    padding-top: 180px !important;
  }
`;
interface FooterCtaProps {
  data?: ContentfulComponentFooterCta;
  className?: string;
}
const FooterCta: React.FC<FooterCtaProps> = (props) => {
  const { data, className } = props;
  const { locale } = useLocalization();
  const themeContext = React.useContext(ThemeContext);

  const ctaLink =
    data?.ctaButton?.link?.url ||
    getFullLink(data?.ctaButton?.link?.page, locale);

  // determine how to size responsive background image
  const mobileAspectRatio =
    data?.mobileImage?.file?.details?.image?.height /
    data?.mobileImage?.file?.details?.image?.width;
  const desktopAspectRatio =
    data?.featureImage?.file?.details?.image?.height /
    data?.featureImage?.file?.details?.image?.width;
  const maxHeight =
    parseInt(themeContext.breakpoint.extra) * desktopAspectRatio;
  return (
    <Container
      mobileAspectRatio={mobileAspectRatio}
      desktopAspectRatio={desktopAspectRatio}
      maxHeight={maxHeight}
      className={`relative mx-auto ${className || ``}`}
    >
      <div className="absolute z-0 w-full h-full top-0 left-0">
        <GatsbyImage
          image={getImage(data?.featureImage?.gatsbyImageData)}
          alt=""
          className="hidden sm:block"
        />
        <GatsbyImage
          image={getImage(data?.mobileImage?.gatsbyImageData)}
          alt=""
          className="sm:hidden h-full"
        />
      </div>
      <FooterCtaContentArea className="h-full">
        <div className="relative z-10 sm:h-full sm:grid grid-cols-12 gap-x-gutter">
          <div className="flex flex-col items-center px-xs col-start-2 col-span-5 sm:items-start sm:justify-center sm:px-0">
            {data?.title && (
              <Typography
                as="div"
                variant="h1"
                className="text-center text-primaryMain sm:text-left"
              >
                {data?.title}
              </Typography>
            )}
            {data?.subheading && (
              <Typography
                as="div"
                variant="body"
                className="mt-xs text-center sm:text-left"
              >
                {data?.subheading}
              </Typography>
            )}
            {data?.ctaButton && (
              <div className="mt-xs">
                <Button
                  variant={data?.ctaButton?.variant}
                  className="text-current"
                >
                  {ctaLink && (
                    <LocalizedLink language={locale} to={ctaLink}>
                      <span>{data?.ctaButton?.text}</span>
                      <ArrowForward className="ml-xs" />
                    </LocalizedLink>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </FooterCtaContentArea>
    </Container>
  );
};
export default FooterCta;
