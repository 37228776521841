import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { getFullLink } from '@/utils/helpers';
import { Close, ArrowForward } from '@material-ui/icons';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { ContentfulComponentPopUp } from '@/../graphql-types';
import Button from '@/components/layout/Button';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="mb-4 last:mb-0">{children}</p>
    ),
    [BLOCKS.HR]: (node, children) => <hr className="my-8" />,
    [INLINES.HYPERLINK]: (node) => {
      return <a href={node.data.uri}>{node.content[0].value}</a>;
    },
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const PopUpContainer = styled.div<{ placement?: string }>`
  position: fixed;
  display: none;
  // box-shadow: ${({ theme }) => theme.boxShadow.popup};
  background: rgba(0, 109, 255, 0.1);
  padding: 12px;
  border-opacity: 0.1;
  max-width: 500px;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 200;
  ${(props) => {
    switch (props.placement) {
      case 'Notification - top right':
        return `top: ${props.theme.spacing.lg}; right: ${props.theme.spacing.sm};`;
      case 'Notification - top left':
        return `top: ${props.theme.spacing.lg}; left: ${props.theme.spacing.sm};`;
      case 'Notification - bottom right':
        return `bottom: ${props.theme.spacing.lg}; right: ${props.theme.spacing.sm};`;
      case 'Notification - bottom left':
      default:
        return `bottom: ${props.theme.spacing.lg}; left: ${props.theme.spacing.sm};`;
    }
  }}
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: block;
  }
  & .title {
    font-size: ${({ theme }) => theme.fontSize.mobileH3};
  }
  &.showPop {
    opacity: 1;
    transform: translateY(0);
  }
  button#close {
    background: ${({ theme }) => theme.color.greenDark};
    font-size: 0;
    line-height: 1;
    position: absolute;
    top: 17px;
    right: 17px;
    padding: 4px;
    border-radius: 100%;
    color: #fff;
  }
`;

const RichTextContainer = styled.div`
  // padding: 24px;
`;

interface PopUpProps {
  data?: ContentfulComponentPopUp;
  className?: string;
  placement?: string;
}
const PromoPopUp: React.FC<PopUpProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  const [cookies, setCookie] = useCookies(['popCookie', 'mio_ref']);

  const [mountPop, setMountPop] = useState(true);
  const [showPop, setShowPop] = useState(false);
  const [hidePop, setHidePop] = useState(false);

  const timer = data?.showAfter * 1000;
  const cookieMaxAge = data?.frequency * 86400;

  const handleClose = () => {
    setCookie('popCookie', true, {
      path: '/',
      maxAge: cookieMaxAge,
      sameSite: true,
    });
    setCookie('mio_ref', null, {
      path: '/',
      maxAge: cookieMaxAge,
      sameSite: true,
    });
    setHidePop(true);
  };

  let refURL;
  const handleCTA = () => {
    refURL = location.pathname;
    setCookie(
      'mio_ref',
      refURL === '/' ? 'homepage[popup]' : refURL + '[popup]',
      {
        path: '/',
        maxAge: 60,
        sameSite: true,
      },
    );
    setHidePop(true);
  };

  // never show if 999999
  if (data?.showAfter !== 999999) {
    React.useEffect(() => {
      // setTimeout(() => {
      //   setMountPop(true);
      // }, timer);
      setTimeout(() => {
        setShowPop(true);
      }, timer);
    }, []);
  } else {
    React.useEffect(() => {
      setHidePop(true);
    }, []);
  }

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  let showForLocale = false;
  if (data?.showInLocale.includes(locale)) {
    showForLocale = true;
  }

  // || cookies['popCookie']
  if (!mountPop || cookies['popCookie'] || !showForLocale) {
    return null;
  }

  if (data?.placement === 'Alert banner') {
    return null;
  } else {
    return (
      <PopUpContainer
        className={`rounded-xl 
          ${hidePop && 'hidden'}
          ${showPop && 'showPop'}`}
        placement={data?.placement}
        id="mioPromoPop"
      >
        <button id="close" onClick={handleClose}>
          <Close className="w-4 h-4" />
        </button>
        <div className="flex items-center justify-center bg-white rounded-xl text-primaryMain">
          <div className="p-8 pt-6">
            {data?.headline && (
              <p
                id="popup_title"
                className="title leading-tight font-bold mb-3"
              >
                {data?.headline}
              </p>
            )}
            <RichTextContainer id="popup_text">
              {renderRichText(
                data?.popUpBody as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                options,
              )}
            </RichTextContainer>
            {ctaLocalLink && (
              <div className="flex justify-center md:justify-start mt-xs">
                <Button variant={data?.ctaButton?.variant} className="text-sm">
                  {ctaLocalLink && (
                    <a
                      id="popup_cta"
                      href={ctaLocalLink}
                      target={target}
                      onClick={handleCTA}
                    >
                      <span>{data?.ctaButton?.text}</span>
                      <span className="inline-block ml-xxs">
                        <ArrowForward />
                      </span>
                    </a>
                  )}
                </Button>
              </div>
            )}
            {ctaExternalLink && (
              <div className="flex justify-center md:justify-start mt-xs">
                <Button variant={data?.ctaButton?.variant} className="text-sm">
                  <a
                    href={ctaExternalLink}
                    id="popup_cta"
                    target={target}
                    onClick={handleCTA}
                  >
                    <span className="buttonText">{data?.ctaButton?.text}</span>
                    <span className="inline-block ml-xxs">
                      <ArrowForward />
                    </span>
                  </a>
                </Button>
              </div>
            )}
          </div>
          <div id="popup_image" className="py-xs pr-xs">
            {data?.image && (
              <GatsbyImage
                image={data?.image.gatsbyImageData}
                objectFit="contain"
                // style={{ maxWidth: '584px', margin: '0 auto' }}
                alt={data?.image.description || ''}
              />
            )}
          </div>
        </div>
      </PopUpContainer>
    );
  }
};
export default PromoPopUp;
