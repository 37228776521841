import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';

import { ContentfulComponentPlatformFeatureSingle } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import Button from '@/components/layout/Button';
import { getFullLink } from '@/utils/helpers';

// to allow list items to override
const Paragraph = styled.p`
  margin-bottom:1.5em;
  }
`;
const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
const UL_list = ({ children }) => (
  <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
);
const OL_list = ({ children }) => (
  <ol className="list-decimal list-outside ml-6 mb-4">{children}</ol>
);
const Heading_2 = ({ children }) => (
  <h2 className="font-light text-3xl">{children}</h2>
);
const Heading_3 = ({ children }) => (
  <h3 className="text-2xl mb-4">{children}</h3>
);
const Heading_4 = ({ children }) => (
  <p className="font-bold text-xl mb-2">{children}</p>
);
// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom:0;
  }
}
`;
const ListItem = ({ children }) => (
  <ListItemWrapper>{children}</ListItemWrapper>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
    [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading_2>{children}</Heading_2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading_3>{children}</Heading_3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading_4>{children}</Heading_4>,
    [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
  },
  renderText: (text) =>
    text
      .replace(/\u2028/g, '')
      .split('\n')
      .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
};

const Container = styled.div`
  a:not(.button) {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:not(.button):hover,
  a:not(.button):focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
  h2 {
    margin-bottom: ${(props) => props.theme.spacing.xs};
  }
  && .text-default h2 {
    color: ${(props) => props.theme.color.primaryMain};
  }
  && .h2-indicator h2 {
    padding-bottom: ${(props) => props.theme.spacing.xs};
    position: relative;
  }
  && .h2-indicator h2:before {
    content: '';
    width: 11px;
    height: 11px;
    border-radius: 100%;
    position: absolute;
    left: -50%;
    bottom: -5px;
    background: ${(props) => props.theme.color.yellowMain};
  }
  && .h2-indicator h2:after {
    content: '';
    width: 100%;
    position: absolute;
    left: -50%;
    bottom: 0;
    height: 1px;
    background: ${(props) => props.theme.color.yellowMain};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    && .h2-indicator h2:before,
    .h2-indicator h2:after {
      display: none;
    }
  }
  && .gatsby-image-wrapper [data-main-image] {
    will-change: auto !important;
  }
`;

const ContainerFull = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  z-index: -1;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & .imageWrapper {
      height: calc(100% + 110px);
      transform: translateY(-95px);
      clip-path: polygon(0 0, 100% 60px, 100% 100%, 0% calc(100% - 60px));
    }
  }
`;

interface PlatformFeatureSingleProps {
  data?: ContentfulComponentPlatformFeatureSingle;
  className?: string;
}

const PlatformFeatureSingle: React.FC<PlatformFeatureSingleProps> = (props) => {
  const { data } = props;
  const { locale } = useLocalization();

  let verticalSpacing = 'py-sm';
  if (data?.customSpacing === 'Tight - vertical') {
    verticalSpacing = 'pb-sm lg:py-0';
  }

  // flip the stats column if necessary
  let imagePosition = '';
  if (data?.imagePosition === 'Right') {
    imagePosition = 'lg:order-last';
  } else if (
    data.topic?.component__platform_feature_large?.length > 0 &&
    data.topic.component__platform_feature_large[0].imagePosition === 'Right'
  ) {
    imagePosition = 'lg:order-last';
  }

  // handle custom image, if present
  let featureImage = data?.topic?.featureImage?.gatsbyImageData;
  let featureAlt = data?.topic?.featureImage?.description;

  if (data?.featureImage?.gatsbyImageData) {
    featureImage = data?.featureImage?.gatsbyImageData;
    featureAlt = data?.featureImage?.description;
  }

  // handle mobile image override
  let mobileImage, mobileAlt, featureDisplay, mobileDisplay;
  if (data?.mobileImage?.gatsbyImageData) {
    mobileImage = data?.mobileImage?.gatsbyImageData;
    mobileAlt = data?.mobileImage?.description;
    featureDisplay = 'hidden lg:block';
    mobileDisplay = 'block lg:hidden';
  }

  let title;
  if (data?.topic.title) {
    title = data?.topic.title
      .replace('®', '<sup style="font-size:0.5em; top:-0.8em;">&reg;</sup>')
      .replace('™', '<sup>&trade;</sup>');
  }

  let description;
  if (data?.topic?.shortDescription) {
    description = data?.topic?.shortDescription?.shortDescription
      .replace('®', '<sup>&reg;</sup>')
      .replace('™', '<sup>&trade;</sup>');
  }

  const textColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-default';
  const titleColor =
    data?.textColour === 'Light' ? 'text-white' : 'text-primaryMain';

  let hasRichText = false;
  if (data?.richText?.raw) {
    hasRichText = true;
  }

  let ctaText, ctaLocalLink, ctaExternalLink, target;
  if (data?.ctaButton?.link?.page?.slug) {
    ctaLocalLink = getFullLink(data?.ctaButton?.link?.page, locale);
  } else if (data?.ctaButton?.link?.url) {
    ctaExternalLink = data?.ctaButton?.link?.url;
  }

  if (data?.ctaButton?.text) {
    ctaText = data?.ctaButton?.text;
  } else if (data?.ctaButton?.link?.text) {
    ctaText = data?.ctaButton?.link?.text;
  }

  if (data?.ctaButton?.link?.openInNewWindow) {
    target = '_blank';
  }

  let ctaText2, ctaLocalLink2, ctaExternalLink2, target2;
  if (data?.ctaButton2) {
    if (data?.ctaButton2.link?.page?.slug) {
      ctaLocalLink2 = getFullLink(data?.ctaButton2?.link?.page, locale);
    } else if (data?.ctaButton2?.link?.url) {
      ctaExternalLink2 = data?.ctaButton2?.link?.url;
    }

    if (data?.ctaButton2?.text) {
      ctaText2 = data?.ctaButton2?.text;
    } else if (data?.ctaButton2?.link?.text) {
      ctaText2 = data?.ctaButton2?.link?.text;
    }

    if (data?.ctaButton2?.link?.openInNewWindow) {
      target2 = '_blank';
    }
  }

  let contentModifier;
  // let containerModifier = 'container';
  if (data?.contentModifier) {
    if (data?.contentModifier === 'H2 indicator to image') {
      contentModifier = 'h2-indicator';
      // containerModifier = 'container';
    }
  }

  let hideImageOnMobile = 'block';
  if (data?.hideImageOnMobile) {
    hideImageOnMobile = 'hidden lg:block';
  }

  const btnVariant =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton?.variant;
  const btnVariant2 =
    data?.textColour === 'Light' ? 'Outline-Light' : data?.ctaButton2?.variant;

  if (data?.layout === 'Full bleed') {
    return (
      <ContainerFull className="">
        <div className="imageWrapper hidden lg:block lg:w-1/2 absolute inset-0">
          {featureImage && (
            <GatsbyImage
              image={getImage(featureImage)}
              objectFit="contain"
              alt={featureAlt || ''}
              // className={featureDisplay}
            />
          )}
        </div>
        <div className="container items-center flex flex-row-reverse">
          <div className="w-full lg:w-1/2 px-md pt-md pb-lg">
            {title && (
              <Typography
                as="h2"
                variant="h2"
                className={`mb-xs block text-center md:text-left ${titleColor}`}
              >
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
              </Typography>
            )}
            {hasRichText ? (
              <div className={`rich1 ${textColor}`}>
                {renderRichText(data?.richText, options)}
              </div>
            ) : (
              <>
                {description && (
                  <p
                    className={` text-center md:text-left ${textColor}`}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                )}
                {data?.topic?.highlights && data?.topic?.highlights.length > 0 && (
                  <div>
                    <ul className="mt-sm">
                      {data.topic?.highlights?.map((highlight) => {
                        return (
                          <li
                            key={`highlight-list-item-${highlight.replace(
                              ' ',
                              '-',
                            )}`}
                            className="flex items-center mt-xs first:mt-0"
                          >
                            <span>
                              <CheckCircleOutline className={titleColor} />
                            </span>
                            <Typography
                              variant="body"
                              className={` ml-xs ${textColor}`}
                            >
                              {highlight}
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            )}
            {data?.finePrint && (
              <p className="text-xs mt-sm">{data?.finePrint}</p>
            )}
          </div>
        </div>
      </ContainerFull>
    );
  } else {
    return (
      <Container className={`${verticalSpacing}`}>
        <div className={`container items-center lg:flex gap-x-sm`}>
          <div
            className={`${hideImageOnMobile} text-center w-full lg:w-1/2 ${
              imagePosition || ``
            }`}
          >
            {featureImage && (
              <GatsbyImage
                image={getImage(featureImage)}
                objectFit="contain"
                alt={featureAlt || ''}
                className={featureDisplay}
              />
            )}
            {mobileImage && (
              <GatsbyImage
                image={getImage(mobileImage)}
                objectFit="contain"
                alt={mobileAlt || ''}
                className={`${mobileDisplay} mb-sm lg:mb-0`}
              />
            )}
          </div>
          <div className="w-full lg:w-1/2">
            {title && (
              <Typography
                as="h2"
                variant="h2"
                className={`mb-xs block text-center md:text-left ${titleColor}`}
              >
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
              </Typography>
            )}
            {hasRichText ? (
              <div className={`richtext ${textColor} ${contentModifier}`}>
                {renderRichText(data?.richText, options)}
              </div>
            ) : (
              <>
                {description && (
                  <p
                    className={` text-center md:text-left ${textColor}`}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                )}
                {data?.topic?.highlights && data?.topic?.highlights.length > 0 && (
                  <div>
                    <ul className="mt-sm">
                      {data.topic?.highlights?.map((highlight) => {
                        return (
                          <li
                            key={`highlight-list-item-${highlight.replace(
                              ' ',
                              '-',
                            )}`}
                            className="flex items-center mt-xs first:mt-0"
                          >
                            <span>
                              <CheckCircleOutline className={titleColor} />
                            </span>
                            <Typography
                              variant="body"
                              className={` ml-xs ${textColor}`}
                            >
                              {highlight}
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            )}
            {data?.finePrint && <p className="text-xs">{data?.finePrint}</p>}
            <div className="lg:flex">
              {ctaLocalLink && (
                <div className="flex justify-center md:justify-start mt-sm">
                  <Button variant={btnVariant}>
                    {ctaLocalLink && (
                      <LocalizedLink
                        language={locale}
                        to={ctaLocalLink}
                        target={target}
                        className={`button cta_${data?.topic?.title
                          .split(' ')
                          .join('-')}`}
                      >
                        <span>{ctaText}</span>
                        <span className="inline-block ml-xxs">
                          <ArrowForward />
                        </span>
                      </LocalizedLink>
                    )}
                  </Button>
                </div>
              )}
              {ctaExternalLink && (
                <div className="flex justify-center md:justify-start mt-sm">
                  <Button variant={btnVariant}>
                    <a
                      href={ctaExternalLink}
                      target={target}
                      className={`button cta_${data?.topic?.title
                        .split(' ')
                        .join('-')}`}
                    >
                      <span>{ctaText}</span>
                      <span className="inline-block ml-xxs">
                        <ArrowForward />
                      </span>
                    </a>
                  </Button>
                </div>
              )}
              {ctaLocalLink2 && (
                <div className="flex justify-center md:justify-start mt-sm lg:mx-4">
                  <Button variant={btnVariant2}>
                    {ctaLocalLink2 && (
                      <LocalizedLink
                        language={locale}
                        to={ctaLocalLink2}
                        target={target2}
                        className={`button cta_${data?.topic?.title
                          .split(' ')
                          .join('-')}_2`}
                      >
                        <span>{ctaText2}</span>
                        <span className="inline-block ml-xxs">
                          <ArrowForward />
                        </span>
                      </LocalizedLink>
                    )}
                  </Button>
                </div>
              )}
              {ctaExternalLink2 && (
                <div className="flex justify-center md:justify-start mt-sm mx-4">
                  <Button variant={btnVariant2}>
                    <a
                      href={ctaExternalLink2}
                      target={target2}
                      className={`button cta_${data?.topic?.title
                        .split(' ')
                        .join('-')}`}
                    >
                      <span>{ctaText2}</span>
                      <span className="inline-block ml-xxs">
                        <ArrowForward />
                      </span>
                    </a>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    );
  }
};
export default PlatformFeatureSingle;
