export const theme = {
  breakpoint: {
    mobile: '0px',
    xxs: '375px',
    xs: '480px',
    sm: '640px',
    md: '768px',
    tablet: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
    desktop: '1280px',
    extra: '1600px',
    super: '2560px',
  },
  boxShadow: {
    top: '0px -4px 10px rgba(5, 5, 5, 0.07)',
    popup: '0px 6px 22px rgba(0, 0, 0, 0.11)',
    30: '0px 0px 30px rgba(0, 0, 0, 0.10)',
    48: '0px 0px 48px rgba(0, 0, 0, 0.45)',
    50: '0px 0px 50px rgba(0, 0, 0, 0.15)',
    4: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  },
  fontFamily: {
    arboria: `arboria, Helvetica, Arial, Tahoma, sans-serif`,
  },
  fontSize: {
    caption: '12px',
    subtitle: '14px',
    body: '16px',
    h5: '24px',
    h4: '24px',
    h3: '36px',
    mobileH3: '28px',
    mobileH2: '32px',
    h2: '42px',
    mobileH1: '32px',
    h1: '42px',
  },
  fontWeight: {
    thin: 200,
    light: 300,
    book: 400,
    medium: 500,
    bold: 700,
  },
  header: {
    desktopHeight: '100px',
    mobileHeight: '65px',
  },
  color: {
    primaryMain: '#006DFF',
    primaryDark: '#0245A0',
    yellowMain: '#FFB700',
    yellowLight: '#FEEB45',
    greenDark: '#00A566',
    grey: '#757575',
    grey1: '#F4F4F4',
    grey2: '#E2E2E2',
    grey4: '#F2F7FF', // very light blue
    black: '#050505',
    black2: '#2c2c32',
    blackAlt: '#000000',
    white: '#FFFFFF',
  },
  gradient: {
    main50: 'linear-gradient(148.75deg, #00A251 14.87%, #279DD8 86.23%)',
    main10:
      'linear-gradient(90deg, #005CED 0%, #005CED 37.68%, #60BEBA 75.52%)',
    main10Reversed:
      'linear-gradient(90deg, #60BEBA 0%, #005CED 71.02%, #1700ED 100%)',
    main33:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #0245A0 0%, #005CED 46.54%, #60BEBA 100%)',
    yellow:
      'linear-gradient(148.75deg, #FEEE47 14.87%, #FEE541 31%, #FCCC30 56.5%, #FBAA19 86.23%)',
    yellowBasic: 'linear-gradient(145deg, #FEEE47, #FBAA19) 1;',
    grey: 'linear-gradient(90deg, #E2E2E2 30.45%, rgba(244, 244, 244, 0.1) 86.52%)',
    black: 'linear-gradient(118.36deg, #565656 2.77%, #1A1A1A 62.95%)',
    black2: 'linear-gradient(118.36deg, #373740 2.77%, #1A1A1A 62.95%)',
    blue: 'linear-gradient(118.36deg, #0245A0 2.77%, #006DFF 62.95%)',
    type: 'linear-gradient(180deg, #0065ED 34.25%, #0245A0 100%);',
  },
  backgroundGradient: {
    grey: 'linear-gradient(4deg, #E2E2E2 29.22%, rgba(244, 244, 244, 0.1) 85.71%)',
    greyReversed: 'linear-gradient(#E2E2E2, rgba(244, 244, 244, 0))',
    black: 'linear-gradient(243.64deg, #373740 0%, #1a1a1a 61.89%)',
    blackReversed: 'linear-gradient(118.36deg, #373740 2.77%, #1A1A1A 62.95%)',
    heroBlack:
      'linear-gradient(to bottom, rgba(55,55,64,1) 0%, rgba(26,26,26,1) 100%);',
    blue: 'linear-gradient(118.36deg, #0245A0 2.77%, #006DFF 62.95%)',
    heroBlue:
      'linear-gradient(to right top, rgba(0,109,255,1) 0%, rgba(2,69,160,1) 100%);',
    blueHover: 'linear-gradient(118.36deg, #0245A0 10%, #006DFF 63%)',
    yellow:
      'linear-gradient(148.75deg, #FEEE47 14.87%, #FEE541 31%, #FCCC30 56.5%, #FBAA19 86.23%)',
    yellowHover:
      'linear-gradient(148.75deg, #FEEE47 10.87%, #FEE541 30%, #FCCC30 54.5%, #FBAA19 88.23%)',
    green: 'linear-gradient(118.36deg, #00A251 2.77%, #1BA396 62.95%)',
    greenHover: 'linear-gradient(118.36deg, #00A251 14%, #00A251 66%)',
  },
  spacing: {
    edge: '24px',
    xxs: '8px',
    xs: '16px',
    sm: '46px',
    md: '72px',
    lg: '96px',
    xl: '120px',
    xxl: '144px',
  },
  container: {
    mobile: '100%',
    xxs: '100%',
    xs: '100%',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
    extra: '1600px',
    super: '2560px',
  },
  gap: {
    xs: '24px',
    sm: '78px',
    md: '98px',
    lg: '124px',
  },
};
